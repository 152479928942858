import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useFeedBack } from "../contexts/FeedBackContext";
import useAxios from "../hooks/useAxios";
import MenuLinks from "../utils/MenuLinks";
import { confirm } from "../utils/swal";

const AplicationsModulesList = () => {
    const [searchParams] = useSearchParams();

    const { setLoading } = useFeedBack();

    const [{ data: loginData, loading: loginLoading }, login] = useAxios({ method: 'POST' }, { useCache: false, manual: true });

    const [modules, setModules] = useState(null);

    useEffect(() => {
        const linkName = searchParams?.get('linkName');
        if (linkName) {
            setModules(MenuLinks[linkName]);
        }
    }, [searchParams])

    useEffect(() => {
        setLoading({
            show: loginLoading,
            message: 'Ingresando',
        });
    }, [loginLoading]);

    const handleSystemClick = async ({ code, siteUrl, name }) => {

        login({ url: '/auth/generate-token', data: { systemCode: code } }).then((response) => {
            window.open(`${siteUrl}?token=${window.encodeURIComponent(response?.data?.token)}`, '_blank');
        });

    }

    return (
        <div>
            <h3>Aplicaciones de {modules?.name}</h3>
            {
                modules?.aplications?.length > 0 ?
                    <div className="row mt-5 ">
                        {
                            modules?.aplications?.map((aplication, i) => {
                                return (
                                    <div className="col-sm-3 text-center" style={{ cursor: 'pointer' }} onClick={() => handleSystemClick(aplication)}>
                                        <div style={{ display: 'inline-block', margin: 'auto', backgroundColor: 'transparent' }}>
                                            <img style={{ borderRadius: '30px', width: '200px', height: '200px' }} src={aplication?.image} alt="" />
                                            <h3 className="text-center">
                                                {aplication?.name}
                                            </h3>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    <div className="text-center">
                        <h1 className="text-danger">
                            No hay módulos.
                        </h1>
                    </div>
            }
        </div>
    )
}

export default AplicationsModulesList;