import DateFormatter from '../components/DateFormatter';
import ActionsDropdown from '../components/ActionsDropdown';

const houseReservationColumns = ({ onDelete }) => [{
    headerName: 'ID',
    field: 'id'
},
{
    headerName: 'Nombre',
    field: 'guestName'
},
{
    headerName: 'Rut',
    field: 'guestRut'
},
{
    headerName: 'Llegada',
    component: ({ item }) => `${item?.fomatedStart} ${item?.startHour}`
},
{
    headerName: 'Salida',
    component: ({ item }) => `${item?.fomatedEnd} ${item?.endHour}`
},
{
    headerName: 'Días',
    component: ({ item }) => `${item?.days} dias`
},
{
    headerName: 'Casa csi',
    component: ({ item }) => `${item?.house?.name || '--'}`
},
{
    headerName: 'Communa',
    component: ({ item }) => `${item?.house?.commune?.name || '--'}`
},
{
    headerName: 'Región',
    component: ({ item }) => `${item?.house?.commune?.region?.name || '--'}`
},
{
    headerName: 'Fecha de creación',
    component: ({ item }) => <DateFormatter date={item.createdAt} />
},
{
    headerName: 'Acciones',
    component: ({ item }) => <ActionsDropdown
        updatePath={`/casas-csi-reservas/${item.id}`}
        onDelete={onDelete}
        dataForOnDelete={item.id}
        deleteAlertTitle="Eliminar registro"
        deleteAlertConfirmBtnText="Si, ¡Eliminarlo!"
        updateTextButton='Ver Detalles'
    />
},]

export default houseReservationColumns;

