import DateFormatter from '../components/DateFormatter';
import ActionsDropdown from '../components/ActionsDropdown';
import ImageAndName from '../components/ImageAndName';
import VacactionActionsComponent from "../components/VacationActionsComponent";

const vacationsColumns = ({ onDelete, handleAction }) => [{
    headerName: 'ID',
    field: 'id'
},
{
    headerName: 'Nombre',
    component: ({ item }) => <ImageAndName source={item?.user?.image_path} name={item.user?.name} />
},
{
    headerName: 'Nro de documento',
    component: ({ item }) => `${item?.user?.document_number}`
},
{
    headerName: 'Centro de costos',
    component: ({ item }) => `${item?.user?.cost_center?.name || '--'}`
},
{
    headerName: 'Días Hábiles',
    component: ({ item }) => `${item?.days}`
},
{
    headerName: 'Estatus',
    component: ({ item }) => <h6 style={{ color: item?.status?.color, textTransform: 'capitalize' }}>{item?.status?.name}</h6>
},
{
    headerName: 'Desde',
    component: ({ item }) => <DateFormatter date={`${item?.formattedStart} 23:59:59`} />
},
{
    headerName: 'Hasta',
    component: ({ item }) => <DateFormatter date={`${item?.formattedEnd} 23:59:59`} />
},
{
    headerName: 'Fecha de creación',
    component: ({ item }) => <DateFormatter date={item.createdAt} />
},
{
    headerName: 'Acciones',
    component: ({ item }) => <VacactionActionsComponent item={item} onDelete={onDelete} handleAction={handleAction} />
},]

export default vacationsColumns;

