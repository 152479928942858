import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import costCentersColumns from "../../tableColumns/costCentersColumns";
import Table from "../../components/Table";
import { useAuth } from "../../contexts/AuthContext";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";
import useCostCenters from "../../hooks/useCostCenters";
import { mainPermissions } from "../../utils/MenuLinks";
import swal from "../../utils/swal";
import UserHavePermission from "../../utils/UserHavePermission";

const CostCenters = () => {

    const { permissions } = useAuth();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [filters, setFilters] = useState({
        page: 1,
        search: ''
    })

    const [{ costCenters, total, numberOfPages, error: costCentersError, loading: costCentersLoading }, getCostCenters] = useCostCenters({ axiosConfig: { params: { ...filters } }, options: { useCache: false } });

    const [{ error: deleteError, loading: deleteLoading }, deleteRecord] = useAxios({ method: 'DELETE' }, { manual: true, useCache: false });

    useEffect(() => {
        setLoading?.({
            show: deleteLoading,
            message: 'Eliminando Registro(s)'
        })
    }, [deleteLoading])

    useEffect(() => {
        if (deleteError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'Ha ocurrido un error al eliminar.',
                show: true
            });
        }

        if (costCentersError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los registros.',
                show: true
            });
        }
    }, [deleteError, costCentersError])

    const handleDelete = async (id) => {
        await deleteRecord({ url: `/cost-centers/${id}` });

        swal({
            icon: 'success',
            title: '¡Eliminado!',
            text: 'Registro eliminado exitosamente!'
        });

        getCostCenters();
    }

    const handlePageChange = (page) => {
        if (page > 0) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    const handleDeleteSelected = async (selectedValues) => {
        await deleteRecord({ url: `/cost-centers/multiple`, data: { ids: selectedValues } });

        swal({
            icon: 'success',
            title: '¡Eliminados!',
            text: '¡Registros eliminado exitosamente!'
        });

        getCostCenters();
    }

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    return (
        <div>
            {UserHavePermission(mainPermissions?.costCenters[1]) && <div className="my-4 justify-content-end d-flex">
                <Link to={"/centro-de-costos/crear"} className="btn btn-primary">
                    Crear centro de costo
                </Link>
                <Link to={"/centro-de-costos/importar"} className="btn btn-success mx-2">
                    Importar excel
                </Link>
            </div>}
            <Table
                title="Centro de costo"
                columns={costCentersColumns({ onDelete: handleDelete })}
                onSearchChange={(e) => handleChange({ target: { value: e?.target.value, name: 'search' } })}
                searchValue={filters?.search}
                excelUrl={'/cost-centers/export/excel'}
                excelFileName='centro-de-costos.xlsx'
                items={costCenters}
                total={total}
                currentPage={filters.page}
                pages={numberOfPages}
                loading={costCentersLoading}
                onChangePage={handlePageChange}
                onDeleteSelected={handleDeleteSelected}
                withSelection
                perPageValue={filters?.perPage}
                onChagePerPage={(e) => {
                    console.log(e.target.value);
                    setFilters((oldFilters) => {
                        return {
                            ...oldFilters,
                            perPage: e.target.value,
                            page: 1
                        }
                    })
                }}
            />
        </div>
    )
}
export default CostCenters;