import DateFormatter from '../components/DateFormatter';
import ActionsDropdown from '../components/ActionsDropdown';

const positionColumns = ({ onDelete }) => [{
    headerName: 'ID',
    field: 'id'
}, {
    headerName: 'Nombre',
    field: 'name'
}, {
    headerName: 'Fecha de creación',
    component: ({ item }) => <DateFormatter date={item.createdAt} />
}, {
    headerName: 'Acciones',
    component: ({ item }) => <ActionsDropdown
        updatePath={`/cargos/${item.id}`}
        onDelete={onDelete}
        dataForOnDelete={item.id}
        deleteAlertTitle="Eliminar cargo"
        deleteAlertConfirmBtnText="Si, ¡Eliminarlo!"
    />
},]

export default positionColumns;

