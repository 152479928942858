import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useFeedBack } from "../contexts/FeedBackContext";
import useAxios from "../hooks/useAxios";
import { mainPermissions } from "../utils/MenuLinks";
import UserHavePermission from "../utils/UserHavePermission";

const Configuration = () => {
  const { permissions } = useAuth();

  const navigate = useNavigate();

  const { setCustomAlert, setLoading } = useFeedBack();

  const [data, setData] = useState({
    travelsTaxiEmail: "",
    travelsAccommodationEmail: "",
    travelsTravelEmail: "",
    peopleManagementEmails: ""
  });

  const [{ data: record, loading: recordLoading }, getRecord] = useAxios({
    url: "/configuration",
  });

  const [{ data: updateData, loading: updateLoading }, updateRecord] = useAxios(
    { url: "/configuration", method: "PUT" },
    { manual: true, useCache: false }
  );

  useEffect(() => {
    if (record) {
      const { updatedAt, createdAt, ...rest } = record?.data;
      setData((oldData) => {
        return {
          ...oldData,
          ...rest,
        };
      });
    }
  }, [record]);

  useEffect(() => {
    setLoading({
      show: recordLoading,
      message: "Cargando",
    });
  }, [recordLoading]);

  useEffect(() => {
    if (updateData) {
      setCustomAlert({
        title: "¡Operacion Exitosa!",
        severity: "success",
        message: "El registro fue actualizado exitosamente.",
        show: true,
      });
    }
  }, [updateData]);

  const handleChange = (e) => {
    setData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    updateRecord({ data });
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Configuración</h4>
        </div>

        <div className="card-body">
          <div className="basic-form">
            <form onSubmit={handleSubmit}>
              <div>
                <h3 className="mb-4">Emails para vacaciones</h3>
                <div className="row mb-5">
                  <div className="form-group col-md-12">
                    <label>
                      <b>Emails de gestión de personas</b> <span className="text-danger">*</span>{" "}
                      <br />
                      <small>Por favor ingrese los emails separados por punto y coma (;).</small>
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      placeholder="Escribe..."
                      name="peopleManagementEmails"
                      value={data.peopleManagementEmails}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <h3 className="mb-4">Emails Traslados y hospedajes</h3>
                <div className="row mb-5">
                  <div className="form-group mb-3 col-md-4">
                    <label>
                      <b>Taxi Email</b> <span className="text-danger">*</span>{" "}
                      <br />
                      <small>Email para notificar solicitudes de taxi.</small>
                    </label>
                    <input
                      required
                      type="email"
                      className="form-control"
                      placeholder="Taxi email"
                      name="travelsTaxiEmail"
                      value={data.travelsTaxiEmail}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-4">
                    <label>
                      <b>Hospedaje Email</b>{" "}
                      <span className="text-danger">*</span> <br />
                      <small>
                        Email para notificar solicitudes de hospedaje.
                      </small>
                    </label>
                    <input
                      required
                      type="email"
                      className="form-control"
                      placeholder="Hotel Email"
                      name="travelsAccommodationEmail"
                      value={data.travelsAccommodationEmail}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-4">
                    <label>
                      <b>Viaje Email</b> <span className="text-danger">*</span>{" "}
                      <br />
                      <small>
                        Email para notificar solicitudes boleto de viaje.
                      </small>
                    </label>
                    <input
                      required
                      type="email"
                      className="form-control"
                      placeholder="Viaje Email"
                      name="travelsTravelEmail"
                      value={data.travelsTravelEmail}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-3 d-flex justify-content-end">
                <Link
                  to={`#`}
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="btn btn-danger mx-2"
                >
                  Cancelar
                </Link>
                {UserHavePermission(mainPermissions?.configuration[1]) && (
                  <button
                    disabled={updateLoading}
                    type="submit"
                    className="btn btn-primary mx-2"
                  >
                    {updateLoading ? "Cargando" : "Actualizar"}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Configuration;
