import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "../../hooks/useAxios";

const UpdateVacationsDays = () => {

    const [file, setFile] = useState(null);

    const [{ data: updateData, loading: updateLoading }, updateVacationsDays] = useAxios({ url: `/users/update-vacations-days`, method: 'post' }, { manual: true, useCache: false });

    const [showValidRuts, setShowValidRuts] = useState(false);

    const [showInvalidRuts, setShowInvalidRuts] = useState(false);

    useEffect(() => {
        if (updateData) {
            setShowValidRuts(true);
            setShowInvalidRuts(true);
        }
    }, [updateData])

    const handleSubmit = (e) => {
        e?.preventDefault?.();

        if (updateLoading) {
            return;
        }

        if (!file) {
            alert('El archivo es obligatorio.');
        }

        const data = new FormData();
        data.append('file', file);

        updateVacationsDays({ data });
    }

    return (
        <div className="card">
            <div className="card-header">
                <h4>Actualizar vacaciones</h4>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="card-body">
                    <div className="form-group">
                        <label htmlFor="">Archivo excel</label>
                        <br />
                        <input
                            type="file"
                            className="from-control"
                            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={(e) => setFile(e?.target?.files[0])}
                        />
                    </div>
                    {
                        updateData?.message &&
                        <p className="text-success">
                            {updateData?.message}
                        </p>
                    }
                    {
                        updateData?.validRuts?.length > 0 && showValidRuts ?
                            <div className="row mb-4" style={{ borderBottom: '1px solid gray' }}>
                                <h4 className="col-6">
                                    Ruts Actualizados exitosamente:
                                </h4>
                                <div className="col-6 text-right">
                                    <button className="btn btn-danger" onClick={() => setShowValidRuts(false)}>Eliminar</button>
                                </div>
                                <div className="col-md-12">
                                    {
                                        updateData?.validRuts?.map((rut, i) => {
                                            return (
                                                <h6 key={i}>{rut}</h6>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            :
                            null
                    }
                    {
                        updateData?.invalidRuts?.length > 0 && showInvalidRuts ?
                            <div className="row">
                                <h4 className="col-6">
                                    Ruts No encontrados:
                                </h4>
                                <div className="col-6 text-right">
                                    <button className="btn btn-danger" onClick={() => setShowInvalidRuts(false)}>Eliminar</button>
                                </div>
                                <div className="col-md-12">
                                    {
                                        updateData?.invalidRuts?.map((rut, i) => {
                                            return (
                                                <p className="text-danger" key={i}>{rut}</p>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
                <div className="card-footer text-right">
                    <Link to={'/usuarios'} className="btn btn-danger mx-2">
                        Volver
                    </Link>
                    <button disabled={!file || updateLoading} className="btn btn-primary mx-2">
                        {
                            updateLoading ?
                                'Cargando'
                                :
                                'Enviar'
                        }
                    </button>
                </div>
            </form>
        </div>
    )
}

export default UpdateVacationsDays;