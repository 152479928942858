import DateFormatter from '../components/DateFormatter';
import ActionsDropdown from '../components/ActionsDropdown';
import ImageAndName from '../components/ImageAndName';

const serviceColumns = ({ onDelete }) => [{
    headerName: 'ID',
    field: 'id'
}, {
    headerName: 'Nombre',
    field: 'name'
},
{
    headerName: 'Jefe de operaciones',
    component: ({ item }) => item?.boss?.name ?
        <span title={item?.boss?.name}>
            {
                item?.boss?.name?.length > 15 ?
                    `${item?.boss?.name?.slice(0, 15)}...`
                    :
                    item?.boss?.name
            }
        </span>
        :
        '--'
},
{
    headerName: 'Admin. de contrato',
    component: ({ item }) => item?.administrator?.name ?
        <span title={item?.administrator?.name}>
            {
                item?.administrator?.name?.length > 15 ?
                    `${item?.administrator?.name?.slice(0, 15)}...`
                    :
                    item?.administrator?.name
            }
        </span>
        :
        '--'
},
{
    headerName: 'Zona',
    component: ({ item }) => item?.zone?.name || '--'
},
{
    headerName: 'Fecha de creación',
    component: ({ item }) => <DateFormatter date={item.createdAt} />
},
{
    headerName: 'Acciones',
    component: ({ item }) => <ActionsDropdown
        updatePath={`/servicios/${item.id}`}
        onDelete={onDelete}
        dataForOnDelete={item.id}
        deleteAlertTitle="Eliminar servicio"
        deleteAlertConfirmBtnText="Si, ¡Eliminarlo!"
    />
},]

export default serviceColumns;

