import RoutesContainer from "./routes/RoutesContainer";
import FeedbackComponents from "./components/FeedbackComponents";

const App = () => {
    return <>
        <FeedbackComponents />
        <RoutesContainer />
    </>;
}

export default App;
