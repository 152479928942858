import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import { useEffect, useState } from 'react';
import useAxios from '../../hooks/useAxios';
import useProfileVacations from '../../hooks/useProfileVacations';
import { useFeedBack } from '../../contexts/FeedBackContext';
import { Modal, Button } from 'react-bootstrap';
import DateFormatter from '../DateFormatter';
import vacationsImage from "../../assets/img/vacaciones.gif";
import Pagination from '../Pagination';

const VacationsComponent = () => {

    const { setLoading } = useFeedBack();

    const [currentVacation, setCurrentVacation] = useState(false);

    const [vacationsFilters, setVacationsFilters] = useState({
        start: '',
        end: '',
        perPage: '',
        page: ''
    });

    const [showMode, setShowMode] = useState('calendar');

    const [showModalVacations, setShowModalVacations] = useState(false);

    const [showVacationsTutorialModal, setShowVacationsTutorialModal] = useState(false);

    const [{ data: vacationsData, loading: createVacationsLoading }, createVacations] = useAxios({ url: `/vacations`, method: 'POST' }, { manual: true, useCache: false });

    const [{ vacations, loading: vacationsLoading, total: vacationsTotal, numberOfPages }, getVacations] = useProfileVacations({ manual: true, useCache: false });

    const [{ data: profileData, loading: profileLoading }, getProfile] = useAxios({ url: `my-account/profile` }, { useCache: false });

    const [{ data: holiDaysSummary, loading: holiDaysSummaryLoading }, getHolidays] = useAxios({ url: `my-account/vacations/calulate-days` }, { manual: true, useCache: false });

    const [currentEvents, setCurrentEvents] = useState([]);

    const [eventData, setEventData] = useState({
        start: '',
        end: '',
        return: ''
    });

    useEffect(() => {
        if (showMode === 'table') {
            setVacationsFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    perPage: 10,
                    page: 1,
                    start: '',
                    end: ''
                }
            })
        }

        if (showMode === 'calendar') {
            setVacationsFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    perPage: 100,
                    page: ''
                }
            })
        }
    }, [showMode])

    useEffect(() => {
        if (eventData?.start && eventData?.end) {
            getHolidays({
                params: {
                    start: eventData?.start,
                    end: eventData?.end
                }
            });
        }
    }, [eventData])

    useEffect(() => {
        setLoading({
            show: vacationsLoading,
            message: 'Cargando'
        })
    }, [vacationsLoading])

    useEffect(() => {
        console.log(currentEvents);
    }, [currentEvents])

    useEffect(() => {
        setCurrentEvents(vacations.map((vacation => {
            return {
                ...vacation,
                vacationId: vacation?.id,
                title: `Solicitud de Vacaciones #${vacation?.id} - ${vacation?.status?.name}`,
                backgroundColor: vacation?.status?.color,
                borderColor: vacation?.status?.color,
                start: `${vacation?.formattedStart} 00:00:00`,
                end: `${vacation?.formattedEnd} 23:59:59`,
            }
        })));
    }, [vacations])

    useEffect(() => {
        if (vacationsFilters?.start && vacationsFilters?.end && showMode === 'calendar') {
            getVacations({
                params: {
                    ...vacationsFilters
                }
            });
        }

        if (showMode === 'table') {
            getVacations({
                params: {
                    ...vacationsFilters
                }
            });
        }
    }, [vacationsFilters])

    useEffect(() => {
        if (vacationsData) {
            getProfile();
            setCurrentEvents((oldCurrentEvents) => {
                return [
                    ...oldCurrentEvents,
                    {
                        ...vacationsData?.data,
                        vacationId: vacationsData?.data?.id,
                        title: `Solicitud de Vacaciones #${vacationsData?.data?.id} - ${vacationsData?.data?.status?.name}`,
                        backgroundColor: vacationsData?.data?.status?.color,
                        borderColor: vacationsData?.data?.status?.color,
                        start: `${vacationsData?.data?.formattedStart} 00:00:00`,
                        end: `${vacationsData?.data?.formattedEnd} 23:59:59`,
                    }
                ]
            });
        }
    }, [vacationsData])

    const handleChange = (e) => {
        setEventData((oldEventData) => {
            return {
                ...oldEventData,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleEvent = (e) => {
        e.preventDefault();

        createVacations({ data: eventData });
    }

    const handleDateInfo = (dateInfo) => {
        setTimeout(() => {
            setVacationsFilters((oldVacactionsFilters) => {
                return {
                    ...oldVacactionsFilters,
                    start: dateInfo?.start,
                    end: dateInfo?.end
                }
            });
        }, 100);
    }


    const handleEventClick = (eventClick) => {
        const vacation = eventClick?.event?._def?.extendedProps;
        setCurrentVacation({
            ...vacation,
            end: eventClick?.event?.end,
            start: eventClick?.event?.start
        })
    }

    return (
        <>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header" style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                        <h5>Vacaciones</h5>
                        <button className='btn btn-dark'>
                            Días Disponibles: {profileLoading ? 'Cargando...' : profileData?.data?.days}
                        </button>

                        <button className='btn btn-secondary' onClick={() => setShowMode((value) => {
                            if (value === 'calendar') return 'table';
                            if (value === 'table') return 'calendar';
                        })} title={showMode === 'calendar' ? 'Ver en lista' : 'Ver en calendario'}>
                            <i className={showMode === 'calendar' ? 'ti-list' : 'ti-calendar'}></i>
                        </button>

                        <button onClick={() => setShowVacationsTutorialModal(true)} className="btn btn-warning btn-sm">
                            <i className='ti-info'></i>
                        </button>

                        <button onClick={() => setShowModalVacations(true)} type="button" className="btn btn-success btn-sm">
                            Crear Solicitud
                        </button>
                    </div>
                    <div className="card-body">
                        {
                            showMode === 'table' &&
                            <div className='table-responsive'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Nombre</th>
                                            <th>Nro de documento</th>
                                            <th>Centro de costos</th>
                                            <th>Días hábiles</th>
                                            <th>Estatus</th>
                                            <th>Desde</th>
                                            <th>Hasta</th>
                                            <th>Fecha de creación</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            currentEvents?.map((event, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            {event?.id}
                                                        </td>
                                                        <td>
                                                            {event?.user?.name}
                                                        </td>
                                                        <td>
                                                            {event?.user?.document_number}
                                                        </td>
                                                        <td>
                                                            {event?.user?.cost_center?.name}
                                                        </td>
                                                        <td>
                                                            {event?.days}
                                                        </td>
                                                        <td>
                                                            <span style={{ color: event?.status?.color }}>
                                                                {event?.status?.name}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <DateFormatter date={`${event?.formattedStart} 23:59:59`} />
                                                        </td>
                                                        <td>
                                                            <DateFormatter date={`${event?.formattedEnd} 23:59:59`} />
                                                        </td>
                                                        <td>
                                                            <DateFormatter date={event?.createdAt} />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }

                        {
                            showMode === 'calendar' &&
                            <FullCalendar
                                datesSet={handleDateInfo}
                                eventClick={handleEventClick}
                                height={'600px'}
                                locale={esLocale}
                                plugins={[dayGridPlugin]}
                                initialView="dayGridMonth"
                                events={currentEvents}
                            />
                        }
                    </div>
                    {
                        showMode === 'table' &&
                        <div className="card-footer" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="py-2">Mostrando: {currentEvents?.length} de {vacationsTotal}</div>
                            <div style={{ display: 'flex', alignItems: 'center', margin: '0px 20px', minWidth: '15%' }}>
                                <label className="label mx-1 mb-0">
                                    Mostrar:
                                </label>
                                <select onChange={(e) => setVacationsFilters((oldFilters) => {
                                    return {
                                        ...oldFilters,
                                        perPage: e.target.value
                                    }
                                })} className="form-control" value={vacationsFilters?.perPage}>
                                    <option value={1}>1</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                            <Pagination
                                style={{
                                    maxWidth: '65%',
                                    flexWrap: 'wrap'
                                }}
                                currentPage={vacationsFilters?.page}
                                pages={numberOfPages}
                                onChangePage={(page) => {
                                    setVacationsFilters((oldFilters) => {
                                        return {
                                            ...oldFilters,
                                            page: page
                                        }
                                    })
                                }}
                            />
                        </div>
                    }
                </div>
            </div>

            <Modal show={showModalVacations}>
                <Modal.Header>
                    <Modal.Title>Gestionar Vacaciones</Modal.Title>
                    <Button
                        onClick={() => setShowModalVacations(false)}
                        className="btn-danger"
                    >
                        <i className='ti-close'></i>
                    </Button>
                </Modal.Header>
                <form onSubmit={handleEvent}>
                    <Modal.Body>
                        <div className='row'>
                            <div className="col-md-6 form-group">
                                <label className='form-label'>Desde:</label>
                                <input
                                    value={eventData?.start}
                                    type="date"
                                    name="start"
                                    className='form-control'
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <label className='form-label'>Hasta:</label>
                                <input
                                    value={eventData?.end}
                                    type="date"
                                    name="end"
                                    className='form-control'
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-12 form-group">
                                <label className='form-label d-flex align-items-center justify-content-between'>
                                    <p>
                                        Reintegro:
                                    </p>
                                    {
                                        holiDaysSummaryLoading ?
                                            <div className='text-md-right'>
                                                Cargando...
                                            </div>
                                            :
                                            holiDaysSummary?.systemReturn &&
                                            <div className='text-md-right'>
                                                <small>
                                                    <span style={{ color: 'red' }}>Advertencia:</span> Día hábil de reintegro sugerido por el sistema
                                                </small>
                                                <p className='m-0'>
                                                    <b>{holiDaysSummary?.systemReturn}</b>
                                                </p>

                                            </div>
                                    }
                                </label>
                                <input
                                    value={eventData?.return}
                                    type="date"
                                    name="return"
                                    className='form-control'
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-6 form-group text-center">
                                <p>Días Disponibles</p>
                                <h4>{profileData?.data?.days || 0}</h4>
                            </div>
                            <div className="col-md-6 form-group text-center">
                                <p>Días a Disponer</p>
                                {
                                    holiDaysSummaryLoading ?
                                        'Cargando...'
                                        :
                                        <h4>
                                            {holiDaysSummary?.realDays || 0}
                                        </h4>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            vacationsData &&
                            <span className="animate__animated animate__fadeInUp">La solicitud se creo exitosamente.</span>
                        }
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => setShowModalVacations(false)}
                        >
                            Cerrar
                        </button>
                        <button type="submit" className="btn btn-primary" disabled={createVacationsLoading}>
                            {
                                createVacationsLoading ?
                                    'Cargando...'
                                    :
                                    'Crear Solicitud'
                            }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            <Modal show={currentVacation}>
                <Modal.Header>
                    <Modal.Title>Solicitud de vacaciones #{currentVacation?.vacationId}</Modal.Title>
                    <Button
                        onClick={() => setCurrentVacation(false)}
                        className="btn-danger"
                    >
                        <i className='ti-close'></i>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row">
                            <div className="col-md-6">
                                <h6>Desde:</h6>
                                <p>
                                    <DateFormatter date={currentVacation?.start} />
                                </p>
                            </div>
                            <div className="col-md-6">
                                <h6>Hasta:</h6>
                                <p>
                                    <DateFormatter date={currentVacation?.end} />
                                </p>
                            </div>
                            <div className="col-md-6">
                                <h6>Fecha de retorno:</h6>
                                <p>
                                    {currentVacation?.return}
                                </p>
                            </div>
                            <div className="col-md-6">
                                <h6>Días Habiles:</h6>
                                <p>
                                    {currentVacation?.days}
                                </p>
                            </div>
                            {
                                currentVacation?.status?.id === 1 &&
                                <div className='mb-4'>
                                    Motivo del rechazo: {currentVacation?.rejected_reason || '--'}
                                </div>
                            }
                            <div className="col-md-12">
                                <button className='btn btn-block text-capitalize text-white' style={{ background: currentVacation?.status?.color }}>
                                    {currentVacation?.status?.name}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showVacationsTutorialModal} size='lg' >
                <Modal.Header style={{ padding: 5 }}>
                    <Modal.Title>¿Como Gestionar Vacaciones?</Modal.Title>
                    <Button
                        onClick={() => setShowVacationsTutorialModal(false)}
                        className="btn-danger"
                    >
                        <i className='ti-close'></i>
                    </Button>
                </Modal.Header>
                <Modal.Body style={{ padding: 0 }}>
                    <img src={vacationsImage} style={{
                        maxWidth: '100%'
                    }} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default VacationsComponent;