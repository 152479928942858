import { get } from "lodash";

const TableCell = ({children, item, field, component: Component, defaultValue = null, ...props}) => {
    return <td {...props}>
        {children
            ? children
            : Component
                ? <Component item={item} />
                : get(item, field, defaultValue)
        }
    </td>;
}

export default TableCell;