import DateFormatter from '../components/DateFormatter';
import ActionsDropdown from '../components/ActionsDropdown';

const regionColumns = ({ onDelete }) => [{
    headerName: 'ID',
    field: 'id'
},
{
    headerName: 'Nombre',
    field: 'name'
},
{
    headerName: 'Zona',
    component: ({ item }) => `${item?.zone?.name || '--'}`
},
{
    headerName: 'Fecha de creación',
    component: ({ item }) => <DateFormatter date={item.createdAt} />
},
{
    headerName: 'Acciones',
    component: ({ item }) => <ActionsDropdown
        updatePath={`/regiones/${item.id}`}
        onDelete={onDelete}
        dataForOnDelete={item.id}
        deleteAlertTitle="Eliminar registro"
        deleteAlertConfirmBtnText="Si, ¡Eliminarlo!"
    />
},]

export default regionColumns;

