import { Button, Modal } from "react-bootstrap";
import ActionsDropdown from "./ActionsDropdown";
import WebDocxDocumentList from "./webdocxs/WebDocxDocumentList";
import { useState } from "react";

const VacationActionsComponent = ({ item, onDelete, handleAction }) => {
  const [showDocuments, setShowDocuments] = useState(false);

  return (
    <>
      <div className="d-flex align-items-center">
        {/* {item?.status?.id === 2 && (
          <>
            <button
              onClick={() => handleAction?.("change-status", item, 3)}
              title="Aprobar"
              className="btn btn-sm btn-success mx-2"
            >
              <i className="ti-check"></i>
            </button>
            <button
              onClick={() => handleAction?.("change-status", item, 1)}
              title="Rechazar"
              className="btn btn-sm btn-danger mx-2"
            >
              <i className="ti-close"></i>
            </button>
          </>
        )} */}
        {item?.workflowId && (
          <button
            onClick={() => setShowDocuments(true)}
            className="btn btn-danger btn-xs"
            title="Ver documentos"
          >
            <i className="ti-bookmark-alt"></i>
          </button>
        )}
        <ActionsDropdown
          updatePath={`/vacaciones/${item?.id}`}
          onDelete={onDelete}
          dataForOnDelete={item.id}
          deleteAlertTitle="Eliminar vacaciones"
          deleteAlertConfirmBtnText="Si, ¡Eliminarlo!"
        />
      </div>
      <Modal show={showDocuments && item?.workflowId}>
        <Modal.Header>
          <Modal.Title>Documentos en webdox</Modal.Title>
          <Button
            onClick={() => setShowDocuments(false)}
            className="btn-danger"
          >
            <i className="ti-close"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <WebDocxDocumentList
            url={`/decision_workflows/${item?.workflowId}/steps/1/documents`}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setShowDocuments(false)}
            className="btn-danger"
            type="button"
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VacationActionsComponent;
