import clsx from "clsx";
import user from "../assets/img/user.png";

const ImageAndName = ({className, source, name, defaultSource = user}) => {
    return <div className={clsx('d-inline-flex align-items-center', className)}>
        <img src={source || defaultSource} alt={name} className="rounded-circle thumb-sm mr-2" />
        <span>{name}</span>
    </div>
}

export default ImageAndName;