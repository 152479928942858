import { Outlet, useLocation } from "react-router-dom";
import Topbar from "../Topbar"
import Sidenav from "../Sidenav"
import { useEffect, useState } from "react";
import initTemplate from '../../utils/initTemplate';
import { useFeedBack } from "../../contexts/FeedBackContext";
import alertEmojis from '../../utils/alertEmojis';
import useWindowSize from "../../hooks/useWindowSize";

const AppLayout = () => {
    const { customAlert } = useFeedBack();

    const location = useLocation();

    const screen = useWindowSize();

    const [showMenu, setMenuShow] = useState(false);

    const [paths, setPaths] = useState([]);

    useEffect(() => {
        initTemplate();
    }, []);

    useEffect(() => {
        var pathArray = location?.pathname?.split('/');
        pathArray?.shift();
        setPaths(pathArray);
    }, [location]);

    useEffect(() => {
        const firstPath = paths[0];

        if (firstPath) {
            document.querySelector('title').textContent = `${firstPath.charAt(0).toUpperCase()}${firstPath.substr(1)} | CSI Intranet`;
        }

        return () => document.querySelector('title').textContent = 'CSI Intranet';
    }, [paths]);

    const handleMenuShow = () => {
        var time = 0;
        if (showMenu) {
            time = 500;
            window.$('#sidenav').removeClass('animate__slideInLeft');
            window.$('#sidenav').addClass('animate__slideOutLeft');
            window.$('#sidenav').addClass('animate__faster');
        } else {
            window.$('#sidenav').removeClass('animate__slideOutLeft');
            window.$('#sidenav').removeClass('animate__faster');
            window.$('#sidenav').addClass('animate__slideInLeft');
        }

        setTimeout(() => {
            setMenuShow((oldMenuShow) => !oldMenuShow);
        }, time)


    }


    return <>
        <Topbar onClickMenu={handleMenuShow} />

        {
            showMenu &&
            <>
                <Sidenav toggleMenu={handleMenuShow} />
                <div className="overlay-menu-mobile" onClick={handleMenuShow}></div>
            </>
        }

        <div className="page-wrapper">
            <div className="page-content">
                <div>
                    {/* Page Title */}
                    <div className="row">
                        <div className="col-12" style={{ maxWidth: showMenu && screen.width > 1024 ? '78vw' : null }}>
                            <div className="page-title-box">
                                <div className="float-right">
                                    <ol className="breadcrumb">
                                        {
                                            paths.map((path, i) => {
                                                return (
                                                    <li
                                                        className={`breadcrumb-item ${paths.length === i + 1 ? 'active' : null}`}
                                                        style={{ textTransform: 'capitalize' }} key={i}>
                                                        {
                                                            paths.length === i + 1 ?
                                                                path
                                                                :
                                                                <a href={`/${paths.slice(0, i + 1).join('/')}`}>
                                                                    {path}
                                                                </a>
                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                    </ol>
                                </div>
                                <h4 className="page-title" style={{ textTransform: 'capitalize' }}>{paths[0]?.replaceAll('-', ' ')}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {customAlert.show && <div className="col-md-12">
                            <div className={`alert icon-custom-alert alert-outline-${customAlert.severity} fade show`} role="alert">
                                {alertEmojis[customAlert.severity]}
                                <div className="alert-text">
                                    <strong>{customAlert.title}</strong>{customAlert.message}
                                </div>

                                <div className="alert-close">
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true"><i className="mdi mdi-close text-danger"></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>}
                        <div className="col-12" style={{ maxWidth: showMenu && screen.width > 1024 ? '78vw' : null }}>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default AppLayout;