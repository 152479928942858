import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useAxios from "../hooks/useAxios";
import { toast } from "react-toastify";

const PasswordRecovery = () => {

    const defaultOpts = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const [data, setData] = useState({
        password: '',
        password_confirmation: '',
        token: '',
        email: ''
    });

    const [{ data: resetPasswordData, loading, error }, sendResetPassword] = useAxios({ url: `/auth/reset-password`, method: 'POST' }, { manual: true, useCache: false });

    useEffect(() => {
        const body = document.querySelector('body');
        body.classList.add('account-body');
        body.classList.add('accountbg');
    }, []);

    useEffect(() => {
        if (error) {
            toast.error(`Error: Los datos ingresados son incorrectos.`, defaultOpts);
        }
    }, [error])

    useEffect(() => {
        if (resetPasswordData) {
            console.log(resetPasswordData?.data);
        }
    }, [resetPasswordData])

    useEffect(() => {
        const token = searchParams?.get('token');
        if (token) {
            setData((oldData) => {
                return {
                    ...oldData,
                    token: token
                }
            });
        } else {
            navigate('/login');
        }
    }, [searchParams])

    const handleChange = (e) => {
        setData((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (loading) return;
        sendResetPassword({ data });
    }

    return (
        <div className="container">
            <div className="row vh-100 ">
                <div className="col-12 align-self-center">
                    <div className="auth-page">
                        <div className="card auth-card shadow-lg">
                            <div className="card-body">
                                <div className="px-3">
                                    {
                                        resetPasswordData ?
                                            <div className="text-center">
                                                <h1>😃</h1>
                                                <br />
                                                <h4>Tu contraseña se ha actualizado exitosamente.</h4>
                                                <br />
                                                <Link to="/login" className="btn btn-gradient-primary btn-round btn-block waves-effect waves-light">
                                                    Volver e iniciar sesión
                                                </Link>
                                            </div>
                                            :
                                            <>
                                                <div className="text-center auth-logo-text">
                                                    <h4 className="mt-0 mb-3 mt-5">Recuperar Contraseña</h4>
                                                </div>
                                                <form className="form-horizontal auth-form my-4" autoComplete="off" onSubmit={handleSubmit}>

                                                    <div className="form-group">
                                                        <label >Correo electrónico</label>
                                                        <div className="input-group mb-3">
                                                            <span className="auth-form-icon">
                                                                <i className="dripicons-user"></i>
                                                            </span>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="email"
                                                                placeholder="Ingrese el correo"
                                                                onChange={handleChange}
                                                                value={data?.email}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="password">Contraseña Nueva</label>
                                                        <div className="input-group mb-3">
                                                            <span className="auth-form-icon">
                                                                <i className="dripicons-lock"></i>
                                                            </span>
                                                            <input
                                                                type="password"
                                                                className='form-control'
                                                                id="password"
                                                                name="password"
                                                                placeholder="Ingrese la contraseña"
                                                                onChange={handleChange}
                                                                value={data?.password}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="password">Confirmar Contraseña</label>
                                                        <div className="input-group mb-3">
                                                            <span className="auth-form-icon">
                                                                <i className="dripicons-lock"></i>
                                                            </span>
                                                            <input
                                                                type="password"
                                                                className='form-control'
                                                                id="password"
                                                                name="password_confirmation"
                                                                placeholder="Ingrese la contraseña"
                                                                onChange={handleChange}
                                                                value={data?.password_confirmation}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row mt-4">
                                                        <div className="col-sm-12 text-right">
                                                            <a href="/login" className="text-muted font-13"><i className="dripicons-lock"></i>Volver e Iniciar Sesión</a>
                                                        </div>
                                                    </div>

                                                    <div className="form-group mb-0 row">
                                                        <div className="col-12 mt-2">
                                                            <button disabled={loading} className="btn btn-gradient-primary btn-round btn-block waves-effect waves-light">
                                                                Enviar <i className="fas fa-sign-in-alt ml-1"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordRecovery;