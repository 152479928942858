import defaultUserImg from "../assets/img/user.png";
import patter2 from "../assets/img/pattern2.png"
import { useState } from "react";
import ImgUploadInput from "../components/ImgUploadInput";
import useAxios from "../hooks/useAxios";
import { useEffect } from "react";
import VacationsComponent from "../components/Profile/VacationsComponent";
import clsx from "clsx";
import TabPanel from "../components/TabPanel";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const Profile = () => {

    const [searchParams] = useSearchParams();

    const { setAuthInfo } = useAuth();

    const [profileData, setProfileData] = useState({
        phoneNumber: '',
        image: '',
        youtube: '',
        facebook: '',
        instagram: '',
        twitter: '',
        linkedin: ''
    });

    const [currentCalendar, setCurrentCalendar] = useState(null);

    const [currentUser, setCurrentUser] = useState({});

    const [{ data: updatedProfileData, loading: updatedProfileLoading }, getProfile] = useAxios({ url: `my-account/profile` }, { useCache: false });

    const [{ data: updateData, loading: updateLoading }, updateProfile] = useAxios({ url: `my-account/profile/update-profile`, method: 'POST' }, { manual: true, useCache: false });

    const [{ data: updatePasswordData, loading: updatePasswordLoading }, sendUpdatePassword] = useAxios({ url: `my-account/profile/update-password`, method: 'POST' }, { manual: true, useCache: false });

    const [showUpdateMessage, setShowUpdateMessage] = useState(false);

    const [showProfileSaved, setShowProfileSaved] = useState(false);

    const [currentTab, setCurrentTab] = useState(1);

    const [updatePassword, setUpdatePassword] = useState({
        password: '',
        currentPassword: '',
        password_confirmation: '',
        _method: 'PUT'
    });

    useEffect(() => {
        getProfile();
    }, []);

    useEffect(() => {
        if (showUpdateMessage) {
            setTimeout(() => {
                setShowUpdateMessage(false);
            }, 5000);
        }
    }, [showUpdateMessage])

    useEffect(() => {
        if (updatePasswordData) {
            setShowUpdateMessage(true);
        }
    }, [updatePasswordData])

    useEffect(() => {
        const tabNumber = searchParams?.get('tab');
        if (tabNumber) {
            setCurrentTab(Number(tabNumber));
        }
    }, [searchParams])

    useEffect(() => {
        if (currentUser) {
            setProfileData((oldProfileData) => {
                return {
                    image: null,
                    phoneNumber: currentUser?.phoneNumber || '--',
                    youtube: currentUser?.youtube || '--',
                    facebook: currentUser?.facebook || '--',
                    instagram: currentUser?.instagram || '--',
                    twitter: currentUser?.twitter || '--',
                    linkedin: currentUser?.linkedin || '--',
                }
            });

            setAuthInfo((oldAuthInfo) => {
                return {
                    ...oldAuthInfo,
                    user: {
                        ...oldAuthInfo.user,
                        ...currentUser
                    }
                }
            });
        }
    }, [currentUser]);

    useEffect(() => {
        if (updateData) {
            setCurrentUser((oldCurrentUser) => {
                return {
                    ...oldCurrentUser,
                    ...updateData?.data
                }
            });

            setShowProfileSaved(true);
        }
    }, [updateData])

    useEffect(() => {
        if (updatedProfileData) {
            setCurrentUser((oldCurrentUser) => {
                return {
                    ...oldCurrentUser,
                    ...updatedProfileData?.data
                }
            });
        }
    }, [updatedProfileData])

    useEffect(() => {
        if (showProfileSaved) {
            setTimeout(() => {
                setShowProfileSaved(false);
            }, 5000)
        }
    }, [showProfileSaved]);

    const handleProfileChange = (e) => {
        if (e.target.type === 'checkbox') {
            const value = profileData[e.target.name]?.includes(e.target.value);
            if (value) {
                const newValues = profileData[e.target.name]?.filter(n => n !== e.target.value);
                setProfileData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: newValues
                    }
                });
            } else {
                setProfileData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: [...profileData[e.target.name], e.target.value]
                    }
                });
            }
            return;
        }


        setProfileData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    const handleUpdateProfile = (e) => {
        e.preventDefault();

        const formData = new FormData();

        Object.keys(profileData).forEach((key) => {
            if (profileData[key]) {
                if (key === 'image') {
                    formData?.append(key, profileData[key], profileData[key]?.name);
                } else {
                    formData?.append(key, profileData[key]);
                }
            }
        });

        updateProfile({ data: formData });
    }

    const handleCalendar = (calendar) => {
        setCurrentCalendar(calendar);
    }

    const handlePasswordChange = (e) => {
        setUpdatePassword((oldUpdatePassword) => {
            return {
                ...oldUpdatePassword,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (updatePasswordLoading) return;

        sendUpdatePassword({
            data: {
                ...updatePassword
            }
        });
    }

    return (
        <>
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body met-pro-bg" style={{
                                backgroundImage: `url(${patter2})`,
                                width: '100%',
                                backgroundSize: '100% 100%'
                            }}>
                                <div className="met-profile">
                                    <div className="row">
                                        <div className="col-lg-6 align-self-center mb-3 mb-lg-2">
                                            <div className="met-profile-main">
                                                <div className="met-profile-main-pic">
                                                    <img src={currentUser?.imagePath || defaultUserImg} alt="" className="rounded-circle" style={{ width: '130px', height: '130px' }} />
                                                    <span className="fro-profile_main-pic-change">
                                                        <i data-toggle="modal" data-animation="bounce" data-target="#updateProfileModal" type="button" className="fas fa-camera"></i>
                                                    </span>
                                                </div>
                                                <div className="met-profile_user-detail">
                                                    <h5 className="met-user-name">{currentUser?.name}</h5>
                                                    <p className="mb-0 met-user-name-post">{currentUser?.position?.name || '--'}</p>
                                                    <p className="mb-0 met-user-name-post">{currentUser?.costCenter?.name || '--'}</p>
                                                    <p className="mb-0 met-user-name-post">{currentUser?.service?.name || ''}</p>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 ml-auto">
                                            <ul className="list-unstyled personal-detail">
                                                <li className="">
                                                    <i className="dripicons-phone mr-2 text-info font-18"></i>
                                                    <b> Teléfono </b> : {currentUser?.phoneNumber}
                                                    <i data-toggle="modal" data-animation="bounce" data-target="#updateProfileModal" type="button" className="mx-2 dripicons-pencil"></i>
                                                </li>
                                                <li className="mt-2">
                                                    <i className="dripicons-mail text-info font-18 mt-2 mr-2"></i>
                                                    <b> Email </b> : {currentUser?.email}
                                                    <i data-toggle="modal" data-animation="bounce" data-target="#updateProfileModal" type="button" className="mx-2 "></i>
                                                </li>
                                                <li className="mt-2">
                                                    <i className="dripicons-location text-info font-18 mt-2 mr-2"></i>
                                                    <b>Oficina</b> : {currentUser?.office?.name || 'No asignada.'}
                                                    <i data-toggle="modal" data-animation="bounce" data-target="#updateProfileModal" type="button" className="mx-2 "></i>
                                                </li>
                                            </ul>
                                            <div className="button-list btn-social-icon">
                                                {
                                                    currentUser?.facebook &&
                                                    <a target="_blank" href={currentUser?.facebook} type="button" className="btn btn-primary btn-circle">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                }
                                                {
                                                    currentUser?.youtube &&
                                                    <a target="_blank" href={currentUser?.youtube} type="button" className="btn btn-pink btn-circle">
                                                        <i className="fab fa-youtube"></i>
                                                    </a>
                                                }
                                                {
                                                    currentUser?.twitter &&
                                                    <a target="_blank" href={currentUser?.twitter} type="button" className="btn btn-secondary btn-circle">
                                                        <i className="fab fa-twitter"></i>
                                                    </a>
                                                }
                                                {
                                                    currentUser?.linkedin &&
                                                    <a target="_blank" href={currentUser?.linkedin} type="button" className="btn btn-orange btn-circle">
                                                        <i className="fab fa-linkedin-in"></i>
                                                    </a>
                                                }
                                                {
                                                    currentUser?.instagram &&
                                                    <a target="_blank" href={currentUser?.instagram} type="button" className="btn btn-pink btn-circle">
                                                        <i className="fab fa-instagram"></i>
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setCurrentTab(1)
                                            }}
                                            href="#"
                                            className={clsx(["nav-link cursor-pointer"], {
                                                'active': currentTab === 1
                                            })}
                                        >
                                            General
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setCurrentTab(2)
                                            }}
                                            href="#"
                                            className={clsx(["nav-link cursor-pointer"], {
                                                'active': currentTab === 2
                                            })}
                                        >
                                            Servicios
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setCurrentTab(4)
                                            }}
                                            href="#"
                                            className={clsx(["nav-link cursor-pointer"], {
                                                'active': currentTab === 4
                                            })}
                                        >
                                            Proximamente...
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <TabPanel value={1} currentValue={currentTab}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="">
                                        <h3 className="mb-4">Tus Datos</h3>
                                        <form className="form-horizontal form-material mb-0">
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label>Nombre Completo</label>
                                                    <input readOnly type="text" placeholder="Nombre Completo" className="form-control" value={currentUser?.name || '--'} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Código SAP</label>
                                                    <input readOnly type="text" placeholder="Codigo sap" className="form-control" value={currentUser?.sapCode || '--'} />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label>RUT</label>
                                                    <input readOnly type="text" placeholder="Rut" className="form-control" value={currentUser?.documentNumber || '--'} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Cargo</label>
                                                    <input readOnly type="text" placeholder="Codigo sap" className="form-control" value={currentUser?.position?.name || '--'} />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label>Tipo de Contrato</label>
                                                    <input readOnly type="text" placeholder="Tipo de contrato" className="form-control" value={currentUser?.contractType?.name || '--'} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Fecha de Ingreso</label>
                                                    <input readOnly type="text" placeholder="Fecha de ingreso" className="form-control" value={currentUser?.formattedAdmissionDate || '--'} />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label>Días de vacaciones:</label>
                                                    <input readOnly type="text" placeholder="Días de vacaciones" className="form-control" value={currentUser?.days || '--'} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Centro de costo</label>
                                                    <input readOnly type="text" placeholder="Centro de costo" className="form-control" value={currentUser?.costCenter?.name || '--'} />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label>Servicio:</label>
                                                    <input readOnly type="text" placeholder="servicio" className="form-control" value={currentUser?.service?.name || '--'} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Fecha de nacimiento:</label>
                                                    <input readOnly type="text" placeholder="Fecha de nacimiento" className="form-control" value={currentUser?.formattedBirthdayDate || '--'} />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h3>
                                        Actualizar contraseña
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Contraseña Actual</label>
                                            <input
                                                type="password"
                                                name="currentPassword"
                                                placeholder="Contraseña actual"
                                                className="form-control"
                                                value={updatePassword?.currentPassword}
                                                onChange={handlePasswordChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Nueva Contraseña</label>
                                            <input
                                                type="password"
                                                placeholder="Contraseña nueva"
                                                name="password"
                                                className="form-control"
                                                value={updatePassword?.password}
                                                onChange={handlePasswordChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Confirmar Contraseña</label>
                                            <input
                                                type="password"
                                                placeholder="Confirmar contraseña"
                                                className="form-control"
                                                name="password_confirmation"
                                                value={updatePassword?.password_confirmation}
                                                onChange={handlePasswordChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    {
                                        showUpdateMessage &&
                                        <span className="mx-4">
                                            La contraseña ha sido actualizada exitosamente.
                                        </span>
                                    }
                                    <button type="submit" disabled={updatePasswordLoading} className="btn btn-primary">
                                        Actualizar Contraseña
                                    </button>
                                </div>
                            </form>
                        </TabPanel>
                        <TabPanel className="row" value={2} currentValue={currentTab}>
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label>Servicios</label>
                                                <div className="row">
                                                    {
                                                        currentUser?.services?.map((service, i) => {
                                                            return (
                                                                <div key={i} className="col-md-3 my-2">
                                                                    <input readOnly type="text" placeholder="Fecha de ingreso" className="form-control" value={service?.name} />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel className="row" value={3} currentValue={currentTab}>
                            <VacationsComponent onCalendar={handleCalendar} />
                        </TabPanel>
                        <TabPanel className="row" value={4} currentValue={currentTab}>
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </div>
                </div>
            </div>

            {/* UPDATE PROFILE MODAL */}
            <div className="modal fade" id="updateProfileModal" tabIndex="-1" role="dialog" aria-labelledby="updateProfileModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="updateProfileModalLabel">
                                Actualizar Perfil
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleUpdateProfile}>
                            <div className="modal-body">
                                {
                                    showProfileSaved ?
                                        <p className="text-center">
                                            <b>Los cambios han sido guardados exitosamente.</b>
                                        </p>
                                        :
                                        null
                                }
                                <div className="row">
                                    <div className="form-group mb-3 col-md-6">
                                        <div className="text-center">
                                            <i className="fas fa-camera fa-2x fa-lg"></i>
                                            <ImgUploadInput
                                                previewImage={currentUser?.imagePath}
                                                style={{
                                                    height: "100px",
                                                    width: "100px",
                                                    marginBottom: '10px'
                                                }}
                                                name="image"
                                                change={handleProfileChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Número de teléfono</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ingrese un número teléfonico"
                                            name="phoneNumber"
                                            value={profileData?.phoneNumber}
                                            onChange={handleProfileChange}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <h3>Redes sociales</h3>
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <label>Youtube</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ingrese link de canal de youtube"
                                            name="youtube"
                                            value={profileData?.youtube}
                                            onChange={handleProfileChange}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <label>LinkedIn</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ingrese link de su perfil en linkedIn"
                                            name="linkedin"
                                            value={profileData?.linkedin}
                                            onChange={handleProfileChange}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <label>Instagram</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ingrese link de su perfil en instagram"
                                            name="instagram"
                                            value={profileData?.instagram}
                                            onChange={handleProfileChange}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <label>Twitter</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ingrese link de su perfil en twitter"
                                            name="twitter"
                                            value={profileData?.twitter}
                                            onChange={handleProfileChange}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <label>Facebook</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ingrese link de perfil de facebook"
                                            name="facebook"
                                            value={profileData?.facebook}
                                            onChange={handleProfileChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Cancelar</button>
                                <button type="submit" className="btn btn-primary" disabled={updateLoading}>
                                    {
                                        updateLoading ?
                                            'Cargando...'
                                            :
                                            'Actualizar información'
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Profile;