import { useEffect, useState } from "react";
import useAxiosWebDoxcs from "../../hooks/useAxiosWebDoxcs";
import env from "../../utils/env";

const WebDocxDocumentList = ({ url }) => {

    const [{ data, loading, error }, fetchOnWebdocx] = useAxiosWebDoxcs({}, { manual: true, useCache: false });

    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        if (url) {
            fetchOnWebdocx({
                data: {
                    username: env('WEBDOCX_USER'),
                    password: env('WEBDOCX_PASSWORD'),
                    grant_type: "password"
                },

                url: '/oauth/token',
                method: 'POST'
            }).then((response) => {
                fetchOnWebdocx({
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        Authorization: `Bearer ${response?.data?.access_token}`
                    },
                    url: url
                }).then((response2) => {
                    const responseDocuments = response2?.data?.documents?.filter((document) => document?.signed);
                    setDocuments(responseDocuments);
                })
            })
        }
    }, [url]);

    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th>Nro</th>
                        <th>Documento</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ?
                            <tr>
                                <td colSpan={3}>
                                    <h3 className="text-center">
                                        Cargando
                                    </h3>
                                </td>
                            </tr>
                            :
                            documents.length === 0 ?
                                <tr>
                                    <td colSpan={3}>
                                        <h3 className="text-center">
                                            No hay documentos.
                                        </h3>
                                    </td>
                                </tr>
                                :
                                documents?.map((document, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>
                                                {i + 1}
                                            </td>
                                            <td>
                                                {document?.name}
                                            </td>
                                            <td>
                                                <a href={document?.download_url} className="btn btn-primary" download={document?.name} target="_blank">
                                                    Descargar
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })
                    }
                    <tr>

                    </tr>
                </tbody>
            </table>
        </div>
    )

}

export default WebDocxDocumentList;