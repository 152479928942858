import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import SpinnerDots from "../components/Spinners/SpinnerDots";
import useSystemFiles from "../hooks/useSystemFiles";
import { Button, Dropdown, Modal } from "react-bootstrap";
import FilesGridView from "../components/SystemFiles/FilesGridView";
import FilesListView from "../components/SystemFiles/FilesListView";
import useAxios from "../hooks/useAxios";
import { useFeedBack } from "../contexts/FeedBackContext";
import update from "immutability-helper";

const FilesManagement = () => {

    const { permissions } = useAuth();

    const { setCustomAlert } = useFeedBack();

    const { setLoading } = useFeedBack();

    const [showMode, setShowMode] = useState('ti-layout-grid2');

    const [showModalCreate, setShowModalCreate] = useState(false);

    const [createEntityName, setCreateEntityName] = useState('');

    const [externalUrl, setExternalUrl] = useState('');

    const [dots, setDots] = useState("");

    const [currentFolder, setCurrentFolder] = useState(null);

    const [filters, setFilters] = useState({
        search: '',
        fileId: '',
        perPage: 10,
        page: 1
    });

    const [{ data: createFileResponse, loading: createFileLoading }, createFile] = useAxios({ url: `/system-files`, method: 'POST' }, { manual: true, useCache: false });

    const [currentFiles, setCurrentFiles] = useState([]);

    const [{ systemFiles, total, numberOfPages, size, error, loading }, getFileDirs] = useSystemFiles({ axiosConfig: { params: { ...filters } }, options: { useCache: false } });

    useEffect(() => {
        if (currentFolder) {
            setCurrentFiles([]);
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    search: '',
                    page: 1,
                    fileId: currentFolder?.id
                }
            });
        } else {
            setCurrentFiles([]);
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    search: '',
                    page: 1,
                    fileId: ''
                }
            });
        }
    }, [currentFolder])

    useEffect(() => {
        if (createFileResponse) {
            setCurrentFiles((oldFiles) => {
                return [createFileResponse.data, ...oldFiles]
            })

            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'El archivo fue cargado exitosamente.',
                show: true
            });
            console.log(createFileResponse)
        }
    }, [createFileResponse])

    useEffect(() => {
        if (systemFiles?.length > 0) {
            setCurrentFiles((oldFiles) => {
                return [...oldFiles, ...systemFiles];
            })
        }
    }, [systemFiles])

    useEffect(() => {
        setLoading({
            show: createFileLoading,
            message: "Subiendo archivo"
        });
    }, [createFileLoading])

    useEffect(() => {
        let id;

        if (createFileLoading) {
            id = setInterval(() => {
                setDots((oldDots) => oldDots.length < 3 ? oldDots + "." : "");
            }, 500);
        }

        return () => {
            if (id) clearInterval(id);
        }
    }, [createFileLoading]);

    const handleChange = (e) => {
        setCurrentFiles([]);

        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        })
    }

    const handleDocument = (e) => {

        if (e.target.files.length === 0) {
            return;
        }

        const formData = new FormData();

        formData.append('file', e.target.files[0], e.target.files[0]?.name);
        formData.append('type', 'file');

        if (filters?.fileId) {
            formData.append('fileDirId', filters?.fileId);
        }

        createFile({ data: formData });
    }

    const handleCreate = (e) => {
        e.preventDefault();

        if (!createEntityName) return alert('El nombre es obligatorio.');

        const dataToSend = {
            name: createEntityName,
            filePath: showModalCreate === 'folder' ? '' : externalUrl,
            type: showModalCreate,
            fileDirId: filters?.fileId
        }

        createFile({ data: dataToSend }).then(() => {
            setCreateEntityName('');
            setExternalUrl('');
            setShowModalCreate(false);
        });
    }

    const handleDeleteFile = (index) => {

        const newcurrentFiles = update(currentFiles, { $splice: [[index, 1]] });

        setCurrentFiles(newcurrentFiles);
    }

    const handleFolder = (folder) => {
        setCurrentFolder(folder);
    }

    return (
        <div>
            <div className="mb-1 row justify-content-between align-items-center">
                <div className="col-md-8">
                    <div className="d-flex align-items-center">
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" >
                                <i className="ti ti-plus mr-2"></i>
                                Nuevo
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as="label" htmlFor="file-input" style={{ cursor: 'pointer' }}>
                                    <i className="ti-files mr-2"></i>
                                    Documento
                                    <input
                                        type="file"
                                        id="file-input"
                                        onChange={handleDocument}
                                        className="d-none"
                                    />
                                </Dropdown.Item>
                                <Dropdown.Item as="button" onClick={() => setShowModalCreate('external')}>
                                    <i className="ti-link mr-2"></i>
                                    Link Externo
                                </Dropdown.Item>
                                <Dropdown.Item as="button" onClick={() => setShowModalCreate('folder')}>
                                    <i className="ti-folder mr-2"></i>
                                    Carpeta
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <button type="button" title="Cambiar vista" className="btn btn-primary ml-5" onClick={(e) => {
                            setShowMode((prevValue) => {
                                return prevValue === 'ti-layout-grid2' ? 'ti-view-list-alt' : 'ti-layout-grid2'
                            })
                        }}>
                            <i className={`${showMode}`}></i>
                        </button>
                    </div>
                </div>
                <div className="col-md-4">
                    <label htmlFor="name">Buscar:</label>
                    <input
                        id="name"
                        type="text"
                        name="search"
                        className="form-control"
                        value={filters?.search}
                        onChange={handleChange}
                        placeholder="buscar..."
                    />
                </div>
            </div>

            <div className="d-flex mt-5">
                <button className="btn" onClick={() => setCurrentFolder(null)}>
                    <i className="ti-home mr-2"></i>
                    Inicio
                </button>
                {

                    currentFolder?.fullPath?.map((folder, i) => {
                        return (
                            <div key={i}>
                                <i className="ti-arrow-right mr-2"></i>
                                <button className="btn" onClick={() => currentFolder?.id !== folder?.id ? setCurrentFolder(folder) : null}>
                                    <i className="ti-folder mr-2"></i>
                                    {folder?.name}
                                </button>
                            </div>
                        )
                    })
                }
            </div>

            {
                showMode === 'ti-layout-grid2' &&
                <FilesGridView
                    onClickFolder={handleFolder}
                    files={currentFiles}
                    onDeleteFile={handleDeleteFile}
                />
            }

            {
                showMode === 'ti-view-list-alt' &&
                <FilesListView files={currentFiles} onDeleteFile={handleDeleteFile} />
            }

            {
                loading &&
                <div className="d-flex justify-content-center align-items-center">
                    <SpinnerDots show={loading} color="#1761fd" />
                </div>
            }

            {
                !loading && currentFiles?.length === 0 ?
                    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
                        No se encontrarón archivos.
                    </div>
                    :
                    null
            }
            {
                numberOfPages > filters?.page && !loading ?
                    <div className="text-center">
                        <button className="btn btn-outline-primary" onClick={() => setFilters((oldFilters) => {
                            return {
                                ...oldFilters,
                                page: oldFilters?.page + 1
                            }
                        })}>
                            Cargar Más
                        </button>
                    </div>
                    :
                    null
            }

            <Modal show={showModalCreate} onHide={() => setShowModalCreate(false)} centered>
                <Modal.Body>
                    <form onSubmit={handleCreate}>
                        <div className="form-group">
                            <label className="form-label">
                                {showModalCreate === 'folder' ? 'Carpeta nueva' : 'Nombre'}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Escriba..."
                                value={createEntityName}
                                onChange={(e) => setCreateEntityName(e.target.value)}
                            />
                        </div>
                        {
                            showModalCreate === 'external' &&
                            < div className="form-group">
                                <label className="form-label">
                                    Url Externa
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Escriba la url..."
                                    value={externalUrl}
                                    onChange={(e) => setExternalUrl(e.target.value)}
                                />
                            </div>
                        }
                        <div className="d-flex align-items-center justify-content-between">
                            <button className="btn btn-muted" type="button" onClick={() => setShowModalCreate(false)}>
                                Cancelar
                            </button>
                            <button className="btn btn-primary" >
                                {
                                    createFileLoading ?
                                        <div className="d-flex align-items-center">
                                            <p className="m-0 text-white">
                                                Creando{dots}
                                            </p>
                                        </div>
                                        :
                                        'Crear'
                                }
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default FilesManagement;