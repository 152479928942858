import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "../../hooks/useAxios";

const HolidaysLoadFile = () => {

    const [file, setFile] = useState(null);

    const [{ data: createData, loading: createLoading }, createHolidays] = useAxios({ url: `/holidays/excel-load`, method: 'post' }, { manual: true, useCache: false });

    const [showValidDates, setShowValidDates] = useState(false);

    const [showInvalidDates, setShowInvalidDates] = useState(false);

    useEffect(() => {
        if (createData) {
            setShowValidDates(true);
            setShowInvalidDates(true);
        }
    }, [createData])

    const handleSubmit = (e) => {
        e?.preventDefault?.();

        if (createLoading) {
            return;
        }

        if (!file) {
            alert('El archivo es obligatorio.');
        }

        const data = new FormData();
        data.append('file', file);

        createHolidays({ data });
    }

    return (
        <div className="card">
            <div className="card-header">
                <h4>Crear Días Feriados</h4>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="card-body">
                    <div className="form-group">
                        <label htmlFor="">Archivo excel</label>
                        <br />
                        <input
                            type="file"
                            className="from-control"
                            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={(e) => setFile(e?.target?.files[0])}
                        />
                    </div>
                    {
                        createData?.message &&
                        <p className="text-success">
                            {createData?.message}
                        </p>
                    }
                    {
                        createData?.validDates?.length > 0 && showValidDates ?
                            <div className="row mb-4" style={{ borderBottom: '1px solid gray' }}>
                                <h4 className="col-6">
                                    Días agregados exitosamente:
                                </h4>
                                <div className="col-6 text-right">
                                    <button className="btn btn-danger" onClick={() => setShowValidDates(false)}>Eliminar</button>
                                </div>
                                <div className="col-md-12">
                                    {
                                        createData?.validDates?.map((day, i) => {
                                            return (
                                                <h6 key={i}>{day}</h6>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            :
                            null
                    }
                    {
                        createData?.invalidDates?.length > 0 && showInvalidDates ?
                            <div className="row">
                                <h4 className="col-6">
                                    Estos días ya están creados:
                                </h4>
                                <div className="col-6 text-right">
                                    <button className="btn btn-danger" onClick={() => setShowInvalidDates(false)}>Eliminar</button>
                                </div>
                                <div className="col-md-12">
                                    {
                                        createData?.invalidDates?.map((day, i) => {
                                            return (
                                                <p className="text-danger" key={i}>{day}</p>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
                <div className="card-footer text-right">
                    <Link to={'/dias-feriados'} className="btn btn-danger mx-2">
                        Volver
                    </Link>
                    <button disabled={!file || createLoading} className="btn btn-primary mx-2">
                        {
                            createLoading ?
                                'Cargando'
                                :
                                'Enviar'
                        }
                    </button>
                </div>
            </form>
        </div>
    )
}

export default HolidaysLoadFile;