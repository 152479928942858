import { useEffect, useState } from 'react';
import useAxios from './useAxios';

const useSystemFiles = ({ options, axiosConfig } = {}) => {

  const [{ data, error, loading }, getSystemFiles] = useAxios({ url: '/system-files', ...axiosConfig }, options);

  const [systemFiles, setSystemFiles] = useState([]);

  const [total, setTotal] = useState(0);

  const [size, setSize] = useState(0);

  const [numberOfPages, setNumberOfPages] = useState(0);

  useEffect(() => {
    if (data) {
      setSystemFiles(data.data);
      setTotal(data?.meta?.total);
      setSize(data?.meta?.per_page);
      setNumberOfPages(data.meta?.last_page);
    }

  }, [data, loading, error]);

  return [{ systemFiles, total, numberOfPages, size, error, loading }, getSystemFiles];
};

export default useSystemFiles;
