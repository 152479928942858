import clsx from "clsx";
import { getIn } from "formik";

const Input = ({
    label,
    type = 'text',
    id,
    field = {},
    form = {},
    ...props
}) => {
    const { touched = {}, errors = {} } = form;

    const isTouched = getIn(touched, field.name);

    const error = getIn(errors, field.name);

    const showError = isTouched && typeof error === 'string';

    return (
        <div className="form-group">
            {label && <label htmlFor={id}>{label}</label>}

            <input
                className={clsx("form-control", showError && 'is-invalid')}
                id={id}
                type={type}
                {...field}
                {...props}
            />

            {showError && <div className="invalid-feedback">{error}</div>}
        </div>
    )
}

export default Input;