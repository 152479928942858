import { Link } from 'react-router-dom';
import swal from '../utils/swal';

const ActionsDropdown = ({
    updatePath,
    dataForOnDelete,
    onDelete,
    deleteAlertTitle = '¿Está seguro?',
    deleteAlertText = '¡No podrá revertir esta acción!',
    deleteAlertConfirmBtnText = 'Si, ¡Estoy seguro!',
    updateTextButton = 'Actualizar',
    showDelete = true,
    showUpdate = true,
}) => {
    const handleDelete = async (e) => {
        e.preventDefault();

        const result = await swal({
            title: deleteAlertTitle,
            text: deleteAlertText,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: deleteAlertConfirmBtnText,
            cancelButtonText: 'Cancelar'
        });

        if (result.value) {
            onDelete?.(dataForOnDelete);
        }
    }

    const finalShowUpdate = showUpdate && updatePath;

    return <div className="dropdown d-inline-block float-right">
        <a className="nav-link dropdown-toggle arrow-none" id="dLabel4" data-toggle="dropdown" href="/#" role="button" aria-haspopup="false" aria-expanded="false">
            <i className="fas fa-ellipsis-v font-20 text-muted"></i>
        </a>
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dLabel4">
            {finalShowUpdate &&
                <Link className="dropdown-item" to={updatePath}>{updateTextButton}</Link>
            }
            {showDelete && <a className="dropdown-item" href="/#" onClick={handleDelete}>Eliminar</a>}
        </div>
    </div>;
}

export default ActionsDropdown;