import { useState } from "react";
import useUserEmployeesVacations from "../../hooks/useUserEmployeesVacations";
import vacationsColumns from "../../tableColumns/vacationsColumns"
import Table from "../Table"

const EmployeeVacations = ({ employeeId }) => {

    const [filters, setFilters] = useState({
        page: 1,
        search: '',
        employeeId: employeeId
    })

    const [{ userEmployeesVacations: records, total, numberOfPages, error: vacationsError, loading: vacationsLoading }, getVacations] = useUserEmployeesVacations({ axiosConfig: { params: { ...filters } }, options: { useCache: false } });


    const handlePageChange = (page) => {
        if (page > 0) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    return (
        <Table
            title="Vacaciones"
            columns={vacationsColumns({})}
            onSearchChange={(e) => handleChange({ target: { value: e?.target.value, name: 'search' } })}
            searchValue={filters?.search}
            excelUrl={'/my-account/employees/vacations/export/excel'}
            excelFileName='vacaciones.xlsx'
            items={records}
            total={total}
            currentPage={filters.page}
            pages={numberOfPages}
            loading={vacationsLoading}
            onChangePage={handlePageChange}
            perPageValue={filters?.perPage}
            onChagePerPage={(e) => {
                setFilters((oldFilters) => {
                    return {
                        ...oldFilters,
                        perPage: e.target.value,
                        page: 1
                    }
                })
            }}
        />
    )
}

export default EmployeeVacations;