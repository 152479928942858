import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "../hooks/useAxios";

const PasswordForgot = () => {

    const [email, setEmail] = useState('');

    const [{ data: forgotPasswordData, loading }, sendForgotPassword] = useAxios({ url: `/auth/forgot-password`, method: 'POST' }, { manual: true, useCache: false });

    useEffect(() => {
        if (forgotPasswordData) {
            console.log(forgotPasswordData);
        }
    }, [forgotPasswordData])

    useEffect(() => {
        const body = document.querySelector('body');

        body.classList.add('account-body');
        body.classList.add('accountbg');
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (loading) return;
        sendForgotPassword({
            data: {
                email
            }
        });
    }

    return (
        <div className="container">
            <div className="row vh-100 ">
                <div className="col-12 align-self-center">
                    <div className="auth-page">
                        <div className="card auth-card shadow-lg">
                            <div className="card-body">
                                <div className="px-3">
                                    {
                                        forgotPasswordData ?
                                            <div className="text-center">
                                                <h1>📩</h1>
                                                <br />
                                                <h4>Te hemos enviado un email a tu correo electrónico. Por favor revísalo.</h4>
                                                <br />
                                                <Link to="/login" className="btn btn-gradient-primary btn-round btn-block waves-effect waves-light">
                                                    Volver
                                                </Link>
                                            </div>
                                            :
                                            <>
                                                <div className="text-center auth-logo-text">
                                                    <h4 className="mt-0 mb-3 mt-5">Olvido de contraseña</h4>
                                                </div>
                                                <form className="form-horizontal auth-form my-4" autoComplete="off" onSubmit={handleSubmit}>

                                                    <div className="form-group">
                                                        <label >Correo electrónico</label>
                                                        <div className="input-group mb-3">
                                                            <span className="auth-form-icon">
                                                                <i className="dripicons-user"></i>
                                                            </span>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="email"
                                                                placeholder="Ingrese el correo"
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                value={email}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row mt-4">
                                                        <div className="col-sm-12 text-right">
                                                            <a href="/login" className="text-muted font-13"><i className="dripicons-lock"></i>Volver e Iniciar Sesión</a>
                                                        </div>
                                                    </div>

                                                    <div className="form-group mb-0 row">
                                                        <div className="col-12 mt-2">
                                                            <button disabled={loading} className="btn btn-gradient-primary btn-round btn-block waves-effect waves-light">
                                                                Enviar
                                                                <i className="fas fa-sign-in-alt ml-1"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordForgot;