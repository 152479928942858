import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../components/Table';
import { useAuth } from '../../contexts/AuthContext';
import { useFeedBack } from '../../contexts/FeedBackContext';
import useAxios from '../../hooks/useAxios';
import useHolidays from '../../hooks/useHolidays';
import holidayColumns from '../../tableColumns/holidayColumns';
import { mainPermissions } from '../../utils/MenuLinks';
import swal from '../../utils/swal';
import UserHavePermission from '../../utils/UserHavePermission';

const Holidays = () => {

    const { permissions } = useAuth();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [filters, setFilters] = useState({
        page: 1,
    })

    const [{ holidays, total, numberOfPages, loading: holidaysLoading }, getHolidays] = useHolidays({ axiosConfig: { params: { ...filters } }, options: { useCache: false } });

    const [{ loading: deleteLoading }, deleteRecord] = useAxios({ method: 'DELETE' }, { manual: true, useCache: false });

    useEffect(() => {
        setLoading({
            show: deleteLoading,
            message: 'Eliminando',
        })
    }, [deleteLoading]);

    const handlePageChange = (page) => {
        if (page > 0) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    const handleDelete = async (id) => {
        await deleteRecord({ url: `/holidays/${id}` });

        swal({
            icon: 'success',
            title: '¡Eliminado!',
            text: 'Registro eliminado exitosamente!'
        });

        getHolidays();
    }

    const handleDeleteSelected = async (selectedValues) => {
        await deleteRecord({ url: `/holidays/multiple`, data: { ids: selectedValues } });

        swal({
            icon: 'success',
            title: '¡Eliminados!',
            text: '¡Registros eliminado exitosamente!'
        });

        getHolidays();
    }

    return <>
        <div className="my-4 justify-content-end d-flex">
            {UserHavePermission(mainPermissions?.holidays[1]) &&
                <>
                    <Link to={"/dias-feriados/cargar-excel"} className="mx-3 btn btn-primary">
                        Cargar por archivo excel
                    </Link>
                    <Link to={"/dias-feriados/crear"} className="mx-3 btn btn-primary">
                        Crear Dia Feriado
                    </Link>
                </>
            }
        </div>
        <Table
            title="Dias Feriados"
            columns={holidayColumns({ onDelete: handleDelete })}
            excelUrl={'/holidays/export/excel'}
            excelFileName='dias-feriados.xlsx'
            items={holidays}
            total={total}
            currentPage={filters.page}
            pages={numberOfPages}
            loading={holidaysLoading}
            onChangePage={handlePageChange}
            onDeleteSelected={handleDeleteSelected}
            withSelection
            perPageValue={filters?.perPage}
            onChagePerPage={(e) => {
                setFilters((oldFilters) => {
                    return {
                        ...oldFilters,
                        perPage: e.target.value,
                        page: 1
                    }
                })
            }}
        />
    </>
}

export default Holidays;