import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import userColumns from "../../tableColumns/userColumns";
import Table from "../../components/Table";
import { useAuth } from "../../contexts/AuthContext";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";
import useUsers from "../../hooks/useUsers";
import { mainPermissions } from "../../utils/MenuLinks";
import swal from "../../utils/swal";
import UserHavePermission from "../../utils/UserHavePermission";

const Users = () => {

    const { permissions } = useAuth();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [filters, setFilters] = useState({
        page: 1,
        perPage: 10,
        search: ''
    });

    const [{ users, total, numberOfPages, error: usersError, loading: usersLoading }, getUsers] = useUsers({ axiosConfig: { params: { ...filters }, timeout: 1200000 }, options: { useCache: false } });

    const [{ error: deleteError, loading: deleteLoading }, deleteUser] = useAxios({ method: 'DELETE' }, { manual: true, useCache: false });

    useEffect(() => {
        setLoading?.({
            show: deleteLoading,
            message: 'Eliminando Usuario(s)'
        })
    }, [deleteLoading])

    useEffect(() => {
        if (deleteError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'Ha ocurrido un error al eliminar.',
                show: true
            });
        }

        if (usersError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los usuarios.',
                show: true
            });
        }
    }, [deleteError, usersError])

    const handleDelete = async (id) => {
        await deleteUser({ url: `/users/${id}` });

        swal({
            icon: 'success',
            title: '¡Eliminado!',
            text: '¡Usuario eliminado exitosamente!'
        });

        getUsers();
    }

    const handlePageChange = (page) => {
        if (page > 0) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    const handleDeleteSelected = async (selectedValues) => {
        await deleteUser({ url: `/users/multiple`, data: { ids: selectedValues } });

        swal({
            icon: 'success',
            title: '¡Eliminados!',
            text: '¡Usuarios eliminado exitosamente!'
        });

        getUsers();
    }

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    return (
        <div>
            <div className="my-4 d-md-flex justify-content-end">
                {
                    UserHavePermission(mainPermissions?.users[2]) ?
                        <Link to={"/usuarios/cargar-dias-de-vacaciones"} className="btn btn-primary mx-2">
                            Cargar días de vacaciones
                        </Link>
                        :
                        null
                }
                {
                    UserHavePermission(mainPermissions?.users[1]) ?
                        <Link to={"/usuarios/importar"} className="btn btn-success mx-2">
                            Importar excel
                        </Link>
                        :
                        null
                }
                {
                    UserHavePermission(mainPermissions?.users[1]) ?
                        <Link to={"/usuarios/crear"} className="btn btn-primary mx-2">
                            Crear usuario
                        </Link>
                        :
                        null
                }
            </div>

            <Table
                title="Usuarios"
                columns={userColumns({ onDelete: handleDelete })}
                onSearchChange={(e) => handleChange({ target: { value: e?.target.value, name: 'search' } })}
                searchValue={filters?.search}
                excelUrl={'/users/export/excel'}
                excelFileName='Usuarios.xlsx'
                items={users}
                total={total}
                currentPage={filters.page}
                pages={numberOfPages}
                loading={usersLoading}
                onChangePage={handlePageChange}
                onDeleteSelected={handleDeleteSelected}
                withSelection
                perPageValue={filters?.perPage}
                onChagePerPage={(e) => {
                    setFilters((oldFilters) => {
                        return {
                            ...oldFilters,
                            perPage: e.target.value,
                            page: 1
                        }
                    })
                }}
            />
        </div>
    )
}
export default Users;