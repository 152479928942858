import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { useEffect, useState } from "react";
import { useFeedBack } from "../../../contexts/FeedBackContext";
import defaultUserImg from "../../../assets/img/user.png";
import { format } from "date-fns";
import DateFormatter from "../../../components/DateFormatter";
import clsx from "clsx";
import TabPanel from "../../../components/TabPanel";
import EmployeeVacations from "../../../components/Employee/EmployeeVacations";

const BossEmployeesDetail = () => {
  const { id } = useParams();

  const { setLoading } = useFeedBack();

  const [currentEmployee, setCurrentEmployee] = useState(null);

  const [currentTab, setCurrentTab] = useState(1);

  const [{ data: employeeData, loading: loadingEmployeeData }, getEmployee] =
    useAxios({ url: `/my-account/employees/${id}` });

  useEffect(() => {
    if (employeeData) {
      setCurrentEmployee(employeeData.data);
    }
  }, [employeeData]);

  useEffect(() => {
    setLoading({
      message: "Obteniendo Datos",
      show: loadingEmployeeData,
    });
  }, [loadingEmployeeData]);

  return (
    <div>
      <div className="d-flex justify-content-end">
        <a href="/mis-empleados" className="btn btn-primary">
          Volver al listado
        </a>
      </div>
      <br />
      <br />
      <div className="card p-5">
        <div className="row align-items-center">
          <div className="col-md-4 text-center">
            <img
              src={currentEmployee?.imagePath || defaultUserImg}
              style={{ width: 120, height: 120, borderRadius: "100%" }}
            />
            <h4>{currentEmployee?.name || "--"}</h4>
          </div>
          <div className="col-md-8 col-12">
            <div className="row p-3" style={{ borderBottom: '1px solid whitesmoke' }}>
              <p className="col-md-12 p-3 card gradient-7">
                <b style={{ color: 'white' }}>
                  Perfil del trabajador
                </b>
              </p>
              <div className="col-md-4 mb-3">
                <span style={{ color: '#9f9f9f' }}>Genero:  </span> <br /> <b style={{ color: 'black' }}>{currentEmployee?.gender || '--'}</b>
              </div>
              <div className="col-md-4 mb-3">
                <span style={{ color: '#9f9f9f' }}>Fecha de cumpleaños:</span> <br /> <b style={{ color: 'black' }}>{currentEmployee?.admissionDate ? DateFormatter({ date: currentEmployee?.admissionDate }) : '--'}</b>
              </div>
              <div className="col-md-4 mb-3">
                <span style={{ color: '#9f9f9f' }}>RUT:</span> <br /> <b style={{ color: 'black' }}>{currentEmployee?.documentNumber || '--'}</b>
              </div>
            </div>
            <div className="row p-3" style={{ borderBottom: '1px solid whitesmoke' }}>
              <p className="col-md-12 p-3 card gradient-5">
                <b style={{ color: 'white' }}>
                  Información de contacto
                </b>
              </p>
              <div className="col-md-4 mb-3">
                <span style={{ color: '#9f9f9f' }}>Correo Electronico:  </span> <br /> <b style={{ color: 'black' }}>{currentEmployee?.email || '--'}</b>
              </div>
              <div className="col-md-4 mb-3">
                <span style={{ color: '#9f9f9f' }}>Numero telefonico:</span> <br /> <b style={{ color: 'black' }}>{currentEmployee?.phoneNumber || '--'}</b>
              </div>
              <div className="col-md-4 mb-3">
                <span style={{ color: '#9f9f9f' }}>Dirección:</span> <br /> <b style={{ color: 'black' }}>{currentEmployee?.address || '--'}</b>
              </div>
            </div>
            <div className="row p-3" style={{ borderBottom: '1px solid whitesmoke' }}>
              <p className="col-md-12 p-3 card gradient-6">
                <b style={{ color: 'white' }}>
                  Datos de la empresa
                </b>
              </p>
              <div class="progress mt-3">Datos de la empresa</div>
              <div className="col-md-4 mb-3">
                <span style={{ color: '#9f9f9f' }}>Ingresó a la empresa:  </span> <br /> <b style={{ color: 'black' }}>{currentEmployee?.admissionDate ? DateFormatter({ date: currentEmployee?.admissionDate }) : '--'}</b>
              </div>
              <div className="col-md-4 mb-3">
                <span style={{ color: '#9f9f9f' }}>Centro de costo:</span> <br /> <b style={{ color: 'black' }}>{currentEmployee?.costCenter?.name || '--'}</b>
              </div>
              <div className="col-md-4 mb-3">
                <span style={{ color: '#9f9f9f' }}>Cargo:</span> <br /> <b style={{ color: 'black' }}>{currentEmployee?.position?.name || '--'}</b>
              </div>
              <div className="col-md-4 mb-3">
                <span style={{ color: '#9f9f9f' }}>Tipo de contrato:  </span> <br /> <b style={{ color: 'black' }}>{currentEmployee?.contractType?.name || '--'}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      {currentEmployee &&
        <>
          <div className="card">
            <div className="card-header">
              <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentTab(1)
                    }}
                    href="#"
                    className={clsx(["nav-link cursor-pointer"], {
                      'active': currentTab === 1
                    })}
                  >
                    <i className="ti-calendar mr-2"></i>
                    <span>
                      Vacaciones
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <TabPanel value={1} currentValue={currentTab}>
            <div className="table-responsive" style={{ maxWidth: '100vw' }}>
              <EmployeeVacations
                employeeId={id}
              />
            </div>
          </TabPanel>
        </>
      }
    </div >
  )
}

export default BossEmployeesDetail;
