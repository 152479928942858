import FileListRow from "./FileListRow";

const FilesListView = ({ files, onReachEnd, onDeleteFile }) => {
    return (
        <div>
            {
                files?.map?.((file, i) => {
                    return (
                        <FileListRow
                            file={file}
                            key={i}
                            onDelete={() => onDeleteFile?.(i)}
                        />
                    )
                })
            }
        </div>
    )
}

export default FilesListView;