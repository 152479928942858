import { useAuth } from "../contexts/AuthContext";

const UserHavePermission = (menuPermissions) => {

    const { permissions, user } = useAuth();

    return menuPermissions?.some?.(r => permissions?.indexOf(r) >= 0) ||
        !menuPermissions ||
        menuPermissions?.length === 0 ||
        permissions?.includes(menuPermissions) ||
        user?.role?.name === "sys-001-super-admin"
        
}


export default UserHavePermission;