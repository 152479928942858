import { Navigate, Route, Routes } from "react-router-dom";
import AppLayout from "../components/Layouts/AppLayout";
import RequireAuth from "../components/RequireAuth";
import RequireGuest from "../components/RequireGuest";
import AdminModulesList from "../pages/AdminModulesList";
import AplicationsModulesList from "../pages/AplicationsModulesList";
import NotVerified from "../pages/email-verification/NotVerified";
import Verify from "../pages/email-verification/Verify";
import ExternalLinks from "../pages/ExternalLinks";
import Login from "../pages/Login";
import ManagementModulesList from "../pages/ManagementModulesList";
import PasswordForgot from "../pages/PasswordForgot";
import PasswordRecovery from "../pages/PasswordRecovery";
import Permissions from "../pages/permissions/Permissions";
import EmployeesVacations from "../pages/vacations/EmployeesVacations";
import BossEmployees from "../pages/my-account/employees/BossEmployees";
import { MenuLinks } from "../utils/MenuLinks";
import BossEmployeesDetail from "../pages/my-account/employees/BossEmployeesDetail";

const RoutesContainer = () => {
    return <Routes>
        <Route path="/" element={<Navigate to="/perfil" />} />
        <Route path="/login" element={<RequireGuest element={<Login />} />} />
        <Route path="/email/verificar/:id/:hash" element={<Verify />} />
        <Route path="/email/no-verificado" element={<NotVerified />} />

        <Route path="/recuperar-contrasena" element={<PasswordRecovery />} />
        <Route path="/olvido-de-contrasena" element={<PasswordForgot />} />

        <Route path="/permissions" element={<Permissions />} />

        <Route element={<RequireAuth element={<AppLayout />} />}>
            <Route path="/administrar" element={<RequireAuth element={<AdminModulesList />} />} />
            <Route path="/gestionar" element={<RequireAuth element={<ManagementModulesList />} />} />
            <Route path="/aplicaciones" element={<RequireAuth element={<AplicationsModulesList />} />} />
            <Route path={`/enlaces-externos`} element={<RequireAuth element={<ExternalLinks />} />} />
            {
                MenuLinks?.map((menuLink, i) => {
                    if (menuLink?.children) {
                        return [...menuLink?.children];
                    } else {
                        return menuLink
                    }
                }).flat().map((menuLink, i) => {
                    return (
                        menuLink?.component ?
                            <Route key={i} path={menuLink?.path} element={<RequireAuth screenPermission={menuLink?.permissions} element={menuLink?.component} />} />
                            :
                            null
                    )
                })
            }
            <Route element={<BossEmployees />} path={`/mis-empleados`} />
            <Route element={<BossEmployeesDetail />} path={`/mis-empleados/:id`} />
            <Route element={<EmployeesVacations />} path={`/vacaciones-empleados`} />
        </Route>
    </Routes>;
}

export default RoutesContainer;