import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'animate.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import { FeedBackProvider } from './contexts/FeedBackContext';
import "./css/fullcalendar/bootstrap/main.css";
import "./css/fullcalendar/core/main.css";
import "./css/fullcalendar/daygrid/main.css";
import "./css/fullcalendar/list/main.css";
import "./css/fullcalendar/timegrid/main.css";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <FeedBackProvider>
                    <App />
                </FeedBackProvider>
            </AuthProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
