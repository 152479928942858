import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";
import useRegions from "../../hooks/useRegions";
import AsyncSelect from 'react-select/async';
import mapValues from "../../utils/mapValues";
import handleLoadSelectOptions from "../../utils/loadSelectValues";

const TransferRequestsCreate = () => {

    const navigate = useNavigate();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [data, setData] = useState({
        modality: 'áerea',
        start: '',
        startHour: '',
        startFromRegion: '',
        startToRegion: '',
        end: '',
        endHour: '',
        endFromRegion: '',
        endToRegion: '',
        baggage: 'No',
        accommodation: 'No',
        accommodationStart: '',
        accommodationStartHour: '',
        accommodationEnd: '',
        accommodationEndHour: '',
        city: '',
        taxi: 'No',
        taxiStart: '',
        taxiStartHour: '',
        taxiEnd: '',
        taxiEndHour: '',
        taxiFromTo: '',
        reason: ''
    });

    const [{ regions, loading: regionsLoading }, getRegions] = useRegions({ options: { manual: true, useCache: false } });

    const [{ data: createData, loading: createLoading }, createRecord] = useAxios({ url: `/transfer-requests`, method: 'POST' }, { manual: true, useCache: false });

    const handleChange = (e) => {
        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.value
            }
        });
    }


    const handleSubmit = (e) => {
        e?.preventDefault?.();
    }

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">Solicitud de viaje</h4>
            </div>

            <div className="card-body">
                <div className="basic-form">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-5">
                            <div className="form-group mb-3 col-md-12">
                                <label>Vía del viaje</label>
                                <select className="form-control" name="modality" value={data?.modality} required onChange={handleChange}>
                                    <option value="áerea">Áerea</option>
                                    <option value="terrestre">terrestre</option>
                                </select>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <h3 className="text-center">ída</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Fecha de salida</label>
                                        <input
                                            type="date"
                                            name="start"
                                            onChange={handleChange}
                                            value={data.start}
                                            required
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Hora de salida</label>
                                        <input
                                            type="time"
                                            name="startHour"
                                            onChange={handleChange}
                                            value={data.startHour}
                                            required
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6 form-group">
                                        <label>¿Viaja desde?</label>
                                        <AsyncSelect
                                            onFocus={() => {
                                                getRegions();
                                            }}
                                            defaultOptions={mapValues(regions)}
                                            isLoading={regionsLoading}
                                            loadOptions={(e) => handleLoadSelectOptions(e, getRegions)}
                                            placeholder='buscar...'
                                            onChange={(e) => { handleChange({ target: { value: e?.value, name: 'startRegionFrom' } }) }}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>¿Hasta?</label>
                                        <AsyncSelect
                                            onFocus={() => {
                                                getRegions();
                                            }}
                                            defaultOptions={mapValues(regions)}
                                            isLoading={regionsLoading}
                                            loadOptions={(e) => handleLoadSelectOptions(e, getRegions)}
                                            placeholder='buscar...'
                                            onChange={(e) => { handleChange({ target: { value: e?.value, name: 'startRegionTo' } }) }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <h3 className="text-center">Vuelta</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Fecha de salida</label>
                                        <input
                                            type="date"
                                            name="end"
                                            onChange={handleChange}
                                            value={data.end}
                                            required
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Hora de salida</label>
                                        <input
                                            type="time"
                                            name="endHour"
                                            onChange={handleChange}
                                            value={data.endHour}
                                            required
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6 form-group">
                                        <label>¿Viaja desde?</label>
                                        <AsyncSelect
                                            onFocus={() => {
                                                getRegions();
                                            }}
                                            defaultOptions={mapValues(regions)}
                                            isLoading={regionsLoading}
                                            loadOptions={(e) => handleLoadSelectOptions(e, getRegions)}
                                            placeholder='buscar...'
                                            onChange={(e) => { handleChange({ target: { value: e?.value, name: 'endRegionFrom' } }) }}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>¿Hasta?</label>
                                        <AsyncSelect
                                            onFocus={() => {
                                                getRegions();
                                            }}
                                            defaultOptions={mapValues(regions)}
                                            isLoading={regionsLoading}
                                            loadOptions={(e) => handleLoadSelectOptions(e, getRegions)}
                                            placeholder='buscar...'
                                            onChange={(e) => { handleChange({ target: { value: e?.value, name: 'endRegionTo' } }) }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <p className="m-0">¿LLeva equipaje de bodega?</p>
                                <label className="mx-1">
                                    Si
                                    <input onChange={handleChange} type="radio" name="baggage" checked={data.baggage === 'Si'} value="Si" />
                                </label>
                                <label className="mx-1">
                                    No
                                    <input onChange={handleChange} type="radio" name="baggage" checked={data.baggage === 'No'} value="No" />
                                </label>
                            </div>
                            <div className="col-md-4">
                                <p className="m-0">¿Necesita alojamiento?</p>
                                <label className="mx-1">
                                    Si
                                    <input onChange={handleChange} type="radio" name="accommodation" checked={data.accommodation === 'Si'} value="Si" />
                                </label>
                                <label className="mx-1">
                                    No
                                    <input onChange={handleChange} type="radio" name="accommodation" checked={data.accommodation === 'No'} value="No" />
                                </label>
                            </div>
                            <div className="col-md-4">
                                <p className="m-0">¿Necesita servico de taxi?</p>
                                <label className="mx-1">
                                    Si
                                    <input onChange={handleChange} type="radio" name="taxi" checked={data.taxi === 'Si'} value="Si" />
                                </label>
                                <label className="mx-1">
                                    No
                                    <input onChange={handleChange} type="radio" name="taxi" checked={data.taxi === 'No'} value="No" />
                                </label>
                            </div>
                            {
                                data.accommodation === 'Si' &&
                                <div className="col-md-12 mt-4 animate__animated animate__bounce">
                                    <h3 className="text-center mb-3">
                                        Datos del alojamiento
                                    </h3>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label>Fecha de ingreso</label>
                                                    <input
                                                        type="date"
                                                        name="accommodationStart"
                                                        onChange={handleChange}
                                                        value={data.accommodationStart}
                                                        required
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Hora de ingreso</label>
                                                    <input
                                                        type="time"
                                                        name="accommodationStartHour"
                                                        onChange={handleChange}
                                                        value={data.accommodationStartHour}
                                                        required
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label>Fecha de salida</label>
                                                    <input
                                                        type="date"
                                                        name="accommodationEnd"
                                                        onChange={handleChange}
                                                        value={data.accommodationEnd}
                                                        required
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Hora de salida</label>
                                                    <input
                                                        type="time"
                                                        name="accommodationEndHour"
                                                        onChange={handleChange}
                                                        value={data.accommodationEndHour}
                                                        required
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <textarea
                                                name="city"
                                                value={data.city}
                                                className="form-control"
                                                onChange={handleChange}
                                                cols="30"
                                                rows="4"
                                                placeholder="¿Ciudad donde necesita el hospedaje...?"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                data.taxi === 'Si' &&
                                <div className="col-md-12 mt-4 animate__animated animate__bounce">
                                    <h3 className="text-center mb-3">
                                        Datos del Taxi
                                    </h3>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label>Fecha de ida</label>
                                                    <input
                                                        type="date"
                                                        name="taxiStart"
                                                        onChange={handleChange}
                                                        value={data.taxiStart}
                                                        required
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Hora de ida</label>
                                                    <input
                                                        type="time"
                                                        name="taxiStartHour"
                                                        onChange={handleChange}
                                                        value={data.taxiStartHour}
                                                        required
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label>Fecha de regreso</label>
                                                    <input
                                                        type="date"
                                                        name="taxiEnd"
                                                        onChange={handleChange}
                                                        value={data.taxiEnd}
                                                        required
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Hora de regreso</label>
                                                    <input
                                                        type="time"
                                                        name="taxiEndHour"
                                                        onChange={handleChange}
                                                        value={data.taxiEndHour}
                                                        required
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <textarea
                                                name="taxiFromTo"
                                                value={data.taxiFromTo}
                                                className="form-control"
                                                onChange={handleChange}
                                                cols="30"
                                                rows="4"
                                                placeholder="¿Tramos?"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="col-md-12 mt-4 animate__animated animate__bounce">
                                <h3 className="text-center mb-3">
                                    Motivo del viaje
                                </h3>
                                <div className="col-md-12 mt-3">
                                    <textarea
                                        name="reason"
                                        value={data.reason}
                                        className="form-control"
                                        onChange={handleChange}
                                        cols="30"
                                        rows="4"
                                        required
                                        placeholder="describe el motivo del viaje..."
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mb-3 d-flex justify-content-end">
                            <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                Cancelar
                            </Link>
                            <button disabled={createLoading} type="submit" className="btn btn-primary mx-2">
                                {createLoading ? 'Cargando' : 'Crear'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default TransferRequestsCreate;