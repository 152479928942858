import { useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Button from "../../components/Forms/Button";
import SystemInfo from "../../utils/SystemInfo";

const { api: apiUrl } = SystemInfo;

const TemporaryCodeButton = ({ secondsBeforeReuse = 30 }) => {
    const timeoutMiliseconds = 30 * 1000;

    const [disabled, setDisabled] = useState(false);

    const [remainingTime, setRemainingTime] = useState(0);

    const intervalRef = useRef(null);

    const handleClick = () => {
        setDisabled(true);

        setRemainingTime(secondsBeforeReuse);

        intervalRef.current = setInterval(() => {
            setRemainingTime(prevTime => prevTime - 1);
        }, 1000);

        setTimeout(() => {
            setDisabled(false);

            clearInterval(intervalRef.current);
        }, timeoutMiliseconds);

        axios.post(`${apiUrl}temporary-codes`)
            .then(() => toast.success('Código enviado!'))
            .catch(() => toast.error('Ha ocurrido un error'));
    };

    const buttonContent = disabled
        ? `Por favor espera ${remainingTime} segundos para generar un código`
        : 'Enviar código';

    return (
        <Button
            disabled={disabled}
            color="warning"
            outline
            leftIconName="mdi mdi-qrcode"
            className="mr-2"
            onClick={handleClick}
        >
            {buttonContent}
        </Button>
    )
}

export default TemporaryCodeButton;