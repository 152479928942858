import clsx from "clsx";

const ContactCard = ({title, subtitle, icon, onDeleteClick, onClick}) => {
    const handleLinkClick = (e) => {
        e.preventDefault();
        onDeleteClick?.();
    }
    
    return <div className={clsx('card profile-card', onClick && 'cursor-pointer')} onClick={onClick}>
        <div className="card-body p-0">
            <div className="media p-3  align-items-center">
                {icon && icon}
                                        
                <div className="media-body ml-3 align-self-center">
                    <h5 className="pro-title">{title}</h5>
                    <p className="mb-2 text-muted">{subtitle}</p>
                </div>
                <div className="action-btn">
                    {onDeleteClick && <a href="/#" onClick={handleLinkClick}><i className="fas fa-trash-alt text-danger"></i></a>}
                </div>                                         
            </div>                                    
        </div>
    </div>;
}

export default ContactCard;