import DateFormatter from '../components/DateFormatter';
import ActionsDropdown from '../components/ActionsDropdown';
import ImageAndName from '../components/ImageAndName';

const userColumns = ({ onDelete }) => [
    {
        headerName: 'ID',
        field: 'id'
    },
    {
        headerName: 'Nombre',
        component: ({ item }) => <ImageAndName source={item.imagePath} name={item.name} />
    },
    {
        headerName: 'Nro de documento',
        field: 'documentNumber'
    },
    {
        headerName: 'Email',
        field: 'email'
    },
    {
        headerName: 'Cargo',
        field: 'position.name',
        defaultValue: '---'
    },
    {
        headerName: 'Fecha de creación',
        component: ({ item }) => <DateFormatter date={item.createdAt} />
    },
    {
        headerName: 'Acciones',
        component: ({ item }) => <ActionsDropdown
            updatePath={`/usuarios/${item.id}`}
            onDelete={onDelete}
            dataForOnDelete={item.id}
            deleteAlertTitle="Eliminar usuario"
            deleteAlertConfirmBtnText="Si, ¡Eliminarlo!"
        />
    }
]

export default userColumns;

