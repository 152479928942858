import DateFormatter from '../components/DateFormatter';
import ActionsDropdown from '../components/ActionsDropdown';

const contractTypesColumns = ({ onDelete }) => [
    {
        headerName: 'ID',
        field: 'id'
    },
    {
        headerName: 'Nombre',
        component: ({ item }) => item?.name || '--'
    },
    {
        headerName: 'Fecha de creación',
        component: ({ item }) => <DateFormatter date={item.createdAt} />
    },
    {
        headerName: 'Acciones',
        component: ({ item }) => <ActionsDropdown
            updatePath={`/tipo-de-contratos/${item.id}`}
            onDelete={onDelete}
            dataForOnDelete={item.id}
            deleteAlertTitle="Eliminar tipo de contrato"
            deleteAlertConfirmBtnText="Si, ¡Eliminarlo!"
        />
    },
]

export default contractTypesColumns;

