import DateFormatter from '../components/DateFormatter';
import ActionsDropdown from '../components/ActionsDropdown';

const costCentersColumns = ({ onDelete }) => [
    {
        headerName: 'ID',
        field: 'id'
    },
    {
        headerName: 'Nombre',
        component: ({ item }) => item?.name || '--'
    },
    {
        headerName: 'Codigo',
        component: ({ item }) => item?.code || '--'
    },
    {
        headerName: 'Jefe',
        component: ({ item }) => item?.boss?.name || '--'
    },
    {
        headerName: 'Comuna',
        component: ({ item }) => item?.commune?.name || '--'
    },
    {
        headerName: 'Tipo',
        component: ({ item }) => item?.type === 'service' ? 'Servicio' : item?.type === 'cost_center' ? 'Centro de costo' : '--'
    },
    {
        headerName: 'Fecha de creación',
        component: ({ item }) => <DateFormatter date={item.createdAt} />
    },
    {
        headerName: 'Acciones',
        component: ({ item }) => <ActionsDropdown
            updatePath={`/centro-de-costos/${item.id}`}
            onDelete={onDelete}
            dataForOnDelete={item.id}
            deleteAlertTitle="Eliminar Centro de costo"
            deleteAlertConfirmBtnText="Si, ¡Eliminarlo!"
        />
    },
]

export default costCentersColumns;

