import { useEffect, useState } from 'react';
import useAxios from './useAxios';

const useProfileVacations = ({ options, axiosConfig } = {}) => {
    const [{ data, error, loading }, getVacations] = useAxios({ url: 'my-account/vacations', ...axiosConfig }, options);

    const [vacations, setVacations] = useState([]);

    const [total, setTotal] = useState(0);

    const [size, setSize] = useState(0);

    const [numberOfPages, setNumberOfPages] = useState(0);

    useEffect(() => {
        if (data) {
            setVacations(data.data);
            setTotal(data?.meta?.total);
            setSize(data?.meta?.per_page);
            setNumberOfPages(data.meta?.last_page);
        }

    }, [data, loading, error]);

    return [{ vacations, total, numberOfPages, size, error, loading }, getVacations];
};

export default useProfileVacations;
