import { useEffect } from "react";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import Card from "../../components/Card";
import { useAuth } from "../../contexts/AuthContext";
import useAxios from "../../hooks/useAxios";
import SystemInfo from "../../utils/SystemInfo";

const Verify = () => {
    const { pathname, search } = useLocation();

    const { user } = useAuth();

    const { id, hash } = useParams();

    const [searchParams] = useSearchParams();

    let verificationUrl = `${SystemInfo.api}email/verify/${id}/${hash}?`;

    for (const [key, value] of searchParams.entries()) {
        verificationUrl = `${verificationUrl}${key}=${value}&`;
    }

    const [{ data: verificationData, error: verificationError, loading: verificationLoading }, verifyEmail] = useAxios({
        url: verificationUrl.substring(0, verificationUrl.length - 1)
    }, { manual: true });

    useEffect(() => {
        if (user) {
            verifyEmail();
        }
    }, [user]);

    return <div className="container py-3">
        <div className="text-center"><img src={logo} alt="CSI" /></div>

        <Card
            className={'mt-3'}
            title={"Verificación de correo electrónico"}
            loading={verificationLoading}
            loadingText="Verificando..."
        >
            {!user
                ? <div className="text-center">Debes inicar sesión para verificar tu correo electrónico. <Link to="/login" className="text-info" state={{
                    from: `${pathname}${search}`
                }}>Inicar sesión</Link></div>
                : <>
                    {verificationError && <div className="text-center">No se pudo verificar el correo electrónico</div>}
                    {verificationData && <div className="text-center">El correo ha sido verificado con éxito. <Link to="/perfil" className="text-info">Ir a Intranet</Link></div>}
                </>
            }
        </Card>
    </div>;
}

export default Verify;