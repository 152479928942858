import takeSpot from "../assets/img/take.png";
import rindeGastos from "../assets/img/rinde-gastos.png";
import payroll from "../assets/img/payroll.png";
import copec from "../assets/img/copec.png";
import sigmo from "../assets/img/sigmo.jpg";
import calendario from "../assets/img/calendario.webp";
import cajandes from "../assets/img/los-andes.jpg";
import monday from "../assets/img/monday.png";
import prontoform from "../assets/img/prontoform.jpg";
import achs from "../assets/img/achs.jpg";
import minsal from "../assets/img/minsal.png";

const externalLinks = {
    admin: [
        {
            name: 'Take a Spot',
            image: takeSpot,
            url: 'https://app.takeaspot.net/login'
        },
        {
            name: 'Rinde gastos',
            image: rindeGastos,
            url: 'https://auth.rindegastos.com/signin'
        },
        {
            name: 'Reservas Copec',
            image: copec,
            url: 'https://reservascopec.mykalena.es/Kalena/Login'
        },
        {
            name: 'Monday',
            image: monday,
            url: 'https://csi-ltda.monday.com/'
        },
    ],
    person: [
        {
            name: 'Payroll',
            image: payroll,
            url: 'https://web.payroll.cl/PR5/inicio/ini/Login.aspx?ReturnUrl=%2fPR5&AspxAutoDetectCookieSupport=1'
        },
        {
            name: 'Calendario de Google',
            image: calendario,
            url: 'https://calendar.google.com/calendar/u/1/r/week'
        },
        {
            name: 'Caja los Andes',
            image: cajandes,
            url: 'https://www.cajalosandes.cl/'
        },
    ],
    operation: [
        {
            name: 'Sigmo',
            image: sigmo,
            url: 'https://sigmoqa.copec.cl/sigmo/'
        },
        {
            name: 'ProntoForms',
            image: prontoform,
            url: 'https://es.prontoforms.com/'
        },
    ],
    ssma: [
        {
            name: 'ACHS',
            image: achs,
            url: 'https://www.achs.cl/'
        },
        {
            name: 'Minsal',
            image: minsal,
            url: 'https://www.minsal.cl/'
        },
    ],
    configuration: [

    ]
}

export default externalLinks;