import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ModuleCard from "../components/ModuleCard";
import MenuLinks from "../utils/MenuLinks";

const AdminModulesList = () => {
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const [modules, setModules] = useState(null);

    useEffect(() => {
        const linkName = searchParams?.get('linkName');
        if (linkName) {
            setModules(MenuLinks[linkName]);
        }
    }, [searchParams])

    const handleClick = (module) => {
        navigate(module?.links?.[0]?.url);
    }

    return (
        <div>
            <h3>Administrar - {modules?.name}</h3>
            {
                modules?.adminLinks?.length > 0 ?
                    <div className="row mt-5">
                        {
                            modules?.adminLinks?.map((managementLink, i) => {
                                return (
                                    <ModuleCard
                                        key={i}
                                        title={managementLink.moduleName}
                                        icon={managementLink?.icon}
                                        onClick={() => handleClick(managementLink)}
                                        permissions={managementLink?.permissions}
                                        menuLinks={managementLink?.links}
                                    />
                                )
                            })
                        }
                    </div>
                    :
                    <div className="text-center">
                        <h1 className="text-danger">
                            No hay módulos.
                        </h1>
                    </div>
            }
        </div>
    )
}

export default AdminModulesList;