import FileGridCard from "./FileGridCard";

const FilesGridView = ({ files, onDeleteFile, onClickFolder }) => {

    return (
        <div className="row my-5">
            {
                files?.map?.((file, i) => {
                    return (
                        <FileGridCard
                            file={file}
                            key={i}
                            onDelete={() => onDeleteFile?.(i)}
                            onClickFolder={onClickFolder}
                        />
                    )
                })
            }
        </div>
    )
}

export default FilesGridView;