import clsx from "clsx";

const Card = ({
    className,
    children,
    title,
    loading = false,
    loadingText = 'Cargando...',
}) => {
    return <div className={clsx('card', className)}>
        {title && <div className="card-header">
            <h4 className="card-title">{title}</h4>
        </div>}
        <div className="card-body text-dark">
            {loading
                ? <div className="text-center py-2">
                    <div className="spinner-border thumb-xl text-primary" role="status" style={{ width: 120, height: 120 }}></div>
                    <h4 className="mt-5">{loadingText}</h4>
                </div>
                : children
            }
        </div>
    </div>;
}

export default Card;