import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";
import usePermissions from "../../hooks/usePermissions";
import useSystems from "../../hooks/useSystems";

const RolesUpdate = () => {

    const { id } = useParams();

    const navigate = useNavigate();

    const { setCustomAlert } = useFeedBack();

    const [data, setData] = useState({
        name: '',
        systemCode: '',
        permissions: []
    });

    const [{ data: getData, loading: getLoading }, getRecord] = useAxios({ url: `/roles/${id}` }, { useCache: false });

    const [{ data: updateData, loading: updateLoading }, updateRecord] = useAxios({ url: `/roles/${id}`, method: 'PUT' }, { manual: true, useCache: false });

    const [{ data: permissionsByModule, loading: permissionsLoading }, getPermissionsByModule] = useAxios({ url: `/permissions/by-module` }, { manual: true, useCache: false });

    const [{ systems, total, numberOfPages, size, error, loading }, getSystems] = useSystems();

    useEffect(() => {
        if (getData) {
            if (!getData.data.canBeUpdated) {
                navigate('/roles');
            }
            
            const permissions = [];
            getData?.data?.permissionsByModule?.forEach(module => {
                module?.permissions?.forEach(permission => {
                    permissions?.push(permission?.name);
                })
            });
            setData((oldData) => {
                return {
                    ...oldData,
                    name: getData?.data?.displayText,
                    systemCode: getData?.data?.systemCode,
                    permissions: permissions
                }
            });
        }
    }, [getData]);

    useEffect(() => {
        if (data?.systemCode) {
            getPermissionsByModule({
                params: {
                    systemCode: data?.systemCode,
                    perPage: 200,
                    page: 1
                }
            });
        }
    }, [data?.systemCode])

    useEffect(() => {
        if (updateData) {
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'El registro fue creado exitosamente.',
                show: true
            });
            navigate('/roles');
        }
    }, [updateData]);

    const handleSubmit = (e) => {
        e?.preventDefault?.();

        if (updateLoading) {
            return;
        }

        updateRecord({ data });
    }

    const handleChange = (e) => {

        if (e.target.name === 'systemCode') {
            setData((oldData) => {
                return {
                    ...oldData,
                    permissions: []
                }
            });
        }

        if (e.target.type === 'checkbox') {
            const value = data[e.target.name]?.includes(e.target.value);
            if (value) {
                const newValues = data[e.target.name]?.filter(n => n !== e.target.value);
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: newValues
                    }
                });
            } else {
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: [...data[e.target.name], e.target.value]
                    }
                });
            }
            return;
        }


        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Editar Rol</h4>
                </div>
                <div className="card-body">
                    <div className="basic-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-5">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Nombre</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Nombre"
                                        name="name"
                                        value={data?.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Sistema</label>
                                    <select
                                        name="systemCode"
                                        className="form-control"
                                        style={{ textTransform: 'capitalize' }}
                                        value={data?.systemCode}
                                        onChange={handleChange}
                                    >
                                        <option value="" disabled>Seleccione...</option>
                                        {
                                            systems?.map((system, i) => {
                                                return (
                                                    <option style={{ textTransform: 'capitalize' }} key={i} value={system?.code}>{system?.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group mb-3 col-md-12">
                                    <h3>Permisos</h3>
                                    {
                                        data?.systemCode ?
                                            permissionsLoading ?
                                                <p className="text-center" style={{ fontSize: '18px' }}>Cargando permisos</p>
                                                :
                                                permissionsByModule ?
                                                    <div className="row">
                                                        {
                                                            Object?.keys(permissionsByModule).map?.((moduleName, i) => {
                                                                return (
                                                                    <div className="col-md-3 my-2" key={i}>
                                                                        <b title={moduleName}>{moduleName?.length > 18 ? `${moduleName?.slice(0, 18)}...` : moduleName}</b>
                                                                        <div>
                                                                            {permissionsByModule[moduleName]?.map((permission, i) => {
                                                                                return (
                                                                                    <div key={i}>
                                                                                        <input
                                                                                            onChange={handleChange}
                                                                                            name="permissions"
                                                                                            checked={data?.permissions?.includes(permission?.name)}
                                                                                            type="checkbox"
                                                                                            value={permission?.name}
                                                                                            id={permission?.name}
                                                                                        />
                                                                                        <label htmlFor={permission?.name}>{permission?.display_text}</label>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    <p className="text-center" style={{ fontSize: '18px' }}>No se Encontraron permisos</p>
                                            :
                                            <p className="text-center" style={{ fontSize: '18px' }}>Seleccione un sistema</p>
                                    }
                                </div>
                            </div>
                            <div className="mb-3 d-flex justify-content-end">
                                <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                    Cancelar
                                </Link>
                                <button disabled={updateLoading} type="submit" className="btn btn-primary mx-2">
                                    {updateLoading ? 'Cargando' : 'Actualizar'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    )
}
export default RolesUpdate;