import DateFormatter from '../components/DateFormatter';
import ActionsDropdown from '../components/ActionsDropdown';
import ImageAndName from '../components/ImageAndName';

const employeesColumns = ({ onDelete, handleAction }) => [
    {
        headerName: 'ID',
        field: 'id'
    },
    {
        headerName: 'Nombre',
        component: ({ item }) => <ImageAndName source={item.imagePath} name={item.name} />
    },
    {
        headerName: 'Nro de documento',
        field: 'documentNumber'
    },
    {
        headerName: 'Email',
        field: 'email'
    },
    {
        headerName: 'Cargo',
        field: 'position.name',
        defaultValue: '---'
    },
    {
        headerName: 'Estado',
        field: 'status',
        defaultValue: '---'
    },
    {
        headerName: 'Fecha de creación',
        component: ({ item }) => <DateFormatter date={item.createdAt} />
    },
    {
        headerName: 'Acciones',
        component: ({ item }) => <ActionsDropdown
            updateTextButton='Ver detalles'
            updatePath={`/mis-empleados/${item.id}`}
            showDelete={false}
        />
    },

]

export default employeesColumns;

