import clsx from "clsx";

const Button = ({
    type = 'button',
    color = '',
    size = 'md',
    outline = false,
    round = false,
    leftIconName,
    rightIconName,
    children,
    className,
    ...props
}) => {
    return (
        <button
            type={type}
            className={clsx(
                className,
                `btn btn-${size}`,
                `btn-${outline ? 'outline-' : ''}${color}`,
                round && 'btn-round',
            )}
            {...props}
        >
            {leftIconName && <i className={clsx(leftIconName, 'mr-2')}></i>}
            {children}
            {rightIconName && <i className={clsx(rightIconName, 'ml-2')}></i>}
        </button >
    )
}

export default Button;