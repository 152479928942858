import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import UserHavePermission from "../utils/UserHavePermission";

const RequireAuth = ({ element, screenPermission }) => {

    const { isAuthenticated, permissions } = useAuth();
    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!UserHavePermission(screenPermission)) {
        return 'No Tienes permisos.'
    }

    return element;
}

export default RequireAuth;