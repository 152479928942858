import { createContext, useContext, useEffect, useState } from "react";
import { deleteAuth, getAuth, setAuth } from "../utils/auth";

const AuthContext = createContext({ user: null, token: null });

const initialData = () => ({ user: null, token: null, webDoxc: null });

const lsItem = getAuth();
const defaultData = lsItem ? { ...JSON.parse(lsItem) } : initialData();

export const AuthProvider = ({ children }) => {
    const [authInfo, setAuthInfo] = useState(defaultData);

    useEffect(() => {
        if (authInfo) {
            setAuth(JSON.stringify(authInfo));
        } else {
            deleteAuth();
        }
    }, [authInfo]);

    const getPermissionName = () => {
        return authInfo?.user?.role?.permissionsByModule?.reduce((result, module) => [...result, ...module.permissions.map(p => p.name)], [])
    }

    const resetAuthInfo = () => {
        setAuthInfo(initialData());
    }

    return <AuthContext.Provider value={{
        user: authInfo.user,
        token: authInfo.token,
        webDoxc: authInfo.webDoxc,
        permissions: getPermissionName(),
        isAuthenticated: !!authInfo.user,
        setAuthInfo,
        resetAuthInfo,
    }}>
        {children}
    </AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);