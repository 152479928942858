import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";
import AsyncSelect from 'react-select/async';
import useCommunes from "../../hooks/useCommunes";
import handleLoadSelectOptions from "../../utils/loadSelectValues";
import mapValues from "../../utils/mapValues";
import DateFormatter from "../../components/DateFormatter";

const HouseReservationsUpdate = () => {

    const { id } = useParams();

    const navigate = useNavigate();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [{ data: record, loading: recordLoading }] = useAxios({ url: `/house-reservations/${id}` }, { useCache: false });

    const [{ data: updateData, loading: updateLoading }, updateRecord] = useAxios({ url: `/house-reservations/${id}`, method: 'PUT' }, { manual: true, useCache: false });

    const [{ communes, total, numberOfPages, size, error, loading: communesLoading }, getCommunes] = useCommunes({
        options: {
            useCache: false
        }
    });

    const [data, setData] = useState({
        alcohol: false,
        cigars: false,
        contactText: "",
        createdAt: '',
        emergencyPhoneNumber: '',
        end: '',
        endHour: '',
        guestName: '',
        guestRut: '',
        house: '',
        personEmergencyName: '',
        start: '',
        startHour: '',
        valueThings: false,
    });

    useEffect(() => {
        setLoading({
            show: recordLoading,
            message: 'Obteniendo datos',
        });
    }, [recordLoading]);

    useEffect(() => {
        setLoading({
            show: updateLoading,
            message: 'Actualizando registro',
        });
    }, [updateLoading]);

    useEffect(() => {
        if (updateData) {
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'El registro fue actualizado exitosamente.',
                show: true
            });
            navigate('/casas-csi');
        }
    }, [updateData]);

    useEffect(() => {
        if (record) {
            setData(prevData => ({
                ...prevData,
                ...record?.data
            }));
        }
    }, [record]);

    const handleChange = (e) => {
        setData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        updateRecord({
            data: {
                name: data?.name,
                commune_id: data?.commune?.value
            }
        });
    }

    return <>
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">Detalles de Reserva: #{id}</h4>
            </div>

            <div className="card-body">
                <div className="basic-form">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-3">
                            <div className="col-md-12 text-center">
                                <h3>Datos del huesped</h3>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Nombre</label>
                                <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    placeholder="Nombre"
                                    value={data?.guestName || '--'}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Rut</label>
                                <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    placeholder="Nombre"
                                    value={data?.guestRut || '--'}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12 text-center">
                                <h3>Datos de la reserva</h3>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Casa de hospedaje</label>
                                <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    placeholder="Entrada"
                                    value={`${data?.house?.commune?.region?.name ? `${data?.house?.commune?.region?.name}` : ''} ${data?.house?.commune?.name ? `- ${data?.house?.commune?.name}` : ''} ${data?.house?.name ? `- ${data?.house?.name}` : ''}`}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Servicio</label>
                                <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    placeholder="Nombre"
                                    value={data?.service?.name || '--'}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label>Entrada</label>
                                <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    placeholder="Entrada"
                                    value={`${DateFormatter({ date: data?.start })} ${data?.startHour}`}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label>Salida</label>
                                <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    placeholder="Salida"
                                    value={`${DateFormatter({ date: data?.end })} ${data?.endHour}`}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label>Dias</label>
                                <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    placeholder="Dias"
                                    value={`${data?.days}`}
                                />
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-md-12 text-center">
                                <h3>Información Adicional</h3>
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label>¿Lleva Cigarros?</label>
                                <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    placeholder="Sigarros"
                                    value={data?.cigars ? 'Si' : 'No'}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label>¿Lleva Alcohol?</label>
                                <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    placeholder="Alcohol"
                                    value={data?.alcohol ? 'Si' : 'No'}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label>¿LLeva Cosas de valor?</label>
                                <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    placeholder="Cosas de valor"
                                    value={data?.valueThings ? 'Si' : 'No'}
                                />
                            </div>
                            <div className="col-md-12">
                                <textarea disabled cols="30" rows="5" placeholder="información adicional" value={data?.aditionalInformation} className="form-control"></textarea>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-12 text-center">
                                <h3>Llamar en caso de emergencias a:</h3>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Nombre</label>
                                <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    placeholder="Entrada"
                                    value={`${data?.personEmergencyName || '--'}`}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Telefono</label>
                                <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    placeholder="Entrada"
                                    value={`${data?.emergencyPhoneNumber || '--'}`}
                                />
                            </div>
                        </div>

                        <div className="mb-3 d-flex justify-content-end">
                            <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                Volver
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}

export default HouseReservationsUpdate;