import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";

const OfficesUpdate = () => {

    const { id } = useParams();

    const navigate = useNavigate();

    const { setCustomAlert, setLoading } = useFeedBack();

    const filters = {
        perPage: 200,
        page: 1
    };

    const [firstLoading, setFirstLoading] = useState(true);

    const [data, setData] = useState({
        name: ''
    });

    const [{ data: dataToUpdate, error: dataToUpdateError, loading: dataToUpdateLoading }] = useAxios({ url: `/offices/${id}` }, { useCache: false });

    const [{ data: updateData, loading: updateLoading, error: updateError }, updateRecord] = useAxios({ url: `/offices/${id}`, method: 'PUT' }, { manual: true, useCache: false });

    useEffect(() => {
        if (dataToUpdate) {
            console.log(dataToUpdate);
            setData((oldData) => {
                return {
                    ...oldData,
                    name: dataToUpdate?.data?.name
                }
            });
        }
    }, [dataToUpdate]);

    useEffect(() => {
        if (!dataToUpdateLoading) {
            setFirstLoading(false);
        } else {
            setFirstLoading(true)
        }
    }, [dataToUpdateLoading]);

    useEffect(() => {
        setLoading({
            show: firstLoading,
            message: 'Obteniendo informacion'
        });
    }, [firstLoading]);

    useEffect(() => {
        if (updateData) {
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'El registro fue actualizado exitosamente.',
                show: true
            });
            navigate('/oficinas');
        }
    }, [updateData])

    useEffect(() => {
        if (updateError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error.',
                show: true
            });
        }

        if (dataToUpdateError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los datos.',
                show: true
            });
        }
    }, [updateError])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (updateLoading) {
            return;
        }

        updateRecord({ data });
    }

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            const value = data[e.target.name]?.includes(e.target.value);
            if (value) {
                const newValues = data[e.target.name]?.filter(n => n !== e.target.value);
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: newValues
                    }
                });
            } else {
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: [...data[e.target.name], e.target.value]
                    }
                });
            }
            return;
        }


        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Actualizar Oficina</h4>
                </div>
                <div className="card-body">
                    <div className="basic-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-5">
                                <div className="form-group mb-3 col-md-12">
                                    <label>Nombre</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Nombre"
                                        name="name"
                                        value={data?.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 d-flex justify-content-end">
                                <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                    Cancelar
                                </Link>
                                <button disabled={updateLoading} type="submit" className="btn btn-primary mx-2">
                                    {
                                        updateLoading ?
                                            'Cargando'
                                            :
                                            'Actualizar'
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default OfficesUpdate;