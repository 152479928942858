import { useEffect, useState } from "react";
import ContactCard from "../components/ContactCard";
import { useFeedBack } from "../contexts/FeedBackContext";
import useAxios from "../hooks/useAxios";
import useSystems from "../hooks/useSystems";
import { confirm } from "../utils/swal";
import systemIcons from "../utils/systemIcons";

const Dashboard = () => {
    const { setLoading } = useFeedBack();

    const [siteUrl, setSiteUrl] = useState('');

    const [{ systems }] = useSystems({ options: { useCache: false } });
    const [{ data: loginData, loading: loginLoading }, login] = useAxios({ method: 'POST' }, { useCache: false, manual: true });

    useEffect(() => {
        setLoading({
            show: loginLoading,
            message: 'Ingresando',
        });
    }, [loginLoading]);

    useEffect(() => {
        if (loginData && siteUrl) {
            window.open(`${siteUrl}?token=${window.encodeURIComponent(loginData.token)}`, '_blank');
        }
    }, [loginData, siteUrl]);

    const handleSystemClick = ({ code, siteUrl, name }) => async () => {
        const res = await confirm({
            title: 'Iniciar sesión',
            text: `¿Iniciar sesión en ${name}?`,
            confirmButtonText: 'Si, ingresar',
        });

        if (res.value) {
            setSiteUrl(siteUrl);
            await login({ url: '/auth/generate-token', data: { systemCode: code } });
        }
    }

    return <div className="row">
        {systems.map(system => <div key={system.code} className="col-md-4">
            <ContactCard
                title={system.name}
                subtitle={`${system.usersCount} usuarios`}
                icon={systemIcons[system.code]}
                onClick={system.siteUrl ? handleSystemClick(system) : null}
            />
        </div>)}
    </div>;
}

export default Dashboard;