import clsx from "clsx";
import { Link } from "react-router-dom";
import UserHavePermission from "../utils/UserHavePermission";

const ModuleCard = ({ title, subtitle, icon, onDeleteClick, onClick, permissions, menuLinks }) => {

    const handleLinkClick = (e) => {
        e.preventDefault();
        onDeleteClick?.();
    }

    if (!UserHavePermission(permissions)) {
        return null;
    }

    return <div className="col-md-4">
        <div className={clsx('card profile-card', menuLinks?.length > 0 ? null : 'cursor-pointer')} onClick={menuLinks?.length > 0 ? null : onClick}>
            <div className="card-body p-0">
                <div className="media p-3  align-items-center">
                    {
                        icon &&
                        <i className={icon} style={{ fontSize: 60 }}></i>
                    }

                    <div className="media-body ml-3 align-self-center">
                        {
                            title &&
                            <h5 className="pro-title">{title}</h5>
                        }
                        <div className="row">
                            {
                                menuLinks?.map((link, i) => {
                                    return (
                                        UserHavePermission(link?.permission) ?
                                            <Link key={i} to={link?.url} className="btn btn-sm text-white btn-primary mb-2 mx-2">{link?.name}</Link>
                                            :
                                            null
                                    )
                                })

                            }
                        </div>
                    </div>
                    <div className="action-btn">
                        {onDeleteClick && <a href="/#" onClick={handleLinkClick}><i className="fas fa-trash-alt text-danger"></i></a>}
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default ModuleCard;