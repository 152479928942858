import { format } from "date-fns";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";
import userImg from '../../assets/img/user.png';
import webDocImg from '../../assets/icons/webdocs-logo.png';
import SystemInfo from "../../utils/SystemInfo";
import useAxiosWebDoxcs from "../../hooks/useAxiosWebDoxcs";
import env from "../../utils/env";
import WebDocxDocumentList from "../../components/webdocxs/WebDocxDocumentList";
import DateFormatter from "../../components/DateFormatter";

const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
};

const VacationsDetail = () => {

    const { id } = useParams();

    const { permissions, setAuthInfo } = useAuth();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [showDocuments, setShowDocuments] = useState(false);

    const [reason, setReason] = useState('');

    const [{ data, loading }] = useAxios({ url: `/vacations/${id}` }, { useCache: false });

    const [{ }, createVacationsPdf] = useAxios({ url: `/vacations/${id}/pdf`, responseType: 'blob' }, { useCache: false, manual: true });

    const [{ }, saveWorkFlowId] = useAxios({ url: `/vacations/${id}/workflow`, method: 'PUT' }, { useCache: false, manual: true });

    const [{ }, authWebdoxcs] = useAxiosWebDoxcs({ url: '/oauth/token', method: 'POST' }, { useCache: false, manual: true });

    const [{ }, createWebDocxsWorkflow] = useAxiosWebDoxcs({ url: '/decision_workflows', method: 'POST' }, { useCache: false, manual: true });

    const [{ }, addWebDocxsDocument] = useAxiosWebDoxcs({ method: 'POST' }, { useCache: false, manual: true });

    const [showReasonModal, setShowReasonModal] = useState(false);

    const [currentVacation, setCurrentVacation] = useState({});

    const [{ data: changeVacationStatusData, loading: changeVacationStatusLoading }, changeVacationStatus] = useAxios({ url: `vacations/boss/change-status`, method: 'put' }, { manual: true, useCache: false });

    useEffect(() => {
        setLoading({
            show: loading,
            message: 'Cargando'
        })
    }, [loading])

    useEffect(() => {
        if (changeVacationStatusData) {
            setCurrentVacation((oldVacation) => {
                return {
                    ...oldVacation,
                    ...changeVacationStatusData?.data
                }
            });

            if (changeVacationStatusData?.data?.status?.id === 3) {
                createDocumentOnWebdoxcs();
            }

            setReason('');
            setCustomAlert({
                title: 'Enhorabuena',
                severity: 'success',
                message: 'El registro se ha actualizado exitosamente.',
                show: true
            });
        }
    }, [changeVacationStatusData])

    useEffect(() => {
        if (data) {
            setCurrentVacation((oldVacation) => {
                return {
                    ...oldVacation,
                    ...data?.data
                }
            });
        }
    }, [data]);

    const changeStatus = (statusId, reason) => {

        const endUrl = permissions?.includes?.('sys-001-change-status-vacations') ? 'vacations/change-status' : 'vacations/boss/change-status';

        changeVacationStatus({
            url: endUrl,
            data: {
                vacationId: id,
                statusId,
                reason: reason ? reason : null
            }
        });
    }

    const handleSubmit = (e) => {
        e?.preventDefault?.();
        if (!reason) {
            alert('El motivo no puede estar vacío.');
            return;
        }
        setShowReasonModal(false);
        changeStatus(1, reason);
    }

    const createDocumentOnWebdoxcs = async () => {

        setLoading({
            show: true,
            message: 'Por favor espere'
        });

        try {

            const authWebdocxResponse = await authWebdoxcs({
                data: {
                    username: env('WEBDOCX_USER'),
                    password: env('WEBDOCX_PASSWORD'),
                    grant_type: "password"
                }
            });

            const accessToken = authWebdocxResponse?.data?.access_token;

            const createWFResponse = await createWebDocxsWorkflow({
                headers: {
                    ...headers,
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    decision_name: `Vacaciones #${changeVacationStatusData?.data?.id} - ${changeVacationStatusData?.data?.user?.name} - ${changeVacationStatusData?.data?.user?.document_number}`,
                    decision_workflow_template_id: '3d207f58-c659-4224-a345-4679e0f5f06d'
                }
            });

            const workFlowData = createWFResponse?.data;

            const pdfResponse = await createVacationsPdf();

            var formData = new FormData();

            var blobFile = pdfResponse?.data;

            blobFile.lastModifiedDate = new Date();
            blobFile.name = `Vacaciones #${id} - ${currentVacation?.user?.name} - ${currentVacation?.user?.document_number}.pdf`;

            formData.append('origin', 'pc');
            formData.append('versioned_document', true);
            formData.append('attachment', blobFile, blobFile.name);

            const webDocxDocumentResponse = await addWebDocxsDocument({
                headers: {
                    ...headers,
                    Authorization: `Bearer ${accessToken}`
                },
                url: `/decision_workflows/${workFlowData?.id}/documents?number=1`,
                data: formData
            });

            if (currentVacation?.user?.cost_center?.name) {

                const addDocumentToClasification = await addWebDocxsDocument({
                    method: 'PUT',
                    headers: {
                        ...headers,
                        Authorization: `Bearer ${accessToken}`
                    },
                    url: `/documents/${webDocxDocumentResponse?.data?.document?.id}`,
                    data: {
                        document: {
                            [`subject_id`]: `c06213fc-c9f8-4eaf-a3bd-b8128ae2d983`,
                            [`dynamic_attribute_33319561-8d59-4848-bc2b-dfdc07f9d598`]: `${currentVacation?.user?.cost_center?.name}`
                        }
                    }
                });
            }

            const responseDocumentSigner = await addWebDocxsDocument({
                method: 'POST',
                headers: {
                    ...headers,
                    Authorization: `Bearer ${accessToken}`
                },
                url: `/decision_workflows/${workFlowData?.id}/set_signable_document`,
                data: {
                    document_id: webDocxDocumentResponse?.data?.document?.id,
                    number: 2
                }
            });

            const setSignerResponse = await addWebDocxsDocument({
                method: 'POST',
                headers: {
                    ...headers,
                    Authorization: `Bearer ${accessToken}`
                },
                url: `/decision_workflows/${workFlowData?.id}/steps/2/signers`,
                data: {
                    kind: 'external',
                    country_code: 'CHL',
                    name: currentVacation?.user?.name,
                    email: currentVacation?.user?.email,
                    national_identification_number: currentVacation?.user?.document_number
                }
            });

            if (currentVacation?.user?.boss) {
                const setBossSignerResponse = await addWebDocxsDocument({
                    headers: {
                        ...headers,
                        Authorization: `Bearer ${accessToken}`
                    },
                    method: 'POST',
                    url: `/decision_workflows/${workFlowData?.id}/steps/2/signers`,
                    data: {
                        kind: 'external',
                        country_code: 'CHL',
                        name: currentVacation?.user?.boss?.name,
                        email: currentVacation?.user?.boss?.email,
                        national_identification_number: currentVacation?.user?.boss?.document_number
                    }
                });
            }

            const confirmStepOneResponse = await addWebDocxsDocument({
                headers: {
                    ...headers,
                    Authorization: `Bearer ${accessToken}`
                },
                method: 'PUT',
                url: `/decision_workflows/${workFlowData?.id}/validate?number=1`,
                data: {
                    comment: 'Validado por la intranet.'
                }
            });

            const confirmStepTwoResponse = await addWebDocxsDocument({
                headers: {
                    ...headers,
                    Authorization: `Bearer ${accessToken}`
                },
                method: 'PUT',
                url: `/decision_workflows/${workFlowData?.id}/validate?number=2`,
                data: {
                    comment: 'Validado por la intranet.'
                }
            });

            const saveWFIdResponse = await saveWorkFlowId({
                data: {
                    workFlowId: workFlowData?.id
                }
            });

            setCurrentVacation(saveWFIdResponse?.data?.data);

            setLoading({
                show: false,
                message: ''
            });

        } catch (error) {

            console.log(error);

            setLoading({
                show: false,
                message: ''
            });
        }

    }

    return (
        <div>
            <div className="text-right mb-4">
                {permissions?.includes?.('sys-001-view-vacations') &&
                    <a href="/vacaciones" className="btn btn-primary">
                        Volver al listado
                    </a>
                }
            </div>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-8">
                            <h4 className="card-title">Solicitud de vacaciones #{id}</h4>
                        </div>
                        <div className="col-md-4">
                            {
                                currentVacation?.workflowId &&
                                <button className="btn btn-secondary" onClick={() => setShowDocuments(true)}>
                                    Mostrar documentos
                                </button>
                            }
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 text-center">
                            <img
                                style={{ height: 120, width: 120 }}
                                src={currentVacation?.user?.image_path || userImg}
                                className="rounded"
                            />
                            <h5>{currentVacation?.user?.name}</h5>
                            <h5>Rut: {currentVacation?.user?.document_number}</h5>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">
                                        Desde:
                                    </label>
                                    <input
                                        readOnly
                                        type="text"
                                        placeholder="Desde..."
                                        className="form-control"
                                        value={currentVacation?.formattedStart ? DateFormatter({ date: `${currentVacation?.formattedStart} 23:59:59` }) : '--'}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">
                                        Hasta:
                                    </label>
                                    <input
                                        readOnly
                                        type="text"
                                        placeholder="Hasta..."
                                        className="form-control"
                                        value={currentVacation?.formattedEnd ? DateFormatter({ date: `${currentVacation?.formattedEnd} 23:59:59` }) : '--'}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">
                                        Días Hábiles:
                                    </label>
                                    <input
                                        readOnly
                                        type="text"
                                        placeholder="Días..."
                                        className="form-control"
                                        value={currentVacation?.days}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="">
                                        Retorno:
                                    </label>
                                    <input
                                        readOnly
                                        type="text"
                                        placeholder="Fecha de retorno"
                                        className="form-control"
                                        value={currentVacation?.return}
                                    />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label>
                                        Jefe
                                    </label>
                                    <input
                                        readOnly
                                        type="text"
                                        placeholder="Jefe..."
                                        className="form-control"
                                        value={currentVacation?.user?.boss?.name || '--'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center text-capitalize">
                        <h4 style={{ color: currentVacation?.status?.color }}>
                            {
                                currentVacation?.status?.name
                            }
                        </h4>
                    </div>
                    {
                        currentVacation?.status?.id === 2 &&
                        <div className="row">
                            <div className="col-md-6 mt-3 text-center">
                                <button disabled={changeVacationStatusLoading} onClick={() => setShowReasonModal(true)} className="btn btn-block btn-danger">
                                    {
                                        changeVacationStatusLoading ?
                                            <div className="spinner-border thumb-xl text-white" role="status" style={{ width: 20, height: 20 }}></div>
                                            :
                                            'Rechazar'
                                    }
                                </button>
                            </div>
                            <div className="col-md-6 mt-3 text-center">
                                <button disabled={changeVacationStatusLoading} onClick={() => changeStatus(3, null)} className="btn btn-block btn-success">
                                    {
                                        changeVacationStatusLoading ?
                                            <div className="spinner-border thumb-xl text-white" role="status" style={{ width: 20, height: 20 }}></div>
                                            :
                                            'Aceptar'
                                    }
                                </button>
                            </div>
                        </div>
                    }

                </div>
            </div>
            <Modal show={showDocuments && currentVacation?.workflowId}>
                <Modal.Header>
                    <Modal.Title>Documentos en webdocx</Modal.Title>
                    <Button
                        onClick={() => setShowDocuments(false)}
                        className="btn-danger"
                    >
                        <i className='ti-close'></i>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <WebDocxDocumentList
                        url={`/decision_workflows/${currentVacation?.workflowId}`}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setShowDocuments(false)}
                        className="btn-danger"
                        type="button"
                    >
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showReasonModal}>
                <Modal.Header>
                    <Modal.Title>Motivo por el cual la rechaza</Modal.Title>
                    <Button
                        onClick={() => setShowReasonModal(false)}
                        className="btn-danger"
                    >
                        <i className='ti-close'></i>
                    </Button>
                </Modal.Header>
                <form action="" onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <textarea
                                    className="form-control"
                                    cols="30"
                                    placeholder="Motivo del rechazo..."
                                    rows="4"
                                    onChange={(e) => setReason(e.target.value)}
                                >{reason}</textarea>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => setShowReasonModal(false)}
                            className="btn-danger"
                            type="button"
                        >
                            Cancelar
                        </Button>
                        <button className="btn btn-success">
                            Enviar
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    )
}
export default VacationsDetail;