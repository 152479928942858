import '../../css/spinners/spinnerDots.css';


const SpinnerDots = ({ show, color = 'rgb(150, 150, 150)' }) => {


    if (!show) return null;

    return (
        <div className="spinner">
            <div style={{ backgroundColor: color }} className="bounce1"></div>
            <div style={{ backgroundColor: color }} className="bounce2"></div>
            <div style={{ backgroundColor: color }} className="bounce3"></div>
        </div>
    )
}

export default SpinnerDots