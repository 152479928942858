import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../components/Table';
import { useAuth } from '../../contexts/AuthContext';
import { useFeedBack } from '../../contexts/FeedBackContext';
import useAxios from '../../hooks/useAxios';
import usePositions from '../../hooks/usePositions';
import positionColumns from '../../tableColumns/positionColumns';
import { mainPermissions } from '../../utils/MenuLinks';
import swal from '../../utils/swal';
import UserHavePermission from '../../utils/UserHavePermission';

const Positions = () => {

    const { permissions } = useAuth();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [filters, setFilters] = useState({
        page: 1,
        search: ''
    })

    const [{ positions, total, numberOfPages, loading: positionsLoading }, getPositions] = usePositions({ axiosConfig: { params: { ...filters } }, options: { useCache: false } });

    const [{ loading: deleteLoading }, deleteCharge] = useAxios({ method: 'DELETE' }, { manual: true, useCache: false });

    useEffect(() => {
        setLoading({
            show: deleteLoading,
            message: 'Eliminando',
        })
    }, [deleteLoading]);

    const handlePageChange = (page) => {
        if (page > 0) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    const handleDelete = async (id) => {
        await deleteCharge({ url: `/positions/${id}` });

        swal({
            icon: 'success',
            title: '¡Eliminado!',
            text: 'Cargo eliminado exitosamente!'
        });

        getPositions();
    }

    const handleDeleteSelected = async (selectedValues) => {
        await deleteCharge({ url: `/positions/multiple`, data: { ids: selectedValues } });

        swal({
            icon: 'success',
            title: '¡Eliminados!',
            text: '¡Cargos eliminado exitosamente!'
        });

        getPositions();
    }

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    return <>
        {UserHavePermission(mainPermissions?.positions[1]) && <div className="my-4 justify-content-end d-flex">
            <Link to={"/cargos/crear"} className="btn btn-primary">
                Crear cargo
            </Link>
        </div>}
        <Table
            title="Cargos"
            columns={positionColumns({ onDelete: handleDelete })}
            onSearchChange={(e) => handleChange({ target: { value: e?.target.value, name: 'search' } })}
            searchValue={filters?.search}
            excelUrl={'/positions/export/excel'}
            excelFileName='cargos.xlsx'
            items={positions}
            total={total}
            currentPage={filters.page}
            pages={numberOfPages}
            loading={positionsLoading}
            onChangePage={handlePageChange}
            onDeleteSelected={handleDeleteSelected}
            withSelection
            perPageValue={filters?.perPage}
            onChagePerPage={(e) => {
                setFilters((oldFilters) => {
                    return {
                        ...oldFilters,
                        perPage: e.target.value,
                        page: 1
                    }
                })
            }}
        />
    </>
}

export default Positions;