import { formatDistance } from "date-fns";
import { es } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import {
    MAX_VACATION_EMPLOYE,
    MAX_VACATION_USER,
    NEW_VACATION_EMPLOYE,
    VACATION_CHANGE_STATUS
} from "../../utils/NotificationsTypes";

const NotificationRow = ({ notification, onReadNotification }) => {

    const [currentNotification, setCurrentNotification] = useState(null);

    const [{ }, setReadNotification] = useAxios({ url: `my-account/notifications/${currentNotification?.id}/read`, method: 'put' }, { manual: true, useCache: false });

    useEffect(() => {
        if (notification) {
            setCurrentNotification(notification);
        }
    }, [notification])

    var notificationUrl = '#';

    switch (notification?.notification_type) {
        case NEW_VACATION_EMPLOYE:
            notificationUrl = `/vacaciones/${notification?.notificable_id}`
            break;
        case VACATION_CHANGE_STATUS:
            notificationUrl = `/perfil?tab=3`
            break;

        case MAX_VACATION_EMPLOYE:
            notificationUrl = `#`
            break;
        case MAX_VACATION_USER:
            notificationUrl = `/perfil?tab=3`
            break;

    }

    const timeDistance = (date, length) => {
        if (date) {
            const dateDistance = formatDistance(new Date(date), new Date(), {
                locale: es
            });

            if (dateDistance?.length > length) {
                return `${dateDistance?.slice(0, length)}...`
            }

            return dateDistance;
        }
    }

    const handleClick = () => {
        if (!currentNotification?.isRead) {
            setReadNotification();
            setCurrentNotification((oldNotification) => {
                return {
                    ...oldNotification,
                    isRead: true
                }
            });
            onReadNotification?.()
        }
    }

    return (
        <Link onClick={handleClick} to={notificationUrl} className="dropdown-item" title={`${currentNotification?.message}.`}>
            {
                !currentNotification?.isRead &&
                <small className="float-right text-muted pl-2 text-capitalize">
                    <b>Nueva</b>
                </small>
            }
            <div className="media">
                <div
                    className="avatar-md bg-success"
                >
                    <i className="la la-group text-white"></i>
                </div>
                <div className="media-body align-self-center ml-2 text-truncate">
                    <h6 className="my-0 font-weight-normal text-dark">{currentNotification?.title}</h6>
                    <small className="text-muted mb-0">{currentNotification?.message}</small>
                    <br />
                    <small className="text-capitalize text-muted mb-0">Hace: {timeDistance(currentNotification?.createdAt, 20)}</small>
                </div>
            </div>
        </Link>
    )
}

export default NotificationRow;