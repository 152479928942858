import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import logo from "../../assets/img/logo.png";
import Card from "../../components/Card";
import useAxios from "../../hooks/useAxios";
import { useEffect } from "react";
import { useFeedBack } from "../../contexts/FeedBackContext";

const NotVerified = () => {
    const { user, resetAuthInfo } = useAuth();

    const navigate = useNavigate();

    const { pathname } = useLocation();

    const { setCustomToast } = useFeedBack();

    const [{ data: resendVerificationEmailData, loading: resendVerificationEmailLoading }, resendVerificationEmail] = useAxios({
        url: '/email/verification-notification',
        method: 'POST',
    }, { manual: true });

    useEffect(() => {
        if (resendVerificationEmailData) {
            setCustomToast({ message: 'Correo enviado con éxito!', severity: 'success', show: true, position: 'top-right' });
        }
    }, [resendVerificationEmailData])

    const handleButtonClick = () => {
        resendVerificationEmail();
    }

    return <div className="container py-3">
        <div className="text-center"><img src={logo} alt="CSI" /></div>

        <Card
            className={'mt-3'}
            title={"Correo electrónico no verificado"}
            loading={resendVerificationEmailLoading}
            loadingText="Enviando correo de verificación..."
        >
            {!user
                ? <div className="text-center">Debes inicar sesión para verificar tu correo electrónico. <Link to="/login" className="text-info" state={{
                    from: pathname,
                }}>Inicar sesión</Link></div>
                : <>
                    <div className="mb-2">Antes de empezar debes verificar tu correo electrónico haciendo clic en el link que se te ha enviado a tu buzón. Si no has recibido el correo, haz clic en el botón <b>"Reenviar correo de verificación".</b></div>
                    <button
                        className="btn btn-primary"
                        disabled={resendVerificationEmailLoading}
                        onClick={handleButtonClick}
                    >
                        Reenviar correo de verificación
                    </button>
                    <Link className="btn btn-success mx-2" to={'/perfil'}>
                        Ya estoy verificado.
                    </Link>
                    <button
                        className="btn btn-danger"
                        onClick={() => {
                            resetAuthInfo();
                            navigate('/login', { replace: true });
                        }}
                    >
                        Volver e iniciar sesión con otra cuenta.
                    </button>
                </>
            }
        </Card>
    </div>;
}

export default NotVerified;