import { useState } from "react";
import { useEffect } from "react";
import DateFormatter from "../../components/DateFormatter";
import Pagination from "../../components/Pagination";
import { useAuth } from "../../contexts/AuthContext";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";

const EmployeesVacations = () => {

    const { user } = useAuth();

    const { setCustomAlert } = useFeedBack();

    const [reason, setReason] = useState({});

    const [vacationsFilters, setVacationsFilters] = useState({
        page: 1,
        statusId: 2,
        start: '',
        end: ''
    });

    const [{ data: vacationsData, loading: loadingVacations }, getVacation] = useAxios({ url: `my-account/employees/vacations` }, { useCache: false, manual: true });

    const [{ data: changeVacationStatusData, loading: changeVacationStatusLoading }, changeVacationStatus] = useAxios({ url: `vacations/boss/change-status`, method: 'put' }, { manual: true, useCache: false });

    useEffect(() => {
        getVacation({
            params: {
                ...vacationsFilters
            }
        }, { useCache: false });
    }, [vacationsFilters]);



    useEffect(() => {
        if (changeVacationStatusData) {
            getVacation({
                params: {
                    ...vacationsFilters
                }
            }, { useCache: false });
            setReason((oldReason) => {
                return {
                    text: oldReason?.text
                }
            });
            setCustomAlert({
                title: 'Enhorabuena',
                severity: 'success',
                message: 'El registro se ha actualizado exitosamente.',
                show: true
            });
        }
    }, [changeVacationStatusData])

    if (!user?.isBoss) {
        return null;
    }

    const handlePage = (page) => {
        console.log(page);
    }

    const changeStatus = (vacationId, statusId, reason) => {
        changeVacationStatus({
            data: {
                vacationId,
                statusId,
                reason: reason ? reason : null
            }
        });
    }

    const handleSubmit = (e) => {
        if (!reason?.text) {
            alert('El motivo no puede estar vacío.');
            return;
        }
        changeStatus(reason?.vacationId, reason?.statusId, reason?.text);
    }

    return (
        <div>
            <h3 className="text-center">Solicitudes de vacaciones</h3>
            <div className="text-center mt-5">
                {
                    !loadingVacations && vacationsData?.data?.length === 0 ?
                        <div style={{ margin: '20vh 0px' }}>
                            <h3>No se encontraron resultados.</h3>
                        </div>
                        :
                        null
                }
                {
                    loadingVacations &&
                    <>
                        <div className="spinner-border thumb-xl text-primary" role="status" style={{ width: 120, height: 120 }}></div>
                        <h4 className="mt-5">Cargando...</h4>
                    </>
                }
                {
                    !loadingVacations ?
                        <div className="row">
                            {
                                vacationsData?.data?.map((holiday, i) => {
                                    return (
                                        <div className="col-md-2" key={i}>
                                            <div className="card p-4">
                                                {
                                                    holiday?.user?.image_path &&
                                                    <img className="rounded" style={{ maxWidth: '100%' }} src={holiday?.user?.image_path} />
                                                }
                                                <h5>{holiday?.user?.name}</h5>
                                                <h6>
                                                    <DateFormatter date={holiday?.start} /> - <DateFormatter date={holiday?.end} />
                                                </h6>
                                                <h6 className="text-capitalize bold">
                                                    {holiday?.status?.name}
                                                </h6>
                                                <button disabled={changeVacationStatusLoading} onClick={() => changeStatus(holiday?.id, 3)} className="btn btn-block btn-success">
                                                    {
                                                        changeVacationStatusLoading ?
                                                            'Cargando...'
                                                            :
                                                            'Aceptar'
                                                    }
                                                </button>
                                                <button
                                                    onClick={() => setReason((oldReason) => {
                                                        return {
                                                            ...oldReason,
                                                            vacationId: holiday?.id,
                                                            statusId: 1
                                                        }
                                                    })}
                                                    data-toggle="modal"
                                                    data-animation="bounce"
                                                    data-target="#reasonModal"
                                                    type="button"
                                                    disabled={changeVacationStatusLoading}
                                                    className="btn btn-block btn-danger"
                                                >
                                                    {
                                                        changeVacationStatusLoading ?
                                                            'Cargando...'
                                                            :
                                                            'Rechazar'
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        null
                }
                <div>
                    <Pagination className="d-flex justify-content-center" onChangePage={handlePage} currentPage={vacationsFilters?.page} pages={vacationsData?.meta?.last_page} />
                </div>
                <div className="modal fade" id="reasonModal" tabIndex="-1" role="dialog" aria-labelledby="reasonModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="reasonModalLabel">
                                    Motivo por el cual la rechaza
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="form-group mb-3 col-md-12">
                                        <textarea
                                            className="form-control"
                                            cols="30"
                                            placeholder="Motivo del rechazo..."
                                            rows="4"
                                            onChange={(e) => { setReason((oldReason) => { return { ...oldReason, text: e.target.value } }) }}
                                        >{reason?.text}</textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Cancelar</button>
                                <button
                                    onClick={handleSubmit}
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                >
                                    Enviar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default EmployeesVacations;