import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import logo from '../assets/img/logo.png';
import NotificationContainer from "./Notifications/NotificationContainer";
import profileImg from '../assets/img/user.png';

const Topbar = ({ onClickMenu }) => {

    const { user, resetAuthInfo } = useAuth();

    const navigate = useNavigate();

    const handleBtnMenuMobileClick = (e) => {
        e.preventDefault();
        onClickMenu?.();
    }

    const handleLogOutClick = (e) => {
        e.preventDefault();

        resetAuthInfo();
        navigate('/login', { replace: true });
    }

    return <div className="topbar">
        <div className="topbar-left d-flex" style={{ textAlign: 'left' }}>
            <button type="button" className="nav-link button-menu-mobile waves-effect waves-light mr-3" onClick={handleBtnMenuMobileClick}>
                <i className="ti-menu nav-icon" style={{ fontSize: 18 }}></i>
            </button>
            <Link to="/" className="logo">
                <span>
                    <img src={logo} alt="logo-large" className="" style={{ maxWidth: '80px' }} />
                </span>
            </Link>
        </div>
        <nav className="navbar-custom">
            <ul className="list-unstyled topbar-nav float-right mb-0">
                <NotificationContainer />
                {
                    user?.isBoss &&
                    <li className="dropdown">
                        <a className="nav-link dropdown-toggle waves-effect waves-light nav-user" data-toggle="dropdown" href="/#" role="button"
                            aria-haspopup="false" aria-expanded="false">
                            <span className="ml-1 nav-user-name hidden-sm">
                                Gestionar
                                <i className="mdi mdi-chevron-down"></i>
                            </span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="/mis-empleados">
                                <i className="ti-user text-muted mr-2"></i>Empleados
                            </a>
                            <a className="dropdown-item" href="/vacaciones-empleados">
                                <i className="ti-calendar text-muted mr-2"></i>Vacaciones de los empleados
                            </a>
                        </div>

                    </li>
                }

                <li className="dropdown">
                    <a className="nav-link dropdown-toggle waves-effect waves-primary nav-user" data-toggle="dropdown" href="/#" role="button"
                        aria-haspopup="false" aria-expanded="false">
                        <img src={user?.imagePath || profileImg} alt="profile-user" className="rounded-circle" />
                        <span className="ml-1 nav-user-name hidden-sm">{user?.name} <i className="mdi mdi-chevron-down"></i> </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item" href="/perfil"><i className="ti-user text-muted mr-2"></i> Mi perfil</a>
                        <a className="dropdown-item" href="/#"><i className="ti-settings text-muted mr-2"></i> Configuración</a>
                        <div className="dropdown-divider mb-0"></div>
                        <a className="dropdown-item" href="/login" onClick={handleLogOutClick}><i className="ti-power-off text-muted mr-2"></i> Cerrar sesión</a>
                    </div>
                </li>
            </ul>
        </nav>
    </div>;
}

export default Topbar;