import env from "./env";
import pdfImage from "../assets/icons/pdf.png";
import wordImage from "../assets/icons/word.png";
import excelImage from "../assets/icons/excel.png";
import powerpointImage from "../assets/icons/powerpoint.png";
import imageImage from "../assets/icons/image.png";
import externalLinkImage from "../assets/icons/link.png";
import folderImage from "../assets/icons/carpeta.png";

const imageExtensions = ['jpeg', 'png', 'jpg'];

const pdfExtensions = ['pdf'];

const wordExtensions = ['docx'];

const excelExtensions = ['xlsx'];

const powerpointExtensions = ['ppt', 'pptx'];

const fileImageResource = (extension, fileType) => {
    if (fileType === 'external') {
        return externalLinkImage;
    }

    if (fileType === 'folder') {
        return folderImage;
    }

    if (imageExtensions.includes(extension)) {
        return imageImage;
    }

    if (pdfExtensions.includes(extension)) {
        return pdfImage;
    }

    if (wordExtensions.includes(extension)) {
        return wordImage;
    }

    if (excelExtensions.includes(extension)) {
        return excelImage;
    }

    if (powerpointExtensions.includes(extension)) {
        return powerpointImage;
    }

}

export default fileImageResource;