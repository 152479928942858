import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";
import AsyncSelect from 'react-select/async';
import useUsers from "../../hooks/useUsers";
import useZones from "../../hooks/useZones";

const ServicesUpdate = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [{ data: service, loading: serviceLoading }] = useAxios({ url: `/services/${id}` }, { useCache: false });

    const [{ data: updateData, loading: updateLoading }, updateService] = useAxios({ url: `/services/${id}`, method: 'PUT' }, { manual: true, useCache: false });

    const [{ zones, loading: zonesLoading }, getZones] = useZones({ axiosConfig: { params: { perPage: 100 } }, options: { useCache: false } });

    const [{ users, loading: usersLoading }, getUsers] = useUsers({ options: { useCache: false } });

    const [data, setData] = useState({
        name: '',
        boss: '',
        administrator: '',
        ordersBoss: '',
        adquisicionUser: '',
        ordersReplacementBoss: '',
        adquisicionReplacementUser: '',
        zone_id: ''
    });

    useEffect(() => {
        setLoading({
            show: serviceLoading,
            message: 'Cargando servicio',
        });
    }, [serviceLoading]);

    useEffect(() => {
        setLoading({
            show: updateLoading,
            message: 'Actualizando servicio',
        });
    }, [updateLoading]);

    useEffect(() => {
        if (updateData) {
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'El servicio fue actualizado exitosamente.',
                show: true
            });
            navigate('/servicios');
        }
    }, [updateData]);

    useEffect(() => {
        if (service) {
            setData(prevData => ({
                ...prevData,
                name: service.data.name,
                zone_id: service?.data?.zone_id || '',
                boss: service?.data?.boss ? { label: service?.data?.boss?.name, value: service?.data?.boss?.id } : '',
                administrator: service?.data?.administrator ? { label: service?.data?.administrator?.name, value: service?.data?.administrator?.id } : '',
                ordersBoss: service?.data?.ordersBoss ? { label: service?.data?.ordersBoss?.name, value: service?.data?.ordersBoss?.id } : '',
                adquisicionUser: service?.data?.adquisicionUser ? { label: service?.data?.adquisicionUser?.name, value: service?.data?.adquisicionUser?.id } : '',
                ordersReplacementBoss: service?.data?.ordersReplacementBoss ? { label: service?.data?.ordersReplacementBoss?.name, value: service?.data?.ordersReplacementBoss?.id } : '',
                adquisicionReplacementUser: service?.data?.adquisicionReplacementUser ? { label: service?.data?.adquisicionReplacementUser?.name, value: service?.data?.adquisicionReplacementUser?.id } : '',
            }));
        }
    }, [service]);

    const handleChange = (e) => {
        setData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        updateService({
            data: {
                name: data?.name,
                zone_id: data?.zone_id || '',
                boss_id: data?.boss?.value,
                administrator_id: data?.administrator?.value,
                order_boss_id: data?.ordersBoss?.value,
                order_acquisition_user_id: data?.adquisicionUser?.value,
                order_replacement_boss_id: data?.ordersReplacementBoss?.value,
                order_replacement_acquisition_user_id: data?.adquisicionReplacementUser?.value
            }
        });
    }

    const handleLoadUsersOptions = async (e) => {
        const getDataUsers = await getUsers({ params: { name: e, perPage: 30, page: 1 } });
        return mapValues(getDataUsers?.data?.data);
    }

    const mapValues = (values) => {
        const valuesMapped = values.map((currentUser) => {
            return {
                label: currentUser?.name,
                value: currentUser?.id
            }
        });
        return valuesMapped;
    }

    return <>
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">Actualizar servicio</h4>
            </div>

            <div className="card-body">
                <div className="basic-form">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-5">
                            <div className="form-group mb-3 col-md-6">
                                <label>Nombre</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nombre"
                                    name="name"
                                    value={data.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Zona</label>
                                <select name="zone_id" onChange={handleChange} className="form-control" value={data?.zone_id}>
                                    <option value="">Seleccione una opcion</option>
                                    {
                                        zones?.map((zone, i) => {
                                            return (
                                                <option value={zone.id} key={i}>
                                                    {zone.name}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label>
                                    Jefe de Operaciones
                                </label>
                                <AsyncSelect
                                    onFocus={() => {
                                        getUsers();
                                    }}
                                    defaultOptions={mapValues(users)}
                                    isLoading={usersLoading}
                                    loadOptions={handleLoadUsersOptions}
                                    placeholder='Escriba el nombre para buscar...'
                                    value={data?.boss}
                                    onChange={(e) => { handleChange({ target: { value: e, name: 'boss' } }) }}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>
                                    Administrador
                                </label>
                                <AsyncSelect
                                    onFocus={() => {
                                        getUsers();
                                    }}
                                    defaultOptions={mapValues(users)}
                                    isLoading={usersLoading}
                                    loadOptions={handleLoadUsersOptions}
                                    placeholder='Escriba el nombre para buscar...'
                                    value={data?.administrator}
                                    onChange={(e) => { handleChange({ target: { value: e, name: 'administrator' } }) }}
                                />
                            </div>
                            <div className="col-md-12">
                                <br />
                                <br />
                                <h1>
                                    Sistema de Pedidos
                                </h1>
                                <div className="row align-items-end">
                                    <div className="col-md-6 my-2">
                                        <h4>
                                            Productos Generales:
                                        </h4>
                                        <label>
                                            Jefe del Servicio (Aprueba el pedido)
                                        </label>
                                        <AsyncSelect
                                            onFocus={() => {
                                                getUsers();
                                            }}
                                            defaultOptions={mapValues(users)}
                                            isLoading={usersLoading}
                                            loadOptions={handleLoadUsersOptions}
                                            placeholder='Escriba el nombre para buscar...'
                                            value={data?.ordersBoss}
                                            onChange={(e) => { handleChange({ target: { value: e, name: 'ordersBoss' } }) }}
                                        />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <label>
                                            Encargado(a) de Adquisiciones (Gestión SPM)
                                        </label>
                                        <AsyncSelect
                                            onFocus={() => {
                                                getUsers();
                                            }}
                                            defaultOptions={mapValues(users)}
                                            isLoading={usersLoading}
                                            loadOptions={handleLoadUsersOptions}
                                            placeholder='Escriba el nombre para buscar...'
                                            value={data?.adquisicionUser}
                                            onChange={(e) => { handleChange({ target: { value: e, name: 'adquisicionUser' } }) }}
                                        />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <h4>
                                            Repuestos:
                                        </h4>
                                        <label>
                                            Jefe del Servicio (Aprueba el pedido)
                                        </label>
                                        <AsyncSelect
                                            onFocus={() => {
                                                getUsers();
                                            }}
                                            defaultOptions={mapValues(users)}
                                            isLoading={usersLoading}
                                            loadOptions={handleLoadUsersOptions}
                                            placeholder='Escriba el nombre para buscar...'
                                            value={data?.ordersReplacementBoss}
                                            onChange={(e) => { handleChange({ target: { value: e, name: 'ordersReplacementBoss' } }) }}
                                        />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <label>
                                            Encargado(a) de Adquisiciones (Gestión SPM)
                                        </label>
                                        <AsyncSelect
                                            onFocus={() => {
                                                getUsers();
                                            }}
                                            defaultOptions={mapValues(users)}
                                            isLoading={usersLoading}
                                            loadOptions={handleLoadUsersOptions}
                                            placeholder='Escriba el nombre para buscar...'
                                            value={data?.adquisicionReplacementUser}
                                            onChange={(e) => { handleChange({ target: { value: e, name: 'adquisicionReplacementUser' } }) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3 d-flex justify-content-end">
                            <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                Cancelar
                            </Link>
                            <button disabled={updateLoading} type="submit" className="btn btn-primary mx-2">
                                {updateLoading ? 'Cargando' : 'Actualizar'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}

export default ServicesUpdate;