import { Dropdown } from "react-bootstrap";
import fileImageResource from "../../utils/fileImageResource";
import { useEffect, useState } from "react";
import FileActionsComponent from "./FileActionsComponent";

const FileGridCard = ({ file, onClickFolder, onDelete }) => {

    const [fileName, setFileName] = useState('');

    useEffect(() => {
        if (file?.name) {
            setFileName(file?.name);
        }
    }, [file])

    const handleUpdate = (newFile) => {
        setFileName(newFile?.name);
    }

    return (
        <div className="col-sm-6 col-md-3 col-lg-2 p-1 mb-3">
            <div className="card p-1 position-relative">
                <Dropdown className="btn btn- btn-sm position-absolute" style={{ top: '-15px', right: '-5px', zIndex: 99 }} autoClose="outside">
                    <Dropdown.Toggle variant="info" size="sm" >
                        <i className="ti ti-more-alt"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <FileActionsComponent
                            onDelete={onDelete}
                            onUpdateFile={handleUpdate}
                            file={file}
                        />
                    </Dropdown.Menu>
                </Dropdown>
                {
                    file?.type === 'folder' ?
                        <div style={{ cursor: 'pointer' }} onClick={() => onClickFolder(file)}>
                            <div className="p-2">
                                <img src={fileImageResource(file?.extension, file?.type)} alt={file?.name} style={{ maxWidth: '100%' }} />
                            </div>
                            <p className="text-center">{fileName}</p>
                        </div>
                        :
                        file?.type === 'external' ?
                            <a href={file?.filePath} target="_blank">
                                <div className="p-2">
                                    <img src={fileImageResource(file?.extension, file?.type)} alt={file?.name} style={{ maxWidth: '100%' }} />
                                </div>
                                <p className="text-center">{fileName}</p>
                            </a>
                            :
                            <>
                                <div className="p-2">
                                    <img src={fileImageResource(file?.extension, file?.type)} alt={file?.name} style={{ maxWidth: '100%' }} />
                                </div>
                                <p className="text-center">{fileName}</p>
                            </>
                }
            </div>
        </div>
    )
}

export default FileGridCard;