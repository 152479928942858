import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImgUploadInput from "../../components/ImgUploadInput";
import { useAuth } from "../../contexts/AuthContext";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";
import useDocumentNumberTypes from "../../hooks/useDocumentNumberTypes";
import usePositions from "../../hooks/usePositions";
import useRoles from "../../hooks/useRoles";
import useServices from "../../hooks/useServices";
import useSystems from "../../hooks/useSystems";
import defaultUserImg from "../../assets/img/user.png";
import useCostCenters from "../../hooks/useCostCenters";
import useOffices from "../../hooks/useOffices";
import useContractTypes from "../../hooks/useContractTypes";
import useUsers from "../../hooks/useUsers";
import AsyncSelect from 'react-select/async';
import handleLoadSelectOptions from "../../utils/loadSelectValues";
import mapValues from "../../utils/mapValues";

const UsersUpdate = () => {

    const { user: currentUser, setAuthInfo } = useAuth();

    const { id } = useParams();

    const navigate = useNavigate();

    const { setCustomAlert, setLoading } = useFeedBack();

    const filters = {
        perPage: 200,
        page: 1
    };

    const [firstLoading, setFirstLoading] = useState(true);

    const [data, setData] = useState({
        name: '',
        email: '',
        password: '',
        address: '',
        days: '',
        image: null,
        phoneNumber: '',
        documentNumber: '',
        documentNumberTypeId: 1,
        serviceIds: [],
        systems: [],
        sapCode: '',
        admissionDate: '',
        birthdayDate: '',
        boss: '',
        private: 'no',
        position: '',
        office: '',
        costCenter: '',
        contractType: '',
        _method: 'PUT'
    });

    const [imagePreview, setImagePreview] = useState('');

    const [{ data: user, error: userError, loading: userLoading }] = useAxios({ url: `/users/${id}` }, { useCache: false });

    const [{ data: updateData, loading: updateLoading, error: updateError }, updateUser] = useAxios({ url: `/users/${id}`, method: 'POST' }, { manual: true, useCache: false });

    const [{ documentNumberTypes, error: documentNumberTypesError, loading: documentNumberTypesLoading }] = useDocumentNumberTypes({ options: { useCache: false } });

    /* FOR SELECTS */
    const [{ positions, error: positionsError, loading: positionsLoading }, getPositions] = usePositions({ axiosConfig: { params: { ...filters } }, options: { useCache: false, manual: true } });

    const [{ users, total, numberOfPages, error: usersError, loading: usersLoading }, getUsers] = useUsers({ axiosConfig: { params: { ...filters, exceptId: id } }, options: { useCache: false, manual: true } });

    const [{ contractTypes, error: contractTypesError, loading: contractTypesLoading }, getContractTypes] = useContractTypes({ axiosConfig: { params: { ...filters } }, options: { useCache: false, manual: true } });

    const [{ costCenters, error: costCentersError, loading: costCentersLoading }, getCostCenters] = useCostCenters({ axiosConfig: { params: { ...filters } }, options: { useCache: false, manual: true } });

    const [{ offices, error: officesError, loading: officesLoading }, getOffices] = useOffices({ axiosConfig: { params: { ...filters } }, options: { useCache: false, manual: true } });

    const [{ services: userServices, loading: userServicesLoading }, getServices] = useServices({ options: { useCache: false } });

    /* END FOR SELECTS */

    const [{ services, error: servicesError, loading: servicesLoading }] = useServices({ axiosConfig: { params: { ...filters } }, options: { useCache: false } });

    const [{ roles, error: rolesError, loading: rolesLoading }, getRoles] = useRoles({ axiosConfig: { params: { ...filters, systemCodes: data.systems } }, options: { manual: true, useCache: false } });

    const [{ systems, error: systemsError, loading: systemsLoading }] = useSystems({ options: { useCache: false } });

    useEffect(() => {
        if (user) {
            const {
                createdAt,
                documentNumberType,
                id,
                imagePath,
                position,
                isBoss,
                employees,
                roles,
                services,
                systems,
                contractType,
                costCenter,
                office,
                service,
                admissionDate,
                birthdayDate,
                boss,
                twitter,
                youtube,
                linkedin,
                instagram,
                facebook,
                ...rest
            } = user?.data;
            setData((oldData) => {
                return {
                    ...oldData,
                    ...rest,
                    admissionDate: admissionDate ? admissionDate.split('T')[0] : '',
                    birthdayDate: birthdayDate ? birthdayDate.split('T')[0] : '',
                    roles: roles?.map(role => role.name) ?? [],
                    serviceIds: services?.map(service => service?.id) ?? [],
                    systems: systems?.map(system => system.code) ?? [],
                    position: { label: position?.name || 'Seleccione una opcion', value: position?.id },
                    boss: { label: boss?.name || 'Seleccione una opcion', value: boss?.id },
                    office: { label: office?.name || 'Seleccione una opcion', value: office?.id },
                    contractType: { label: contractType?.name || 'Seleccione una opcion', value: contractType?.id },
                    costCenter: { label: costCenter?.name || 'Seleccione una opcion', value: costCenter?.id },
                    service: { label: service?.name || 'Seleccione una opcion', value: service?.id },
                    private: rest?.private ? 'si' : 'no'
                }
            });

            if (imagePath) setImagePreview(`${imagePath}`);
        }
    }, [user]);

    useEffect(() => {
        if (
            !documentNumberTypesLoading &&
            !servicesLoading &&
            !userLoading &&
            !systemsLoading
        ) {
            setFirstLoading(false);
        } else {
            setFirstLoading(true)
        }
    }, [documentNumberTypesLoading, servicesLoading, userLoading, systemsLoading]);

    useEffect(() => {
        setLoading({
            show: firstLoading,
            message: 'Obteniendo informacion'
        });
    }, [firstLoading]);

    const [canRemove, setCanRemove] = useState(false);

    useEffect(() => {
        if (data?.documentNumber?.length > 7 && !data?.documentNumber?.includes('-') && !canRemove) {
            setCanRemove(true);
            setData((oldData) => {
                return {
                    ...oldData,
                    documentNumber: `${oldData?.documentNumber}-`
                }
            })
        }

        if (data?.documentNumber?.length < 7 && canRemove) {
            setCanRemove(false);
        }

    }, [data?.documentNumber, canRemove])

    useEffect(() => {
        if (data.systems.length === 0) {
            setData(prevData => ({
                ...prevData,
                roles: [],
            }))
        }

        if (data.systems?.length > 0) {
            getRoles({
                params: {
                    ...filters,
                    systemCodes: data.systems
                }
            })
        }
    }, [data.systems]);

    useEffect(() => {
        if (updateData) {
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'El usuario fue creado exitosamente.',
                show: true
            });
            navigate('/usuarios');
            if (updateData?.data?.id === currentUser?.id) {
                const { createdAt, role, ...rest } = updateData?.data;
                setAuthInfo((oldAuthInfo) => {
                    return {
                        ...oldAuthInfo,
                        user: {
                            ...oldAuthInfo?.user,
                            ...rest
                        }
                    }
                });
            }
        }
    }, [updateData])

    useEffect(() => {
        if (updateError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error.',
                show: true
            });
        }

        if (usersError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los jefes.',
                show: true
            });
        }

        if (positionsError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los cargos.',
                show: true
            });
        }

        if (documentNumberTypesError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los tipos de documentos.',
                show: true
            });
        }

        if (rolesError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los roles.',
                show: true
            });
        }

        if (servicesError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los servicios.',
                show: true
            });
        }

        if (userError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los datos del usuario.',
                show: true
            });
        }

        if (systemsError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los sistemas.',
                show: true
            });
        }

        if (contractTypesError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los tipos de contratos.',
                show: true
            });
        }

        if (costCentersError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los centros de costos.',
                show: true
            });
        }

        if (officesError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener las oficinas.',
                show: true
            });
        }

    }, [usersError, positionsError, documentNumberTypesError, rolesError, servicesError, systemsError, contractTypesError, costCentersError, officesError])

    const handleSubmit = (e) => {
        e?.preventDefault?.();

        if (updateLoading) return;

        const { image: image2, password: password2, boss_id, ...requireValues } = data;

        const formData = new FormData();
        const { image, serviceIds, systems, roles, password, ...rest } = data;

        Object.keys(rest).forEach((key, i) => {
            if (
                data[key] &&
                key !== 'position' &&
                key !== 'boss' &&
                key !== 'office' &&
                key !== 'contractType' &&
                key !== 'costCenter' &&
                key !== 'service'
            ) {
                formData?.append(key, data[key]);
            }
        });

        if (data?.position?.value) formData?.append('positionId', data?.position?.value);
        if (data?.boss?.value) formData?.append('boss_id', data?.boss?.value);
        if (data?.office?.value) formData?.append('officeId', data?.office?.value);
        if (data?.contractType?.value) formData?.append('contractTypeId', data?.contractType?.value);
        if (data?.costCenter?.value) formData?.append('costCenterId', data?.costCenter?.value);
        if (data?.service?.value) formData?.append('serviceId', data?.service?.value);

        serviceIds?.forEach?.((serviceId, i) => {
            formData?.append(`serviceIds[${i}]`, serviceId);
        });

        systems?.forEach?.((system, i) => {
            formData?.append(`systems[${i}]`, system);
        });

        roles?.forEach?.((role, i) => {
            formData?.append(`roles[${i}]`, role);
        });

        if (password) {
            formData?.append(`password`, password);
        }

        if (image) {
            formData?.append('image', image, image?.name);
        }

        updateUser({ data: formData });
    }

    const handleChange = (e) => {

        if (e.target.name === 'documentNumber' && e.target.value.length > 1) {

            var value = e.target.value.replace('-', '');

            const newValue = `${value?.slice(0, -1)}-${value?.substr(value.length - 1)}`;

            e.target.value = newValue;
        }

        if (e.target.type === 'checkbox') {
            const value = data[e.target.name]?.includes(e.target.value);
            if (value) {
                const newValues = data[e.target.name]?.filter(n => n !== e.target.value);
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: newValues
                    }
                });
            } else {
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: [...data[e.target.name], e.target.value]
                    }
                });
            }
            return;
        }


        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    const checker = (arr, target) => arr.every((value) => target?.includes(value));

    const handleAllServices = () => {
        let hash = {};
        if (checker(services?.map(service => service?.id), data?.serviceIds)) {
            services?.forEach((service) => {
                hash[service?.id] = true;
            });
            setData((oldData) => {
                return {
                    ...oldData,
                    serviceIds: data?.serviceIds?.filter((serviceId) => !hash[serviceId])
                }
            });
        } else {
            services.forEach((service) => {
                hash[service?.id] = true;
            });

            let oldServicesIds = data?.serviceIds?.filter((serviceId) => !hash[serviceId]);

            setData((oldData) => {
                return {
                    ...oldData,
                    serviceIds: [...oldServicesIds, ...services?.map((service) => service?.id)]
                }
            });

        }
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Actualizar usuario</h4>
                </div>
                <div className="card-body">
                    <div className="basic-form">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group col-1 mb-3">
                                <ImgUploadInput previewImage={imagePreview || defaultUserImg} description="imagen" name="image" change={handleChange} style={{ height: 80, width: 80 }} />
                            </div>
                            <div className="row mb-5">
                                <div className="form-group mb-3 col-md-4">
                                    <label>Nombre <span className="text-danger">*</span></label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Nombre"
                                        name="name"
                                        value={data?.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label htmlFor="admissionDate">Fecha de Ingreso <span className="text-danger">*</span></label>
                                    <input
                                        required
                                        type="date"
                                        className="form-control"
                                        name="admissionDate"
                                        value={data?.admissionDate}
                                        id="admissionDate"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label htmlFor="birthdayDate">Fecha de Nacimiento <span className="text-danger">*</span></label>
                                    <input
                                        required
                                        type="date"
                                        className="form-control"
                                        name="birthdayDate"
                                        value={data?.birthdayDate}
                                        id="birthdayDate"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Email <span className="text-danger">*</span></label>
                                    <input
                                        required
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        value={data?.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Contraseña</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Contraseña"
                                        name="password"
                                        autoComplete="off"
                                        value={data?.password}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Número de teléfono <span className="text-danger">*</span></label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Ingrese un numero telefonico"
                                        name="phoneNumber"
                                        value={data?.phoneNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Número de documento <span className="text-danger">*</span></label>
                                    <div className="d-flex">
                                        <select
                                            className="form-control w-25"
                                            name="documentNumberTypeId"
                                            value={data?.documentNumberTypeId}
                                            onChange={handleChange}
                                        >
                                            <option value="">
                                                Tipo...
                                            </option>
                                            {
                                                documentNumberTypes?.map?.((type, i) => {
                                                    return (
                                                        <option value={type?.id} key={i}>
                                                            {type?.name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <input
                                            required
                                            type="text"
                                            className="form-control "
                                            placeholder="Ej: 26629346-6"
                                            name="documentNumber"
                                            value={data?.documentNumber}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Días de vacaciones disponibles <span className="text-danger">*</span></label>
                                    <input
                                        required
                                        type="number"
                                        className="form-control"
                                        placeholder="Días de vacaciones"
                                        name="days"
                                        value={data?.days}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Código Sap <span className="text-danger">*</span></label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Ingrese el codigo sap"
                                        name="sapCode"
                                        value={data?.sapCode}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Servicio al cual pertenece</label>
                                    <AsyncSelect
                                        isClearable
                                        onFocus={() => {
                                            getServices();
                                        }}
                                        value={data?.service}
                                        defaultOptions={mapValues(userServices)}
                                        isLoading={userServicesLoading}
                                        loadOptions={(e) => handleLoadSelectOptions(e, getServices)}
                                        placeholder='Escriba el nombre para buscar...'
                                        onChange={(e) => { handleChange({ target: { value: e, name: 'service' } }) }}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Privado</label>
                                    <select className="form-control" name="private" id="" onChange={handleChange} value={data?.private}>
                                        <option value="si">Si</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                                <div className="form-group mb-3 col-md-12">
                                    <label htmlFor="address">Dirección <span className="text-danger">*</span></label>
                                    <textarea
                                        required
                                        className="form-control"
                                        rows="5"
                                        placeholder="Ingrese una direccion"
                                        id="address"
                                        name="address"
                                        value={data?.address}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Cargo <span className="text-danger">*</span></label>
                                    <AsyncSelect
                                        required
                                        isClearable
                                        onFocus={() => {
                                            getPositions();
                                        }}
                                        defaultOptions={mapValues(positions)}
                                        isLoading={positionsLoading}
                                        loadOptions={(e) => handleLoadSelectOptions(e, getPositions)}
                                        placeholder='Escriba el nombre para buscar...'
                                        value={data?.position}
                                        onChange={(e) => { handleChange({ target: { value: e, name: 'position' } }) }}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Jefe directo</label>
                                    <AsyncSelect
                                        isClearable
                                        onFocus={() => {
                                            getUsers();
                                        }}
                                        defaultOptions={mapValues(users)}
                                        isLoading={usersLoading}
                                        loadOptions={(e) => handleLoadSelectOptions(e, getUsers)}
                                        placeholder='Escriba el nombre para buscar...'
                                        value={data?.boss}
                                        onChange={(e) => { handleChange({ target: { value: e, name: 'boss' } }) }}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Oficina</label>
                                    <AsyncSelect
                                        isClearable
                                        onFocus={() => {
                                            getOffices();
                                        }}
                                        defaultOptions={mapValues(offices)}
                                        isLoading={officesLoading}
                                        loadOptions={(e) => handleLoadSelectOptions(e, getOffices)}
                                        placeholder='Escriba el nombre para buscar...'
                                        value={data?.office}
                                        onChange={(e) => { handleChange({ target: { value: e, name: 'office' } }) }}
                                    />
                                </div>

                                <div className="form-group mb-3 col-md-6">
                                    <label>Centro de costos</label>
                                    <AsyncSelect
                                        isClearable
                                        onFocus={() => {
                                            getCostCenters();
                                        }}
                                        defaultOptions={mapValues(costCenters)}
                                        isLoading={costCentersLoading}
                                        loadOptions={(e) => handleLoadSelectOptions(e, getCostCenters)}
                                        placeholder='Escriba el nombre para buscar...'
                                        value={data?.costCenter}
                                        onChange={(e) => { handleChange({ target: { value: e, name: 'costCenter' } }) }}
                                    />
                                </div>

                                <div className="form-group mb-3 col-md-6">
                                    <label>Tipo de contrato</label>
                                    <AsyncSelect
                                        isClearable
                                        onFocus={() => {
                                            getContractTypes();
                                        }}
                                        defaultOptions={mapValues(contractTypes)}
                                        isLoading={contractTypesLoading}
                                        is
                                        loadOptions={(e) => handleLoadSelectOptions(e, getContractTypes)}
                                        placeholder='Escriba el nombre para buscar...'
                                        value={data?.contractType}
                                        onChange={(e) => { handleChange({ target: { value: e, name: 'contractType' } }) }}
                                    />
                                </div>

                                <div className="form-group mb-3 col-md-12">
                                    <div className="mb-1">
                                        <h6>Servicios</h6>
                                        <small>Seleccione los servicios a los cuales tendrá acceso el usuario.</small>
                                    </div>
                                    <div className="form-check-inline">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="serviceIds"
                                                checked={checker(services?.map(service => service.id), data?.serviceIds)}
                                                onChange={handleAllServices}
                                            />
                                            <label className="custom-control-label" htmlFor="serviceIds">Seleccionar todos</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {
                                            services?.map((service) => {
                                                const inputId = `service-${service.id}`;

                                                return <div key={service.id} className="col-md-3">
                                                    <div className="form-check-inline">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                value={service.id}
                                                                id={inputId}
                                                                checked={data?.serviceIds?.includes(service?.id)}
                                                                onChange={() => { handleChange({ target: { name: 'serviceIds', value: service.id, type: 'checkbox' } }) }}
                                                            />
                                                            <label className="custom-control-label" htmlFor={inputId}>{service.name}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="form-group mb-3 col-md-12">
                                    <div className="mb-1">
                                        <h6>Sistemas</h6>
                                        <small>Seleccione los sistemas a los cuales tendrá acceso el usuario.</small>
                                    </div>

                                    {systems?.map(system => {
                                        return <div key={system.code} className="form-check-inline">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    value={system.code}
                                                    id={`system-${system.code}`}
                                                    checked={data?.systems?.includes(system.code)}
                                                    onChange={() => { handleChange({ target: { name: 'systems', value: system.code, type: 'checkbox' } }) }}
                                                />
                                                <label className="custom-control-label" htmlFor={`system-${system.code}`}>{system.name}</label>
                                            </div>
                                        </div>
                                    })}
                                </div>

                                <div className="form-group mb-3 col-md-12">
                                    <h6>Roles</h6>
                                    <div className="row">
                                        {data.systems.length > 0
                                            ? roles?.map(role => {
                                                const inputId = `system-${role.name}-${role.systemCode}`;
                                                return <div key={role.name} className="col-md-3">
                                                    <div className="form-check-inline">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                value={role.name}
                                                                id={inputId}
                                                                checked={data?.roles?.includes(role.name)}
                                                                onChange={() => { handleChange({ target: { name: 'roles', value: role.name, type: 'checkbox' } }) }}
                                                            />
                                                            <label className="custom-control-label" htmlFor={inputId}>{role.displayText} (<small className="text-capitalize">{role.system?.name}</small>)</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                            : <small className="col-md-4">Seleccione al menos un sistema</small>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 d-flex justify-content-end">
                                <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                    Cancelar
                                </Link>
                                <button disabled={updateLoading} type="submit" className="btn btn-primary mx-2">
                                    {
                                        updateLoading ?
                                            'Cargando'
                                            :
                                            'Actualizar'
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UsersUpdate;