import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";
import useUsers from "../../hooks/useUsers";
import AsyncSelect from 'react-select/async';
import useZones from "../../hooks/useZones";

const ServicesCreate = () => {
    const navigate = useNavigate();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [data, setData] = useState({
        name: '',
        order_boss_id: '',
        order_acquisition_user_id: '',
        order_replacement_boss_id: '',
        order_replacement_acquisition_user_id: '',
        boss_id: '',
        administrator_id: '',
        zone_id: ''
    });

    const [{ users, loading: usersLoading }, getUsers] = useUsers({ options: { useCache: false } });

    const [{ zones, loading: zonesLoading }, getZones] = useZones({ axiosConfig: { params: { perPage: 100 } }, options: { useCache: false } });

    const [{ data: createData, loading: createLoading }, createService] = useAxios({ url: '/services', method: 'POST' }, { manual: true });

    useEffect(() => {
        setLoading({
            show: createLoading,
            message: 'Creando servicio',
        });
    }, [createLoading]);

    useEffect(() => {
        if (createData) {
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'El servicio fue creado exitosamente.',
                show: true
            });
            navigate('/servicios');
        }
    }, [createData]);

    const handleChange = (e) => {
        setData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        createService({ data });
    }

    const handleLoadUsersOptions = async (e) => {
        const getDataUsers = await getUsers({ params: { name: e, perPage: 30, page: 1 } });
        return mapValues(getDataUsers?.data?.data);
    }

    const mapValues = (values) => {
        const valuesMapped = values.map((currentUser) => {
            return {
                label: currentUser?.name,
                value: currentUser?.id
            }
        });
        return valuesMapped;
    }

    return <>
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">Crear servicio</h4>
            </div>

            <div className="card-body">
                <div className="basic-form">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-5">
                            <div className="form-group mb-3 col-md-6">
                                <label>Nombre</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nombre"
                                    name="name"
                                    value={data.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Zona</label>
                                <select name="zone_id" onChange={handleChange} className="form-control" value={data?.zone_id}>
                                    <option value="">Seleccione una opcion</option>
                                    {
                                        zones?.map((zone, i) => {
                                            return (
                                                <option value={zone.id} key={i}>
                                                    {zone.name}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label>
                                    Jefe del Servicio
                                </label>
                                <AsyncSelect
                                    onFocus={() => {
                                        getUsers();
                                    }}
                                    defaultOptions={mapValues(users)}
                                    isLoading={usersLoading}
                                    loadOptions={handleLoadUsersOptions}
                                    placeholder='Escriba el nombre para buscar...'
                                    onChange={(e) => { handleChange({ target: { value: e?.value, name: 'boss_id' } }) }}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>
                                    Administrador
                                </label>
                                <AsyncSelect
                                    onFocus={() => {
                                        getUsers();
                                    }}
                                    defaultOptions={mapValues(users)}
                                    isLoading={usersLoading}
                                    loadOptions={handleLoadUsersOptions}
                                    placeholder='Escriba el nombre para buscar...'
                                    onChange={(e) => { handleChange({ target: { value: e?.value, name: 'administrator_id' } }) }}
                                />
                            </div>
                            <div className="col-md-12">
                                <br />
                                <br />
                                <h1>
                                    Sistema de Pedidos
                                </h1>
                                <div className="row align-items-end">
                                    <div className="col-md-6 my-2">
                                        <h4>
                                            Productos Generales:
                                        </h4>
                                        <label>
                                            Jefe del Servicio (Aprueba el pedido)
                                        </label>
                                        <AsyncSelect
                                            onFocus={() => {
                                                getUsers();
                                            }}
                                            defaultOptions={mapValues(users)}
                                            isLoading={usersLoading}
                                            loadOptions={handleLoadUsersOptions}
                                            placeholder='Escriba el nombre para buscar...'
                                            onChange={(e) => { handleChange({ target: { value: e?.value, name: 'order_boss_id' } }) }}
                                        />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <label>
                                            Encargado de Adquisiciones (Gestiona el pedido)
                                        </label>
                                        <AsyncSelect
                                            onFocus={() => {
                                                getUsers();
                                            }}
                                            defaultOptions={mapValues(users)}
                                            isLoading={usersLoading}
                                            loadOptions={handleLoadUsersOptions}
                                            placeholder='Escriba el nombre para buscar...'
                                            onChange={(e) => { handleChange({ target: { value: e?.value, name: 'order_acquisition_user_id' } }) }}
                                        />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <h4>
                                            Repuestos:
                                        </h4>
                                        <label>
                                            Jefe del Servicio (Aprueba el pedido)
                                        </label>
                                        <AsyncSelect
                                            onFocus={() => {
                                                getUsers();
                                            }}
                                            defaultOptions={mapValues(users)}
                                            isLoading={usersLoading}
                                            loadOptions={handleLoadUsersOptions}
                                            placeholder='Escriba el nombre para buscar...'
                                            onChange={(e) => { handleChange({ target: { value: e?.value, name: 'order_replacement_boss_id' } }) }}
                                        />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <label>
                                            Encargado de Adquisiciones (Gestiona el pedido)
                                        </label>
                                        <AsyncSelect
                                            onFocus={() => {
                                                getUsers();
                                            }}
                                            defaultOptions={mapValues(users)}
                                            isLoading={usersLoading}
                                            loadOptions={handleLoadUsersOptions}
                                            placeholder='Escriba el nombre para buscar...'
                                            onChange={(e) => { handleChange({ target: { value: e?.value, name: 'order_replacement_acquisition_user_id' } }) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3 d-flex justify-content-end">
                            <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                Cancelar
                            </Link>
                            <button disabled={createLoading} type="submit" className="btn btn-primary mx-2">
                                {createLoading ? 'Cargando' : 'Crear'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}

export default ServicesCreate;