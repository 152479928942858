import { useEffect, useState } from "react";
import Table from "../../components/Table";
import { useAuth } from "../../contexts/AuthContext";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";
import swal from "../../utils/swal";
import useVacations from "../../hooks/useVacations";
import vacationsColumns from "../../tableColumns/vacationsColumns";
import useAxiosWebDoxcs from "../../hooks/useAxiosWebDoxcs";
import env from "../../utils/env";

const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
};

const Vacations = () => {

    const { permissions } = useAuth();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [filters, setFilters] = useState({
        page: 1,
        search: ''
    })

    const [{ vacations, total, numberOfPages, error: vacationsError, loading: vacationsLoading }, getVacations] = useVacations({ axiosConfig: { params: { ...filters } }, options: { useCache: false } });

    const [{ data: changeVacationStatusData, loading: changeVacationStatusLoading }, changeVacationStatus] = useAxios({ url: `vacations/change-status`, method: 'put' }, { manual: true, useCache: false });

    const [{ }, createWebDocxsWorkflow] = useAxiosWebDoxcs({ url: '/decision_workflows', method: 'POST' }, { useCache: false, manual: true });

    const [{ }, authWebdoxcs] = useAxiosWebDoxcs({ url: '/oauth/token', method: 'POST' }, { useCache: false, manual: true });

    const [{ }, createVacationsPdf] = useAxios({ responseType: 'blob' }, { useCache: false, manual: true });

    const [{ }, saveWorkFlowId] = useAxios({ method: 'PUT' }, { useCache: false, manual: true });

    const [{ }, addWebDocxsDocument] = useAxiosWebDoxcs({ method: 'POST' }, { useCache: false, manual: true });

    const [{ error: deleteError, loading: deleteLoading }, deleteRecord] = useAxios({ method: 'DELETE' }, { manual: true, useCache: false });

    useEffect(() => {
        setLoading?.({
            show: changeVacationStatusLoading,
            message: 'Cambiando Estatus'
        })
    }, [changeVacationStatusLoading])

    useEffect(() => {
        setLoading?.({
            show: deleteLoading,
            message: 'Eliminando Registro(s)'
        })
    }, [deleteLoading])

    useEffect(() => {
        if (deleteError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'Ha ocurrido un error al eliminar.',
                show: true
            });
        }

        if (vacationsError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los registros.',
                show: true
            });
        }
    }, [deleteError, vacationsError])

    useEffect(() => {
        if (changeVacationStatusData) {
            if (changeVacationStatusData?.data?.status?.id === 3) {
                createDocumentOnWebdoxcs();
            } else {
                getVacations();

                setCustomAlert({
                    title: 'Enhorabuena',
                    severity: 'success',
                    message: 'El registro se ha actualizado exitosamente.',
                    show: true
                });
            }
        }
    }, [changeVacationStatusData])

    const handleDelete = async (id) => {
        await deleteRecord({ url: `/vacations/${id}` });

        swal({
            icon: 'success',
            title: '¡Eliminado!',
            text: '¡Registro eliminado exitosamente!'
        });

        getVacations();
    }

    const handlePageChange = (page) => {
        if (page > 0) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    const handleDeleteSelected = async (selectedValues) => {
        await deleteRecord({ url: `/vacations/multiple`, data: { ids: selectedValues } });

        swal({
            icon: 'success',
            title: '¡Eliminados!',
            text: '¡Registros eliminados exitosamente!'
        });

        getVacations();
    }

    const changeStatus = (vacationId, statusId, reason) => {
        changeVacationStatus({
            data: {
                vacationId,
                statusId,
                reason: reason ? reason : null
            }
        });
    }

    const handleAction = (action, item, value) => {
        if (action === 'change-status') {
            const reason = value === 1 ? 'Rechazada por el administrador' : '';
            changeStatus(item?.id, value, reason);
        }
    }

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    const createDocumentOnWebdoxcs = async () => {

        setLoading({
            show: true,
            message: 'Por favor espere'
        });

        try {

            const authWebdocxResponse = await authWebdoxcs({
                data: {
                    username: env('WEBDOCX_USER'),
                    password: env('WEBDOCX_PASSWORD'),
                    grant_type: "password"
                }
            });

            const accessToken = authWebdocxResponse?.data?.access_token;

            const createWFResponse = await createWebDocxsWorkflow({
                headers: {
                    ...headers,
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    decision_name: `Vacaciones #${changeVacationStatusData?.data?.id} - ${changeVacationStatusData?.data?.user?.name} - ${changeVacationStatusData?.data?.user?.document_number}`,
                    decision_workflow_template_id: '3d207f58-c659-4224-a345-4679e0f5f06d'
                }
            });

            const workFlowData = createWFResponse?.data;

            const pdfResponse = await createVacationsPdf({
                url: `/vacations/${changeVacationStatusData?.data?.id}/pdf`
            });

            var formData = new FormData();

            var blobFile = pdfResponse?.data;

            blobFile.lastModifiedDate = new Date();
            blobFile.name = `Vacaciones #${changeVacationStatusData?.data?.id} - ${changeVacationStatusData?.data?.user?.name} - ${changeVacationStatusData?.data?.user?.document_number}.pdf`;

            formData.append('origin', 'pc');
            formData.append('versioned_document', true);
            formData.append('attachment', blobFile, blobFile.name);

            const webDocxDocumentResponse = await addWebDocxsDocument({
                headers: {
                    ...headers,
                    Authorization: `Bearer ${accessToken}`
                },
                url: `/decision_workflows/${workFlowData?.id}/documents?number=1`,
                data: formData
            });

            if (changeVacationStatusData?.data?.user?.cost_center?.name) {

                const addDocumentToClasification = await addWebDocxsDocument({
                    method: 'PUT',
                    headers: {
                        ...headers,
                        Authorization: `Bearer ${accessToken}`
                    },
                    url: `/documents/${webDocxDocumentResponse?.data?.document?.id}`,
                    data: {
                        document: {
                            [`subject_id`]: `c06213fc-c9f8-4eaf-a3bd-b8128ae2d983`,
                            [`dynamic_attribute_33319561-8d59-4848-bc2b-dfdc07f9d598`]: `${changeVacationStatusData?.data?.user?.cost_center?.name}`
                        }
                    }
                });
            }

            const responseDocumentSigner = await addWebDocxsDocument({
                method: 'POST',
                headers: {
                    ...headers,
                    Authorization: `Bearer ${accessToken}`
                },
                url: `/decision_workflows/${workFlowData?.id}/set_signable_document`,
                data: {
                    document_id: webDocxDocumentResponse?.data?.document?.id,
                    number: 2
                }
            });

            const setSignerResponse = await addWebDocxsDocument({
                method: 'POST',
                headers: {
                    ...headers,
                    Authorization: `Bearer ${accessToken}`
                },
                url: `/decision_workflows/${workFlowData?.id}/steps/2/signers`,
                data: {
                    kind: 'external',
                    country_code: 'CHL',
                    name: changeVacationStatusData?.data?.user?.name,
                    email: changeVacationStatusData?.data?.user?.email,
                    national_identification_number: changeVacationStatusData?.data?.user?.document_number
                }
            });

            if (changeVacationStatusData?.data?.user?.boss) {
                const setBossSignerResponse = await addWebDocxsDocument({
                    headers: {
                        ...headers,
                        Authorization: `Bearer ${accessToken}`
                    },
                    method: 'POST',
                    url: `/decision_workflows/${workFlowData?.id}/steps/2/signers`,
                    data: {
                        kind: 'external',
                        country_code: 'CHL',
                        name: changeVacationStatusData?.data?.user?.boss?.name,
                        email: changeVacationStatusData?.data?.user?.boss?.email,
                        national_identification_number: changeVacationStatusData?.data?.user?.boss?.document_number
                    }
                });
            }

            const confirmStepOneResponse = await addWebDocxsDocument({
                headers: {
                    ...headers,
                    Authorization: `Bearer ${accessToken}`
                },
                method: 'PUT',
                url: `/decision_workflows/${workFlowData?.id}/validate?number=1`,
                data: {
                    comment: 'Validado por la intranet.'
                }
            });

            const confirmStepTwoResponse = await addWebDocxsDocument({
                headers: {
                    ...headers,
                    Authorization: `Bearer ${accessToken}`
                },
                method: 'PUT',
                url: `/decision_workflows/${workFlowData?.id}/validate?number=2`,
                data: {
                    comment: 'Validado por la intranet.'
                }
            });

            const saveWFIdResponse = await saveWorkFlowId({
                url: `/vacations/${changeVacationStatusData?.data?.id}/workflow`,
                data: {
                    workFlowId: workFlowData?.id
                }
            });

            getVacations();

            setCustomAlert({
                title: 'Enhorabuena',
                severity: 'success',
                message: 'El registro se ha actualizado exitosamente.',
                show: true
            });

            setLoading({
                show: false,
                message: ''
            });

        } catch (error) {

            console.log(error);

            setLoading({
                show: false,
                message: ''
            });
        }

    }

    return (
        <div>
            <Table
                title="Vacaciones"
                columns={vacationsColumns({ onDelete: handleDelete, handleAction: handleAction })}
                onSearchChange={(e) => handleChange({ target: { value: e?.target.value, name: 'search' } })}
                searchValue={filters?.search}
                excelUrl={'/vacations/export/excel'}
                excelFileName='vacaciones.xlsx'
                items={vacations}
                total={total}
                currentPage={filters.page}
                pages={numberOfPages}
                loading={vacationsLoading}
                onChangePage={handlePageChange}
                onDeleteSelected={handleDeleteSelected}
                withSelection
                perPageValue={filters?.perPage}
                onChagePerPage={(e) => {
                    setFilters((oldFilters) => {
                        return {
                            ...oldFilters,
                            perPage: e.target.value,
                            page: 1
                        }
                    })
                }}
            />
        </div>
    )
}
export default Vacations;