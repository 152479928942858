import { Dropdown, Modal } from "react-bootstrap";
import fileImageResource from "../../utils/fileImageResource";
import { useEffect, useState } from "react";
import FileActionsComponent from "./FileActionsComponent";

const FileListRow = ({ file, onDelete }) => {

    const [fileName, setFileName] = useState('');

    useEffect(() => {
        if (file?.name) {
            setFileName(file?.name);
        }
    }, [file])

    const handleUpdate = (newFile) => {
        setFileName(newFile?.name);
    }


    return (
        <div className="p-1">
            <div className="p-1 d-flex align-items-center" style={{ display: 'flex !important', boxShadow: '0px 2px 4px rgba(31,30,47,0.1)', background: 'white' }}>
                {
                    file?.type === 'external' ?
                        <a href={file?.filePath} target="_blank" className="d-flex align-items-center">
                            <img src={fileImageResource(file?.extension, file?.type)} alt={file?.name} style={{ maxWidth: 50 }} />
                            <p className="m-0 ml-2">{fileName}</p>
                        </a>
                        :
                        <>
                            <img src={fileImageResource(file?.extension, file?.type)} alt={file?.name} style={{ maxWidth: 50 }} />
                            <p className="m-0 ml-2">{fileName}</p>
                        </>
                }
                <Dropdown className="btn btn-sm ml-auto" autoClose="outside">
                    <Dropdown.Toggle variant="" size="sm" >
                        <i className="ti ti-more-alt"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <FileActionsComponent
                            onDelete={onDelete}
                            onUpdateFile={handleUpdate}
                            file={file}
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export default FileListRow;