import { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useFeedBack } from "../contexts/FeedBackContext";
import useAxios from "../hooks/useAxios";
import env from '../utils/env';

const Login = () => {
    const { setAuthInfo } = useAuth();
    const { setLoading } = useFeedBack();

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: { value: '', isDirty: false },
        password: { value: '', isDirty: false },
    });

    const [{ data: loginData, loading: loadingLogin }, login] = useAxios({ url: '/auth/login', method: 'post' }, { manual: true, useCache: false });
    const [{ data: googleLoginData, loading: loadingGoogleLoginData }, googleLogin] = useAxios({ url: '/auth/login/google', method: 'post' }, { manual: true, useCache: false });

    useEffect(() => {
        const body = document.querySelector('body');

        body.classList.add('account-body');
        body.classList.add('accountbg');
    }, []);

    useEffect(() => {
        if (loginData) {
            const body = document.querySelector('body');

            body.classList.remove('account-body');
            body.classList.remove('accountbg');
            if (searchParams?.get('redirectTo')) {
                navigate(searchParams?.get('redirectTo'));
            }
            setAuthInfo({ user: loginData.data, token: loginData.token });
        }

        if (googleLoginData) {
            const body = document.querySelector('body');
            body.classList.remove('account-body');
            body.classList.remove('accountbg');
            if (searchParams?.get('redirectTo')) {
                navigate(searchParams?.get('redirectTo'));
            }
            setAuthInfo({ user: googleLoginData.data, token: googleLoginData.token });
        }
    }, [loginData, googleLoginData]);

    useEffect(() => {
        setLoading({ message: 'Iniciando sesión', show: loadingLogin });
    }, [loadingLogin, setLoading]);

    useEffect(() => {
        setLoading({ message: 'Iniciando sesión', show: loadingGoogleLoginData });
    }, [loadingGoogleLoginData, setLoading]);

    const handleChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: { value: e.target.value, isDirty: true },
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log(searchParams?.get('redirectTo'));

        login({
            data: {
                email: formData.email.value,
                password: formData.password.value,
            }
        });
    }

    const handleGoogleResponse = (response) => {
        googleLogin({
            data: {
                accessToken: response.accessToken,
            }
        });
    }

    const handleGoggleError = (error) => {
        console.log(error);
    }

    return <div className="container">
        <div className="row vh-100 ">
            <div className="col-12 align-self-center">
                <div className="auth-page">
                    <div className="card auth-card shadow-lg">
                        <div className="card-body">
                            <div className="px-3">
                                <div className="text-center auth-logo-text">
                                    <h4 className="mt-0 mb-3 mt-5">Iniciar sesión</h4>
                                </div>
                                {
                                    searchParams?.get('message') &&
                                    <p className="m-0 mb-1 text-center text-danger">
                                        {searchParams?.get('message')}
                                    </p>
                                }
                                <form className="form-horizontal auth-form my-4" onSubmit={handleSubmit}>

                                    <div className="form-group">
                                        <label >Correo electrónico</label>
                                        <div className="input-group mb-3">
                                            <span className="auth-form-icon">
                                                <i className="dripicons-user"></i>
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="email"
                                                placeholder="Ingrese el correo"
                                                onChange={handleChange}
                                                value={formData.email.value}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="password">Contraseña</label>
                                        <div className="input-group mb-3">
                                            <span className="auth-form-icon">
                                                <i className="dripicons-lock"></i>
                                            </span>
                                            <input
                                                type="password"
                                                className='form-control'
                                                id="password"
                                                name="password"
                                                placeholder="Ingrese la contraseña"
                                                onChange={handleChange}
                                                value={formData.password.value}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row mt-4">
                                        <div className="col-sm-12 text-right">
                                            <a href="/olvido-de-contrasena" className="text-muted font-13"><i className="dripicons-lock"></i> Olvide mi contraseña</a>
                                        </div>
                                    </div>

                                    <div className="form-group mb-0 row">
                                        <div className="col-12 mt-2">
                                            <button className="btn btn-gradient-primary btn-round btn-block waves-effect waves-light">Ingresar <i className="fas fa-sign-in-alt ml-1"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="account-social text-center mt-4">
                        <h6 className="my-4">O iniciar sesión con</h6>
                        <ul className="list-inline mb-4">
                            <li className="list-inline-item">
                                <GoogleLogin
                                    clientId={env('GOOGLE_CLIENT_ID')}
                                    onSuccess={handleGoogleResponse}
                                    onFailure={handleGoggleError}
                                    cookiePolicy={'single_host_origin'}
                                    render={renderProps => <button onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                        <i className="fab fa-google google"></i>
                                    </button>}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Login;