const swal = (...args) => {
    return window.swal.fire(...args);
};

export const confirm = (args = {}) => {
    return swal({
        icon: 'question',
        confirmButtonText: 'Si',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        ...args
    });
}

export default swal;