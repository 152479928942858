import { useEffect, useState } from "react";
import { useFeedBack } from "../../../contexts/FeedBackContext";
import useUserEmployees from "../../../hooks/useUserEmployees";
import Table from "../../../components/Table";
import employeesColumns from "../../../tableColumns/employeesColumns";



const BossEmployees = () => {

    const { setCustomAlert, setLoading } = useFeedBack();

    const [filters, setFilters] = useState({
        page: 1,
        search: ''
    })

    const [{ userEmployees: employees, total, numberOfPages, error: employeesError, loading: employeesLoading }, getEmployees] = useUserEmployees({ axiosConfig: { params: { ...filters } }, options: { useCache: false } });

    useEffect(() => {

        if (employeesError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los registros.',
                show: true
            });
        }
    }, [employeesError]);

    const handlePageChange = (page) => {
        if (page > 0) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    return (
        <div>
            <Table
                title="Mis Empleados"
                columns={employeesColumns({})}
                onSearchChange={(e) => handleChange({ target: { value: e?.target.value, name: 'search' } })}
                searchValue={filters?.search}
                excelUrl={'/my-account/employees/export/excel'}
                excelFileName='vacaciones.xlsx'
                items={employees}
                total={total}
                currentPage={filters.page}
                pages={numberOfPages}
                loading={employeesLoading}
                onChangePage={handlePageChange}
                perPageValue={filters?.perPage}
                onChagePerPage={(e) => {
                    setFilters((oldFilters) => {
                        return {
                            ...oldFilters,
                            perPage: e.target.value,
                            page: 1
                        }
                    })
                }}
            />
        </div>
    )
}

export default BossEmployees;