import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import externalLinks from "../utils/externalLinks";


const ExternalLinks = () => {
    const [searchParams] = useSearchParams();

    const [modules, setModules] = useState([]);

    useEffect(() => {
        const linkName = searchParams?.get('linkName');
        if (linkName) {
            setModules(externalLinks[linkName]);
        }
    }, [searchParams])

    return (
        <div>
            <h3>App Externos</h3>
            <div className="row">
                {
                    modules?.map((linkExtern, i) => {
                        return (
                            <div className="col-md-4" style={{ cursor: 'pointer' }}>
                                <a href={linkExtern?.url} target="_blank">
                                    <div className="card">
                                        <img style={{ maxWidth: '100%', height: '200px' }} src={linkExtern?.image} alt="" />
                                        <h3 className="text-center">
                                            {linkExtern?.name}
                                        </h3>
                                    </div>
                                </a>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ExternalLinks;