import { useState } from "react";
import { useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import useUserNotifications from "../../hooks/useUserNotifications";
import NotificationRow from "./NotificationRow";

const NotificationContainer = () => {

    const [notificationsFilters, setNotificationsFilters] = useState({
        perPage: 10,
        page: 1
    })

    const [{ data: notificationsCountData, loading: loadingNotificationCount }, getCount] = useAxios({ url: `my-account/notifications/not-read/count` }, { useCache: false });

    const [{ userNotifications, numberOfPages, loading }, getUserNotifications] = useUserNotifications({ axiosConfig: { params: { ...notificationsFilters } }, options: { useCache: false } });

    const [currentNotifications, setCurrentNotifications] = useState([]);

    const [notificationsCount, setNotificationsCount] = useState(0);

    useEffect(() => {
        if (notificationsCountData) {
            setNotificationsCount(notificationsCountData)
        }
    }, [notificationsCountData])

    useEffect(() => {
        if (userNotifications?.length > 0) {
            setCurrentNotifications((oldCurrentNotifications) => {
                return [...oldCurrentNotifications, ...userNotifications]
            });
        }
    }, [userNotifications])

    const handleLoadMore = (e) => {
        e.preventDefault();
        if (notificationsFilters?.page < numberOfPages) {
            setNotificationsFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: oldFilters?.page + 1
                }
            });
        }
    }

    const handleRead = () => {
        setNotificationsCount((oldNotificationsCount) => {
            return oldNotificationsCount - 1;
        })
    }

    return (
        <li className="dropdown notification-list">
            <a className="nav-link dropdown-toggle arrow-none waves-light waves-effect" data-toggle="dropdown" href="/#" role="button"
                aria-haspopup="false" aria-expanded="false">
                <i className="ti-bell noti-icon"></i>
                <span className="badge badge-danger badge-pill noti-icon-badge">

                    {
                        loadingNotificationCount ?
                            <div className="spinner-border text-light" role="status" style={{ width: 10, height: 10 }}></div>
                            :
                            notificationsCount
                    }
                </span>
            </a>
            <div className="dropdown-menu dropdown-menu-right dropdown-lg pt-0">
                <h6 className="dropdown-item-text font-15 m-0 py-3 bg-primary text-white d-flex justify-content-between align-items-center">
                    Notificaciones <span className="badge badge-light badge-pill">{notificationsCount}</span>
                </h6>
                <div
                    style={{
                        overflowY: 'auto',
                        maxHeight: '70vh'
                    }}
                >
                    {
                        currentNotifications?.length > 0 ?
                            currentNotifications?.map((notification, i) => {
                                return (
                                    <NotificationRow onReadNotification={handleRead} notification={notification} key={i} />
                                )
                            })
                            :
                            <p className="text-center py-5">
                                No se encontraron notificaciones nuevas.
                            </p>
                    }
                </div>
                {
                    loading ?
                        <div className="text-center">
                            <div className="spinner-border thumb-sm text-primary" role="status" style={{ width: 30, height: 30 }}></div>
                        </div>
                        :
                        numberOfPages > notificationsFilters?.page ?
                            < button disabled={notificationsFilters?.page >= numberOfPages} onClick={handleLoadMore} className="dropdown-item text-center text-primary">
                                Ver más <i className="fi-arrow-right"></i>
                            </button>
                            :
                            null
                }
            </div>
        </li >
    )
}

export default NotificationContainer;