import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ImgUploadInput from "../../components/ImgUploadInput";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";
import useContractTypes from "../../hooks/useContractTypes";
import useCostCenters from "../../hooks/useCostCenters";
import useDocumentNumberTypes from "../../hooks/useDocumentNumberTypes";
import useOffices from "../../hooks/useOffices";
import usePositions from "../../hooks/usePositions";
import useRoles from "../../hooks/useRoles";
import useServices from "../../hooks/useServices";
import useSystems from "../../hooks/useSystems";
import useUsers from "../../hooks/useUsers";
import AsyncSelect from 'react-select/async';
import mapValues from "../../utils/mapValues";
import handleLoadSelectOptions from "../../utils/loadSelectValues";

const UsersCreate = () => {

    const navigate = useNavigate();

    const { setCustomAlert, setLoading } = useFeedBack();

    const filters = {
        perPage: 200,
        page: 1
    };

    const [firstLoading, setFirstLoading] = useState(true);

    const [currentServices, setCurrentServices] = useState([]);

    const [data, setData] = useState({
        name: '',
        email: '',
        password: '',
        address: '',
        image: null,
        phoneNumber: '',
        positionId: '',
        boss_id: '',
        private: 'no',
        days: '',
        documentNumber: '',
        documentNumberTypeId: 1,
        roles: [],
        serviceIds: [],
        systems: [],
        costCenterId: '',
        contractTypeId: '',
        officeId: '',
        serviceId: '',
        sapCode: '',
        admissionDate: '',
        birthdayDate: ''
    });

    const [{ data: createData, loading: createLoading }, createUser] = useAxios({ url: `/users`, method: 'POST' }, { manual: true, useCache: false });

    const [{ users, loading: usersLoading }, getUsers] = useUsers({ options: { manual: true, useCache: false } });

    const [{ positions, loading: positionsLoading }, getPositions] = usePositions({ options: { manual: true, useCache: false } });

    const [{ contractTypes, loading: contractTypesLoading }, getContractTypes] = useContractTypes({ options: { useCache: false, manual: true } });

    const [{ costCenters, loading: costCentersLoading }, getCostCenters] = useCostCenters({ options: { manual: true, useCache: false } });

    const [{ offices, loading: officesLoading }, getOffices] = useOffices({ options: { useCache: false, manual: true } });

    const [{ documentNumberTypes, error: documentNumberTypesError, loading: documentNumberTypesLoading }] = useDocumentNumberTypes({ options: { useCache: false } });

    const [{ roles, error: rolesError, loading: rolesLoading }] = useRoles({ axiosConfig: { params: { ...filters, systemCodes: data.systems } }, options: { useCache: false } });

    const [{ services, error: servicesError, loading: servicesLoading }] = useServices({ axiosConfig: { params: { ...filters, orderBy: 'name ASC' } }, options: { useCache: false } });

    const [{ services: userServices, loading: userServicesLoading }, getServices] = useServices({ options: { useCache: false } });

    const [{ systems, error: systemsError, loading: systemsLoading }] = useSystems({ options: { useCache: false } });

    useEffect(() => {
        if (
            !documentNumberTypesLoading &&
            !rolesLoading &&
            !servicesLoading &&
            !systemsLoading
        ) {
            setFirstLoading(false);
        } else {
            setFirstLoading(true)
        }
    }, [documentNumberTypesLoading, rolesLoading, servicesLoading, systemsLoading]);

    useEffect(() => {
        setLoading({
            show: firstLoading,
            message: 'Obteniendo informacion'
        });
    }, [firstLoading]);

    useEffect(() => {
        if (services?.length > 0) {
            setCurrentServices(services);
        }
    }, [services]);

    useEffect(() => {
        if (data.systems.length === 0) {
            setData(prevData => ({
                ...prevData,
                roles: [],
            }))
        }
    }, [data.systems]);

    useEffect(() => {
        if (createData) {
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'El usuario fue creado exitosamente.',
                show: true
            });
            navigate('/usuarios');
        }
    }, [createData])

    useEffect(() => {

        if (documentNumberTypesError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los tipos de documentos.',
                show: true
            });
        }

        if (rolesError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los roles.',
                show: true
            });
        }

        if (servicesError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los servicios.',
                show: true
            });
        }

        if (systemsError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los sistemas.',
                show: true
            });
        }
    }, [documentNumberTypesError, rolesError, servicesError, systemsError])

    const handleSubmit = (e) => {
        let hasError = false;
        e?.preventDefault?.();

        if (createLoading) {
            return;
        }

        const formData = new FormData();

        const { image, serviceIds, systems, roles, ...rest } = data;

        Object.keys(rest).forEach((key) => {
            if (data[key]) {
                formData?.append(key, data[key]);
            }
        });

        serviceIds?.forEach?.((serviceId, i) => {
            formData?.append(`serviceIds[${i}]`, serviceId);
        });

        systems?.forEach?.((system, i) => {
            formData?.append(`systems[${i}]`, system);
        });

        roles?.forEach?.((role, i) => {
            formData?.append(`roles[${i}]`, role);
        });

        if (image) {
            formData?.append('image', image, image?.name);
        }

        createUser({ data: formData });
    }

    const handleChange = (e) => {

        if (e.target.name === 'documentNumber' && e.target.value.length > 1) {

            var value = e.target.value.replace('-', '');

            const newValue = `${value?.slice(0, -1)}-${value?.substr(value.length - 1)}`;

            e.target.value = newValue;
        }

        if (e.target.type === 'checkbox') {
            const value = data[e.target.name]?.includes(e.target.value);
            if (value) {
                const newValues = data[e.target.name]?.filter(n => n !== e.target.value);
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: newValues
                    }
                });
            } else {
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: [...data[e.target.name], e.target.value]
                    }
                });
            }
            return;
        }


        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    const checker = (arr, target) => arr.every((value) => target?.includes(value));

    const handleAllServices = () => {
        let hash = {};
        if (checker(services?.map(service => service?.id), data?.serviceIds)) {
            services?.forEach((service) => {
                hash[service?.id] = true;
            });
            setData((oldData) => {
                return {
                    ...oldData,
                    serviceIds: data?.serviceIds?.filter((serviceId) => !hash[serviceId])
                }
            });
        } else {
            services.forEach((service) => {
                hash[service?.id] = true;
            });

            let oldServicesIds = data?.serviceIds?.filter((serviceId) => !hash[serviceId]);

            setData((oldData) => {
                return {
                    ...oldData,
                    serviceIds: [...oldServicesIds, ...services?.map((service) => service?.id)]
                }
            });

        }
    }

    const filterServices = (e) => {
        const filterServices = services?.filter((service) => {
            return (service?.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
        });

        setCurrentServices(filterServices);
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Crear usuario</h4>
                </div>
                <div className="card-body">
                    <div className="basic-form">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group col-1 mb-3">
                                <ImgUploadInput description="imagen" name="image" change={handleChange} style={{ height: 80, width: 80 }} />
                            </div>
                            <div className="row mb-5">
                                <div className="form-group mb-3 col-md-4">
                                    <label>Nombre <span className="text-danger">*</span></label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Nombre"
                                        name="name"
                                        value={data?.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label htmlFor="admissionDate">Fecha de Ingreso <span className="text-danger">*</span></label>
                                    <input
                                        required
                                        type="date"
                                        className="form-control"
                                        name="admissionDate"
                                        value={data?.admissionDate}
                                        id="admissionDate"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label htmlFor="birthdayDate">Fecha de Nacimiento <span className="text-danger">*</span></label>
                                    <input
                                        required
                                        type="date"
                                        className="form-control"
                                        name="birthdayDate"
                                        value={data?.birthdayDate}
                                        id="birthdayDate"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Email <span className="text-danger">*</span></label>
                                    <input
                                        required
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        value={data?.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Contraseña <span className="text-danger">*</span></label>
                                    <input
                                        required
                                        type="password"
                                        className="form-control"
                                        placeholder="Contraseña"
                                        name="password"
                                        value={data?.password}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Número de teléfono<span className="text-danger">*</span></label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Ingrese un numero telefonico"
                                        name="phoneNumber"
                                        value={data?.phoneNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Número de documento<span className="text-danger">*</span></label>
                                    <div className="d-flex">
                                        <select
                                            className="form-control w-25"
                                            name="documentNumberTypeId"
                                            value={data?.documentNumberTypeId}
                                            onChange={handleChange}
                                        >
                                            <option value="">
                                                Tipo...
                                            </option>
                                            {
                                                documentNumberTypes?.map?.((type, i) => {
                                                    return (
                                                        <option value={type?.id} key={i}>
                                                            {type?.name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <input
                                            type="text"
                                            className="form-control "
                                            placeholder="Ej: 26629346"
                                            name="documentNumber"
                                            value={data?.documentNumber}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Días de vacaciones disponibles: <span className="text-danger">*</span></label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="dias de vacaciones..."
                                        name="days"
                                        value={data?.days}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Código Sap <span className="text-danger">*</span></label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Ingrese el codigo sap"
                                        name="sapCode"
                                        value={data?.sapCode}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Servicio al cual pertenece</label>
                                    <AsyncSelect
                                        isClearable
                                        onFocus={() => {
                                            getServices();
                                        }}
                                        defaultOptions={mapValues(userServices)}
                                        isLoading={userServicesLoading}
                                        loadOptions={(e) => handleLoadSelectOptions(e, getServices)}
                                        placeholder='Escriba el nombre para buscar...'
                                        onChange={(e) => { handleChange({ target: { value: e?.value, name: 'serviceId' } }) }}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Privado</label>
                                    <select className="form-control" name="private" id="" onChange={handleChange} value={data?.private}>
                                        <option value="si">Si</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                                <div className="form-group mb-3 col-md-12">
                                    <label htmlFor="address">Dirección <span className="text-danger">*</span></label>
                                    <textarea
                                        required
                                        className="form-control"
                                        rows="5"
                                        placeholder="Ingrese una direccion"
                                        id="address"
                                        name="address"
                                        value={data?.address}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Cargo<span className="text-danger">*</span></label>
                                    <AsyncSelect
                                        isClearable
                                        onFocus={() => {
                                            getPositions();
                                        }}
                                        defaultOptions={mapValues(positions)}
                                        isLoading={positionsLoading}
                                        loadOptions={(e) => handleLoadSelectOptions(e, getPositions)}
                                        placeholder='Escriba el nombre para buscar...'
                                        onChange={(e) => { handleChange({ target: { value: e?.value, name: 'positionId' } }) }}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Jefe directo</label>
                                    <AsyncSelect
                                        isClearable
                                        onFocus={() => {
                                            getUsers();
                                        }}
                                        defaultOptions={mapValues(users)}
                                        isLoading={usersLoading}
                                        loadOptions={(e) => handleLoadSelectOptions(e, getUsers)}
                                        placeholder='Escriba el nombre para buscar...'
                                        onChange={(e) => { handleChange({ target: { value: e?.value, name: 'boss_id' } }) }}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Oficina</label>
                                    <AsyncSelect
                                        isClearable
                                        onFocus={() => {
                                            getOffices();
                                        }}
                                        defaultOptions={mapValues(offices)}
                                        isLoading={officesLoading}
                                        loadOptions={(e) => handleLoadSelectOptions(e, getOffices)}
                                        placeholder='Escriba el nombre para buscar...'
                                        onChange={(e) => { handleChange({ target: { value: e?.value, name: 'officeId' } }) }}
                                    />
                                </div>

                                <div className="form-group mb-3 col-md-6">
                                    <label>Centro de costos</label>
                                    <AsyncSelect
                                        isClearable
                                        onFocus={() => {
                                            getCostCenters();
                                        }}
                                        defaultOptions={mapValues(costCenters)}
                                        isLoading={costCentersLoading}
                                        loadOptions={(e) => handleLoadSelectOptions(e, getCostCenters)}
                                        placeholder='Escriba el nombre para buscar...'
                                        onChange={(e) => { handleChange({ target: { value: e?.value, name: 'costCenterId' } }) }}
                                    />
                                </div>

                                <div className="form-group mb-3 col-md-6">
                                    <label>Tipo de contrato</label>
                                    <AsyncSelect
                                        isClearable
                                        onFocus={() => {
                                            getContractTypes();
                                        }}
                                        defaultOptions={mapValues(contractTypes)}
                                        isLoading={contractTypesLoading}
                                        loadOptions={(e) => handleLoadSelectOptions(e, getContractTypes)}
                                        placeholder='Escriba el nombre para buscar...'
                                        onChange={(e) => { handleChange({ target: { value: e?.value, name: 'contractTypeId' } }) }}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-12">
                                    <div className="mb-1">
                                        <h6>Servicios</h6>
                                        <input type="text" onChange={filterServices} className="form-control" placeholder="Buscar servicio..." />
                                        <small>Seleccione los servicios a los cuales tendra acceso el usuario.</small>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 my-2">
                                            <div className="form-check-inline">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="serviceIds"
                                                        checked={checker(services?.map(service => service.id), data?.serviceIds)}
                                                        onChange={handleAllServices}
                                                    />
                                                    <label className="custom-control-label" htmlFor="serviceIds">Seleccionar todos</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                currentServices?.map((service) => {
                                                    const inputId = `service-${service.id}`;

                                                    return <div className="col-md-3 my-2" key={service.id}>
                                                        <div className="form-check-inline">
                                                            <div className="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    value={service.id}
                                                                    id={inputId}
                                                                    checked={data?.serviceIds?.includes(service?.id)}
                                                                    onChange={() => { handleChange({ target: { name: 'serviceIds', value: service.id, type: 'checkbox' } }) }}
                                                                />
                                                                <label className="custom-control-label" htmlFor={inputId}>{service.name}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group mb-3 col-md-12">
                                    <div className="mb-1">
                                        <h6>Sistemas</h6>
                                        <small>Seleccione los sistemas a los cuales tendra acceso el usuario.</small>
                                    </div>

                                    {systems?.map(system => {
                                        return <div key={system.code} className="form-check-inline">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    value={system.code}
                                                    id={`system-${system.code}`}
                                                    checked={data?.systems?.includes(system.code)}
                                                    onChange={() => { handleChange({ target: { name: 'systems', value: system.code, type: 'checkbox' } }) }}
                                                />
                                                <label className="custom-control-label" style={{ textTransform: 'capitalize' }} htmlFor={`system-${system.code}`}>{system.name}</label>
                                            </div>
                                        </div>
                                    })}
                                </div>

                                <div className="form-group mb-3 col-md-12">
                                    <h6>Roles</h6>
                                    <div className="row">
                                        {data.systems.length > 0
                                            ? roles?.map(role => {
                                                const inputId = `system-${role.name}-${role.systemCode}`;
                                                return <div key={role.name} className="col-md-3">
                                                    <div className="form-check-inline">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                value={role.name}
                                                                id={inputId}
                                                                checked={data?.roles?.includes(role.name)}
                                                                onChange={() => { handleChange({ target: { name: 'roles', value: role.name, type: 'checkbox' } }) }}
                                                            />
                                                            <label className="custom-control-label" htmlFor={inputId}>{role.displayText} (<small>{role?.system?.name}</small>)</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                            : <small className="col-md-4">Seleccione al menos un sistema</small>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 d-flex justify-content-end">
                                <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                    Cancelar
                                </Link>
                                <button disabled={createLoading} type="submit" className="btn btn-primary mx-2">
                                    {createLoading ? 'Cargando' : 'Crear'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    )
}
export default UsersCreate;