import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";
import AsyncSelect from 'react-select/async';
import useCommunes from "../../hooks/useCommunes";
import handleLoadSelectOptions from "../../utils/loadSelectValues";
import mapValues from "../../utils/mapValues";

const HousesUpdate = () => {

    const { id } = useParams();

    const navigate = useNavigate();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [{ data: record, loading: recordLoading }] = useAxios({ url: `/houses/${id}` }, { useCache: false });

    const [{ data: updateData, loading: updateLoading }, updateRecord] = useAxios({ url: `/houses/${id}`, method: 'PUT' }, { manual: true, useCache: false });

    const [{ communes, total, numberOfPages, size, error, loading: communesLoading }, getCommunes] = useCommunes({
        options: {
            useCache: false
        }
    });

    const [data, setData] = useState({
        name: '',
        commune: ''
    });

    useEffect(() => {
        setLoading({
            show: recordLoading,
            message: 'Obteniendo datos',
        });
    }, [recordLoading]);

    useEffect(() => {
        setLoading({
            show: updateLoading,
            message: 'Actualizando registro',
        });
    }, [updateLoading]);

    useEffect(() => {
        if (updateData) {
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'El registro fue actualizado exitosamente.',
                show: true
            });
            navigate('/casas-csi');
        }
    }, [updateData]);

    useEffect(() => {
        if (record) {
            setData(prevData => ({
                ...prevData,
                name: record?.data?.name,
                commune: record?.data?.commune ? mapValues([record?.data?.commune])[0] : '',
            }));
        }
    }, [record]);

    const handleChange = (e) => {
        setData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        updateRecord({
            data: {
                name: data?.name,
                commune_id: data?.commune?.value
            }
        });
    }

    return <>
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">Actualizar Casa Csi</h4>
            </div>

            <div className="card-body">
                <div className="basic-form">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-5">
                            <div className="form-group mb-3 col-md-6">
                                <label>Nombre</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nombre"
                                    name="name"
                                    value={data.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Comuna</label>
                                <AsyncSelect
                                    onFocus={() => {
                                        getCommunes();
                                    }}
                                    defaultOptions={mapValues(communes)}
                                    value={data?.commune}
                                    isLoading={communesLoading}
                                    loadOptions={(e) => handleLoadSelectOptions(e, getCommunes)}
                                    placeholder='Escriba el nombre para buscar...'
                                    onChange={(e) => { handleChange({ target: { value: e, name: 'commune' } }) }}
                                />
                            </div>
                        </div>

                        <div className="mb-3 d-flex justify-content-end">
                            <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                Cancelar
                            </Link>
                            <button disabled={updateLoading} type="submit" className="btn btn-primary mx-2">
                                {updateLoading ? 'Cargando' : 'Actualizar'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}

export default HousesUpdate;