import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";

const ZonesCreate = () => {
    const navigate = useNavigate();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [data, setData] = useState({
        name: '',
    });

    const [{ data: createData, loading: createLoading }, createZone] = useAxios({ url: '/zones', method: 'POST' }, { manual: true });

    useEffect(() => {
        setLoading({
            show: createLoading,
            message: 'Creando zona',
        });
    }, [createLoading]);

    useEffect(() => {
        if (createData) {
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'La zona fue creado exitosamente.',
                show: true
            });
            navigate('/zonas');
        }
    }, [createData]);

    const handleChange = (e) => {
        setData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        createZone({ data });
    }

    return <>
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">Crear Zona</h4>
            </div>

            <div className="card-body">
                <div className="basic-form">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-5">
                            <div className="form-group mb-3 col-md-12">
                                <label>Nombre</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nombre"
                                    name="name"
                                    value={data.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="mb-3 d-flex justify-content-end">
                            <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                Cancelar
                            </Link>
                            <button disabled={createLoading} type="submit" className="btn btn-primary mx-2">
                                {createLoading ? 'Cargando' : 'Crear'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}

export default ZonesCreate;