import env from "./env";
import logo from "../assets/img/logo.png";

const SystemInfo = {
    name: "Csi Usuarios",
    description: "Sistema multiplataforma.",
    logo: logo,
    host: env('HOST_URL'),
    api: env('API_URL'),
    webDoxcsApi: env('WEBDOCX_API_URL'),
    ordersLoginUrl: env('ORDERS_LOGIN_URL'),
    systemCode: 'sys-001',
}

export default SystemInfo;