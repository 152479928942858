import DateFormatter from '../components/DateFormatter';
import ActionsDropdown from '../components/ActionsDropdown';

const rolesColumns = ({ onDelete }) => [
    {
        headerName: 'ID',
        field: 'id'
    },
    {
        headerName: 'Nombre',
        component: ({ item }) => item?.displayText || '--'
    },
    {
        headerName: 'Sistema',
        component: ({ item }) => <p style={{ textTransform: 'capitalize' }}>{item?.system?.name}</p> || '--'
    },
    {
        headerName: 'Fecha de creación',
        component: ({ item }) => <DateFormatter date={item.createdAt} />
    },
    {
        headerName: 'Acciones',
        component: ({ item }) => <ActionsDropdown
            updatePath={`/roles/${item.id}`}
            onDelete={onDelete}
            showDelete={item.canBeDeleted}
            showUpdate={item.canBeUpdated}
            dataForOnDelete={item.id}
            deleteAlertTitle="Eliminar Rol"
            deleteAlertConfirmBtnText="Si, ¡Eliminarlo!"
        />
    },
]

export default rolesColumns;

