import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";

const PositionsUpdate = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [{ data: position, loading: positionLoading }] = useAxios({ url: `/positions/${id}` }, { useCache: false });

    const [{ data: updateData, loading: updateLoading }, updatePosition] = useAxios({ url: `/positions/${id}`, method: 'PUT' }, { manual: true, useCache: false });

    const [data, setData] = useState({ name: '' });

    useEffect(() => {
        setLoading({
            show: positionLoading,
            message: 'Cargando cargo',
        });
    }, [positionLoading]);

    useEffect(() => {
        setLoading({
            show: updateLoading,
            message: 'Actualizando cargo',
        });
    }, [updateLoading]);

    useEffect(() => {
        if (updateData) {
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'El cargo fue actualizado exitosamente.',
                show: true
            });
            navigate('/cargos');
        }
    }, [updateData]);

    useEffect(() => {
        if (position) {
            setData(prevData => ({
                ...prevData,
                name: position?.data?.name,
            }));
        }
    }, [position]);

    const handleChange = (e) => {
        setData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        updatePosition({ data });
    }

    return <>
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">Actualizar cargo</h4>
            </div>

            <div className="card-body">
                <div className="basic-form">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-5">
                            <div className="form-group mb-3 col-md-12">
                                <label>Nombre</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nombre"
                                    name="name"
                                    value={data.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="mb-3 d-flex justify-content-end">
                            <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                Cancelar
                            </Link>
                            <button disabled={updateLoading} type="submit" className="btn btn-primary mx-2">
                                {updateLoading ? 'Cargando' : 'Crear'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}

export default PositionsUpdate;