import { toast } from "react-toastify";
import { Field, FieldArray, Form, Formik } from "formik";
import axios from "axios";
import Card from "../../components/Card";
import Input from "../../components/Forms/Input";
import Select from "../../components/Forms/Select";
import Button from "../../components/Forms/Button";
import useSystems from "../../hooks/useSystems";
import { crudPermissions, formSchema } from "./permissionsData";
import SystemInfo from "../../utils/SystemInfo";
import swal from "../../utils/swal";
import TemporaryCodeButton from "./TemporaryCodeButton";

const { api: apiUrl } = SystemInfo;

const validationErrorsCatcher = (setFieldError) => (e) => {
    if (e?.response?.status !== 422) {
        throw e;
    }

    const errors = e.response.data.errors;

    for (const key of Object.keys(errors)) {
        setFieldError(key, errors[key][0]);
    }
}

const generalErrorCatcher = (e) => {
    swal({
        title: "Ha ocurrido un error!",
        text: e?.response?.data?.message,
        icon: 'error',
    })
}

const handleSubmit = async (values, { resetForm, setFieldError }) => {
    const { dismiss, value: code } = await swal({
        title: 'Código',
        input: 'text',
        inputAttributes: {
            required: 'true'
        },
        confirmButtonText: 'Aceptar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        preConfirm: (code) => code || false,
        validationMessage: 'Ingrese el código.',
    });

    if (dismiss === 'cancel') {
        return;
    }

    const { data } = await axios
        .post(`${apiUrl}permissions`, {
            ...values,
            code,
        })
        .catch(validationErrorsCatcher(setFieldError))
        .catch(generalErrorCatcher);

    if (!data) {
        return;
    }

    resetForm();

    toast.success("Permisos agregados")
}

const Permissions = () => {
    const [{ systems }] = useSystems();

    return (
        <div className="container py-4">
            <Card title={"Asignación de permisos"}>
                <Formik
                    initialValues={{
                        name: '',
                        displayText: '',
                        guard: '',
                        systemCode: '',
                        permissions: [],
                    }}
                    validationSchema={formSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, touched, errors, isSubmitting }) => <Form noValidate>
                        <h5>Sistema</h5>

                        <Field
                            label={'Sistema'}
                            name={'systemCode'}
                            component={Select}
                        >
                            <option value="" disabled>Selecciona una opción</option>
                            {systems.map(system => <option key={system.code} value={system.code}>{system.name}</option>)}
                        </Field>

                        <h5>Módulo</h5>

                        <div className="row">
                            <div className="col-md-4">
                                <Field
                                    label={'Nombre en pantalla'}
                                    name={'displayText'}
                                    placeholder="Nombre del modulo"
                                    component={Input}
                                />
                            </div>

                            <div className="col-md-4">
                                <Field
                                    label={'Nombre'}
                                    name={'name'}
                                    placeholder="nombre-del-modulo"
                                    component={Input}
                                />
                            </div>

                            <div className="col-md-4">
                                <Field
                                    label={'Guard'}
                                    name={'guard'}
                                    component={Select}
                                >
                                    <option value="" disabled>Selecciona una opción</option>
                                    {['sanctum', 'web', 'admin'].map(guard => <option key={guard} value={guard}>{guard}</option>)}
                                </Field>
                            </div>
                        </div>

                        <h5>Permisos</h5>

                        {(touched.permissions && typeof errors.permissions === 'string') && (
                            <div className="mb-2"><small className="text-danger">{errors.permissions}</small></div>
                        )}

                        <FieldArray name="permissions">
                            {({ push, remove }) => <>
                                {values.permissions.length > 0 && (
                                    <div className="row mb-2">
                                        <div className="col-md-5">Nombre</div>
                                        <div className="col-md-5">Nombre en pantalla</div>
                                        <div className="col">Acciones</div>
                                    </div>
                                )}

                                {values.permissions.map((permission, index) => (
                                    <div key={index} className="row">
                                        <div className="col-md-5">
                                            <Field
                                                name={`permissions.${index}.displayText`}
                                                placeholder="Nombre del permiso"
                                                component={Input}
                                            />
                                        </div>
                                        <div className="col-md-5">
                                            <Field
                                                name={`permissions.${index}.name`}
                                                placeholder="nombre-del-permiso"
                                                component={Input}
                                            />
                                        </div>
                                        <div className="col">
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-round"
                                                onClick={() => remove(index)}
                                            >
                                                <i className="far fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                ))}

                                <Button
                                    color="success"
                                    size="sm"
                                    leftIconName="mdi mdi-plus"
                                    className="mr-2"
                                    onClick={() => push({ name: '', displayText: '' })}
                                >
                                    Agregar permiso
                                </Button>

                                <Button
                                    outline
                                    color="success"
                                    size="sm"
                                    leftIconName="mdi mdi-creation"
                                    onClick={() => crudPermissions.forEach(push)}
                                >
                                    Agregar permisos de crud
                                </Button>
                            </>}
                        </FieldArray>

                        <div className="mb-3 d-flex justify-content-end">
                            <TemporaryCodeButton />

                            <Button
                                disabled={isSubmitting}
                                type="submit"
                                color="primary"
                                leftIconName="mdi mdi-content-save-outline"
                            >
                                {isSubmitting ? 'Cargando' : 'Guardar'}
                            </Button>
                        </div>
                    </Form>}
                </Formik>
            </Card>
        </div>
    )
}

export default Permissions;