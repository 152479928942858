import clsx from "clsx";
import { getIn } from "formik";

const Select = ({
    label,
    id,
    children,
    field = {},
    form = {},
    ...props
}) => {
    const { touched = {}, errors = {} } = form;

    const isTouched = getIn(touched, field.name);

    const error = getIn(errors, field.name);

    const showError = isTouched && typeof error === 'string';

    return (
        <div className="form-group">
            {label && <label htmlFor={id}>{label}</label>}

            <select
                className={clsx("custom-select", showError && 'is-invalid')}
                id={id}
                {...field}
                {...props}
            >
                {children}
            </select>

            {showError && <div className="invalid-feedback">{error}</div>}
        </div >
    );
}

export default Select;