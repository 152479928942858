const Pagination = ({
    currentPage = 1,
    pages = 1,
    onChangePage,
    className,
    style
}) => {
    const handlePrevPageClick = () => {
        if (currentPage > 0) {
            onChangePage?.(currentPage - 1)
        }
    }

    const handleNextPageClick = () => {
        if (currentPage < pages) {
            onChangePage?.(currentPage + 1)
        }
    }

    return <nav aria-label="..." className={className} style={style}>
        <ul className="pagination mb-0 custom-scrollbar-horizontal scrollbar-primary" style={{ overflowX: 'auto' }}>
            <li className={`page-item ${currentPage <= 1 ? 'disabled' : ''}`} style={{ width: '15%' }}>
                <button
                    type="button"
                    className="page-link w-100"
                    tabIndex="-1"
                    onClick={handlePrevPageClick}
                    disabled={currentPage <= 1}
                >
                    <i class="fas fa-chevron-left"></i>
                </button>
            </li>
            <li style={{ width: '70%' }}>
                <ul className="d-flex w-100 custom-scrollbar-horizontal scrollbar-primary" style={{ listStyle: 'none', paddingLeft: 0, overflowX: 'auto' }}>
                    {[...Array(pages + 1).keys()].slice(1).map((n, i) => <li
                        key={n}
                        className={`page-item ${currentPage === (i + 1) ? 'active' : ''}`}
                    >
                        <button
                            type="button"
                            className="page-link"
                            onClick={() => onChangePage?.(n)}
                        >{n}</button>
                    </li>)}
                </ul>
            </li>

            <li className={`page-item ${currentPage >= pages ? 'disabled' : ''}`} style={{ width: '15%' }}>
                <button
                    type="button"
                    className="page-link w-100"
                    onClick={handleNextPageClick}
                >
                    <i class="fas fa-chevron-right"></i>
                </button>
            </li>
        </ul>
    </nav>;
}

export default Pagination;