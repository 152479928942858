import { Dropdown, Modal } from "react-bootstrap";
import useAxios from "../../hooks/useAxios";
import { useEffect, useState } from "react";
import { useFeedBack } from "../../contexts/FeedBackContext";
import fileDownload from "js-file-download";

const FileActionsComponent = ({ file, onDelete, onUpdateFile }) => {

    const { setCustomAlert } = useFeedBack();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const [fileName, setFileName] = useState('');

    const [dots, setDots] = useState("");

    const [{ loading: downloadLoading }, getFile] = useAxios({ url: 'files', method: 'POST', responseType: 'blob' }, { manual: true, useCache: false });

    const [{ loading: deleteLoading }, deleteFile] = useAxios({ url: `system-files/${file?.id}`, method: 'DELETE' }, { manual: true, useCache: false });

    const [{ loading: updateLoading }, updateFile] = useAxios({ url: `system-files/${file?.id}`, method: 'PUT' }, { manual: true, useCache: false });

    useEffect(() => {
        if (file?.name) {
            setFileName(file?.name);
        }
    }, [file])

    useEffect(() => {
        let id;

        if (downloadLoading || deleteLoading || updateLoading) {
            id = setInterval(() => {
                setDots((oldDots) => oldDots.length < 3 ? oldDots + "." : "");
            }, 500);
        }

        return () => {
            if (id) clearInterval(id);
        }
    }, [downloadLoading, deleteLoading, updateLoading]);

    const handleDeleteFile = async () => {
        try {
            const deleteResponse = await deleteFile();

            setShowDeleteModal(false);
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'El registro fue eliminado exitosamente.',
                show: true
            });
            onDelete?.();

        } catch (error) {
            alert('Ha ocurrido un error al descargar el archivo');
            console.log(error);
        }
    }

    const handleUpdateFileName = async (e) => {
        e.preventDefault();

        try {
            const updateResponse = await updateFile({
                data: {
                    name: fileName
                }
            });

            onUpdateFile?.(updateResponse?.data?.data);

            setShowUpdateModal(false);

            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'El registro fue eliminado exitosamente.',
                show: true
            });

        } catch (error) {
            alert('Ha ocurrido un error al descargar el archivo');
            console.log(error);
        }
    }

    const handleDownload = async (file) => {
        try {
            const fileResponse = await getFile({
                data: {
                    fileId: file?.id
                }
            });

            fileDownload(fileResponse?.data, fileName);

        } catch (error) {
            alert('Ha ocurrido un error al descargar el archivo');
            console.log(error);
        }
    }

    const handleCloseUpdateModal = () => {
        setFileName(file?.name);
        setShowUpdateModal(false);
    }

    return (
        <>
            <Dropdown.Item as="button" onClick={() => setShowUpdateModal(true)}>
                <i className="ti-pencil mr-2"></i>
                Cambiar nombre
            </Dropdown.Item>
            {
                file?.type === 'file' &&
                <Dropdown.Item onClick={() => handleDownload(file)}>
                    {
                        downloadLoading ?
                            <div className="d-flex align-items-center">
                                <div className="spinner-border text-muted mr-2" role="status" style={{ width: 16, height: 16 }}></div>
                                <p className="m-0">
                                    Descargando{dots}
                                </p>
                            </div>
                            :
                            <>
                                <i className="ti-download mr-2"></i>
                                Descargar
                            </>
                    }
                </Dropdown.Item>
            }
            <Dropdown.Item as="button" className="text-danger" onClick={() => setShowDeleteModal(true)}>
                {
                    deleteLoading ?
                        <div className="d-flex align-items-center">
                            <div className="spinner-border text-danger mr-2" role="status" style={{ width: 16, height: 16 }}></div>
                            <p className="m-0">
                                Eliminando{dots}
                            </p>
                        </div>
                        :
                        <>
                            <i className="ti-close mr-2"></i>
                            Eliminar
                        </>
                }
            </Dropdown.Item>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} size="sm" centered>
                <Modal.Body>
                    <h4>¿Estas seguro/a?</h4>
                    <p>
                        Deseas eliminar el archivo: <b>{file?.name}</b>. Esta acción es inrreversible.
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                        <button className="btn btn-muted" onClick={() => setShowDeleteModal(false)}>
                            Cancelar
                        </button>
                        <button className="btn btn-danger" onClick={() => handleDeleteFile()}>
                            {
                                deleteLoading ?
                                    <div className="d-flex align-items-center">
                                        <p className="m-0 text-white">
                                            Eliminando{dots}
                                        </p>
                                    </div>
                                    :
                                    'Eliminar'
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showUpdateModal} onHide={handleCloseUpdateModal} centered>
                <Modal.Body>
                    <form onSubmit={handleUpdateFileName}>
                        <h4 className="mb-4">¿Cambiar nombre?</h4>
                        <input type="text" className="form-control mb-4" value={fileName} onChange={(e) => setFileName(e.target.value)} />
                        <div className="d-flex align-items-center justify-content-between">
                            <button className="btn btn-muted" type="button" onClick={handleCloseUpdateModal}>
                                Cancelar
                            </button>
                            <button className="btn btn-primary" >
                                {
                                    updateLoading ?
                                        <div className="d-flex align-items-center">
                                            <p className="m-0 text-white">
                                                Actualizando{dots}
                                            </p>
                                        </div>
                                        :
                                        'Cambiar nombre'
                                }
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default FileActionsComponent;