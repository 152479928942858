import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const RequireGuest = ({ element }) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();
    const redirectPath = location.state?.from || '/perfil';

    if (isAuthenticated) {
        return <Navigate to={redirectPath} state={{ from: location }} replace />;
    }

    return element;
}

export default RequireGuest;