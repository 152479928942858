import { object, string, array } from "yup"

export const crudPermissions = [
    { name: 'view', displayText: 'Ver' },
    { name: 'create', displayText: 'Crear' },
    { name: 'update', displayText: 'Actualizar' },
    { name: 'delete', displayText: 'Eliminar' },
]

const lowercaseAlphaAndHyphens = /^[a-z\-]+$/;
const alphaSpacesAndHyphens = /^[A-Za-z \-]+$/;

export const formSchema = object().shape({
    name: string().matches(lowercaseAlphaAndHyphens).required(),
    displayText: string().matches(alphaSpacesAndHyphens).required(),
    guard: string().required(),
    systemCode: string().required(),
    permissions: array().min(1).of(object().shape({
        name: string().matches(lowercaseAlphaAndHyphens).required(),
        displayText: string().matches(alphaSpacesAndHyphens).required(),
    })),
})