import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";
import useRegions from "../../hooks/useRegions";
import handleLoadSelectOptions from "../../utils/loadSelectValues";
import mapValues from "../../utils/mapValues";

const CommunesCreate = () => {
    const navigate = useNavigate();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [data, setData] = useState({
        name: '',
        region: ''
    });

    const [{ data: createData, loading: createLoading }, createRecord] = useAxios({ url: '/communes', method: 'POST' }, { manual: true });

    const [{ regions, total, numberOfPages, size, error, loading: regionsLoading }, getRegions] = useRegions({
        options: {
            useCache: false
        }
    });

    useEffect(() => {
        setLoading({
            show: createLoading,
            message: 'Creando registro',
        });
    }, [createLoading]);

    useEffect(() => {
        if (createData) {
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'El registro fue creado exitosamente.',
                show: true
            });
            navigate('/comunas');
        }
    }, [createData]);

    useEffect(() => {
        console.log(data);
    }, [data])

    const handleChange = (e) => {
        setData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        createRecord({
            data: {
                name: data?.name,
                region_id: data?.region?.value
            }
        });
    }

    return <>
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">Crear Comuna</h4>
            </div>

            <div className="card-body">
                <div className="basic-form">
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-5">
                            <div className="form-group mb-3 col-md-6">
                                <label>Nombre</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nombre"
                                    name="name"
                                    value={data.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Region</label>
                                <AsyncSelect
                                    onFocus={() => {
                                        getRegions();
                                    }}
                                    defaultOptions={mapValues(regions)}
                                    value={data?.zone}
                                    isLoading={regionsLoading}
                                    loadOptions={(e) => handleLoadSelectOptions(e, getRegions)}
                                    placeholder='Escriba el nombre para buscar...'
                                    onChange={(e) => { handleChange({ target: { value: e, name: 'region' } }) }}
                                />
                            </div>
                        </div>

                        <div className="mb-3 d-flex justify-content-end">
                            <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                Cancelar
                            </Link>
                            <button disabled={createLoading} type="submit" className="btn btn-primary mx-2">
                                {createLoading ? 'Cargando' : 'Crear'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}

export default CommunesCreate;