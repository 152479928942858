import { useEffect, useState } from "react";
import useAxios from "../hooks/useAxios";
import makeId from "../utils/makeId";
import swal from "../utils/swal";
import ActionsDropdown from "./ActionsDropdown";
import Pagination from "./Pagination";
import TableCell from "./TableCell";
import TableHeading from "./TableHeading";

const Table = ({
    title,
    columns,
    items = [],
    identifierField = 'id',
    currentPage = 1,
    total = 0,
    pages = 1,
    perPageValue = 10,
    loading,
    withSelection = false,
    onChangePage,
    onSelectedChange,
    onDeleteSelected,
    excelUrl,
    searchValue,
    onSearchChange,
    onChagePerPage,
    excelFileName = 'table.xlsx'
}) => {
    const [selectedValues, setSelectedValues] = useState([]);

    const [{ data: excelData, loading: excelLoading }, getExcel] = useAxios({ url: excelUrl, method: 'GET', responseType: 'blob' }, { manual: true, useCache: false });

    const showPagination = pages > 1 && !loading;

    const selectAllId = makeId(10);

    useEffect(() => {
        if (excelData) {
            const excelBlobUrl = URL.createObjectURL(excelData);
            const aToDownload = document.getElementById('downloadLink');
            aToDownload.href = excelBlobUrl;
            aToDownload?.click();
            window.URL.revokeObjectURL(excelBlobUrl);
        }
    }, [excelData])

    const handleSelectAllChange = (e) => {
        setSelectedValues(e.target.checked
            ? items.map(item => item[identifierField])
            : []
        );
    }

    const handleCheckChange = (value) => (e) => {
        setSelectedValues(currentSelected => {
            if (currentSelected.find(selected => selected === value)) {
                return currentSelected.filter(selected => selected !== value);
            }

            return [...currentSelected, value];
        })
    }

    const isSelected = (value) => selectedValues.indexOf(value) !== -1;

    useEffect(() => {
        onSelectedChange?.(selectedValues);
    }, [selectedValues]);

    useEffect(() => {
        const itemIdentifiers = items?.map(item => item[identifierField]) ?? [];

        setSelectedValues(prevSelected => prevSelected.filter(value => itemIdentifiers.includes(value)));
    }, [items]);

    return <>
        <div className={`card ${loading ? 'loading' : ''}`} style={{ maxWidth: '97vw' }}>
            <a id="downloadLink" style={{ display: 'none' }} download={excelFileName}></a>
            <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h4>{title}</h4>
                <div style={{ textAlign: 'right', display: 'flex', width: '70%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <input
                        onChange={(e) => onSearchChange?.(e)}
                        type="text"
                        className="form-control"
                        placeholder="Buscar..."
                        style={{ width: 'auto' }}
                        value={searchValue || ''}
                    />
                    {
                        excelUrl &&
                        <button onClick={() => getExcel().then((res) => {
                            console.log(res);
                        })} disabled={excelLoading} className="btn btn-success btn-sm">
                            {
                                excelLoading ?
                                    'Loading...'
                                    :
                                    'Descargar Excel'
                            }
                        </button>
                    }
                    {withSelection &&
                        <div>
                            <h5 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', textAlign: 'right' }}>
                                Acciones Globales
                                {selectedValues.length > 0 &&
                                    <ActionsDropdown onDelete={onDeleteSelected} dataForOnDelete={selectedValues} />
                                }
                            </h5>
                            {selectedValues.length === 0 &&
                                <small>Seleccione al menos un valor.</small>
                            }
                        </div>
                    }
                </div>
            </div>
            <div className="card-body">
                {loading && <>
                    <div className="spinner-border thumb-xl text-primary" role="status" style={{ width: 120, height: 120 }}></div>
                    <h4 className="mt-5">Cargando...</h4>
                </>}
                {!loading && <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                {withSelection && <TableHeading>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={selectAllId}
                                            checked={selectedValues.length === items?.length}
                                            onChange={handleSelectAllChange}
                                        />
                                        <label className="custom-control-label" htmlFor={selectAllId}></label>
                                    </div>
                                </TableHeading>}
                                {columns.map((column, i) => <TableHeading key={i}>{column.headerName}</TableHeading>)}
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => <tr key={item[identifierField]}>
                                {withSelection && <TableHeading>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={`${selectAllId}-${item[identifierField]}`}
                                            checked={isSelected(item[identifierField])}
                                            onChange={handleCheckChange(item[identifierField])}
                                        />
                                        <label className="custom-control-label" htmlFor={`${selectAllId}-${item[identifierField]}`}></label>
                                    </div>
                                </TableHeading>}
                                {columns.map(({ field, component, defaultValue }, i) => <TableCell
                                    key={i}
                                    item={item}
                                    field={field}
                                    component={component}
                                    defaultValue={defaultValue}
                                />)}
                            </tr>)}
                        </tbody>
                    </table>
                </div>}
            </div>
            <div className="card-footer" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <div className="py-2" style={{ width: '20%' }}>Mostrando: {items.length} de {total}</div>
                <div style={{ width: '20%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label className="label mx-1 mb-0">
                            Mostrar:
                        </label>
                        <select onChange={onChagePerPage} className="form-control" value={perPageValue}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                </div>
                {showPagination &&
                    <Pagination
                        style={{
                            width: '40%',
                            flexWrap: 'wrap'
                        }}
                        currentPage={currentPage}
                        pages={pages}
                        onChangePage={onChangePage}
                    />}
            </div>
        </div>
    </>;
}

export default Table;